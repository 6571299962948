import Showdown from "showdown";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { selectLogics } from "../../../../logics/logicsSlice";
import { WindowTypeAndPropsDefinition } from "../../../windowManagerSlice";
import css from "./ReadmeWindow.module.css";
import { useMemo } from "react";

export interface ReadmeWindowProps {
    source: string,
}

export type ReadmeWindowTypeAndProps = WindowTypeAndPropsDefinition<
    "readme",
    ReadmeWindowProps
>;

export function ReadmeWindow(props: ReadmeWindowProps & { id: string }) {
    const dispatch = useAppDispatch();
    const logics = useAppSelector(selectLogics);

    const info = useMemo(() => {
        const source = logics.readme.find(([id, _]) => id === props.source)?.[1];

        if (!source) {
            return "No info available.";
        }

        let { parentDirectory, rawContent } = source;

        const converter = new Showdown.Converter();
        converter.setOption('tables', true);

        console.log({ parentDirectory, rawContent });

        const reg = /(!\[.+\])\((.+)\)/g;
        rawContent = rawContent.replace(reg, `$1(imageapi/${parentDirectory}\$2)`);
        console.log(rawContent);

        return converter.makeHtml(rawContent);
    }, [props.source]);

    return <div className={css.content}>
        <div className={css.readmeContent} dangerouslySetInnerHTML={{ __html: info }}/>
    </div>;
}
