import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Point2D } from "../../common/Point2D";
import { MenuType } from "./menu/menuImpl";

export interface MenuState {
    position: Point2D,
    sourceId: MenuType,
}

export interface NormalMenuState extends MenuState {
    metaType: "normal",
};

export interface ContextMenuState extends MenuState {
    metaType: "context",
};

export type MenuManagerState = {
    currentMenu: NormalMenuState | ContextMenuState | null,
};

const initialState: MenuManagerState = {
    currentMenu: null,
};

export const menuManagerSlice = createSlice({
    name: "menuManager",
    initialState,
    reducers: {
        resetMenuManager: () => {
            return initialState;
        },
        openNormalMenu: (state, action: PayloadAction<MenuState>) => {
            state.currentMenu = {
                ...action.payload,
                metaType: "normal",
            };
        },
        openContextMenu: (state, action: PayloadAction<MenuState>) => {
            state.currentMenu = {
                ...action.payload,
                metaType: "context",
            };
        },
        closeMenu: (state) => {
            state.currentMenu = null;
        }
    },
    extraReducers: (builder) => {

    },
});

export const {
    resetMenuManager,
    openNormalMenu,
    openContextMenu,
    closeMenu,
} = menuManagerSlice.actions;

export const selectCurrentMenu = (state: RootState) => state.menuManager.currentMenu;

export default menuManagerSlice.reducer;
