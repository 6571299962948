import { RawLogicsDevice } from "../rawLogics/RawDevice";

export interface LogicsDevice {
    name: string;
    addresses: string[];

    input: Record<string, LogicsInput>;
    output: Record<string, LogicsOutput>;

    config: Record<string, LogicsConfiguration[]>;
}

interface LogicsInput {
    id: number;
    pull: string[];
    mode: string[];
}

interface LogicsOutput {
    id: number;
    signal: string[];
    mode: string[];
}

interface LogicsConfiguration {
    details?: string,
    properties?: Partial<Record<string, string | number | boolean>>,
}

export function fromRawDevices(rawDevices: RawLogicsDevice[]): LogicsDevice[] {
    return rawDevices.map(fromRawDevice);
}

function fromRawDevice(rawDevice: RawLogicsDevice): LogicsDevice {
    return {
        name: rawDevice.name,
        addresses: rawDevice.addresses,

        input: rawDevice.input,
        output: rawDevice.output,

        config: rawDevice.configurations,
    };
}
