import { env } from "../../env/env";
import { Logics, buildLogics } from "./logics/Logics";
import { CommitRef } from "./logicsSlice";
import { RawLogics } from "./rawLogics/RawLogics";

export async function fetchLogics(commit: CommitRef): Promise<Logics> {
    const response = await fetch(`${env.apiUrl}/configuration?commitHash=${commit.hash}`);

    const {
        config,
        device,
        memory,
        operator,
    } = await response.json();

    if (!config || !device || !memory || !operator) {
        throw new Error("Invalid response");
    }

    const readmeResponse = await fetch(`${env.apiUrl}/readme?commitHash=${commit.hash}`);
    const readme = await readmeResponse.json();

    const rawLogics: RawLogics = {
        config,
        device,
        memory,
        operator,
    };

    const logics: Logics = buildLogics(
        rawLogics,
        readme,
        commit,
    );

    console.log({logics});

    return logics;
}

function isArray<T>(array: any): array is Array<T> {
    return array instanceof Array;
}
