import Metadata from "./Metadata";

export interface MemorySourceIo {
    type: "io";
    deviceId: string;
    io: string;
}

export interface MemorySourceAddress {
    type: "address";
    deviceId: string;
    address: number;
}

export interface MemorySourceLocal {
    type: "local";
    deviceId?: undefined;
}

export type MemorySource =
    | MemorySourceIo
    | MemorySourceAddress
    | MemorySourceLocal
;

export interface Memory {
    id: string;
    label: string;
    name: string;
    source: MemorySource;
    broadcast: boolean;
    metadata: Metadata;
}

export interface MemoryScratchPad {
    label: string;
    name: string;
    source: MemorySource | null;
    broadcast: boolean;
    metadata: Metadata;
}

export default Memory;

export function isSameMemorySource(a: MemorySource, b: MemorySource): boolean {
    if (a === b) {
        return true;
    }

    if (a.type !== b.type) {
        return false;
    }

    switch (a.type) {
        case "io":
            return a.type === b.type && a.deviceId === b.deviceId && a.io === b.io;
        case "address":
            return a.type === b.type && a.deviceId === b.deviceId && a.address === b.address;
        case "local":
            return true;
    }
}
