import { Point2D } from "../../../../../common/Point2D";

export function InfoBox(props: {
    sheetPosition: Point2D,
    zoom: number,
}) {
    return <span style={{
        userSelect: "none",
        position: "absolute",
        bottom: 0,
        right: 0,
        backgroundColor: "var(--palette-pms-641c-100)",
        color: "white",
        padding: "2px 7px",
        pointerEvents: "none",
        zIndex: 1,
        textAlign: "right",
    }}>
        <div>{`${props.zoom.toFixed(2)}x`}</div>
        <div>{`x: ${props.sheetPosition.x.toFixed(0)} y: ${props.sheetPosition.y.toFixed(0)}`}</div>
    </span>;
}
