import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectLogics } from "../../../logics/logicsSlice";
import { SideBarSelection, createMemoryRefPreview, createOperatorPreview, insertPreview, selectCurrentTabIndex, selectSheet, setSideBarSelection } from "../../editorSlice";
import css from "./SideBar.module.css";
import { useDrag } from "../../../../common/useDrag";
import { Point2D } from "../../../../common/Point2D";

interface SideBarGroupEntry {
    label: string,
    type: "memory" | "operator",
}

function SideBarGroup(props: {
    label: string,
    items: SideBarGroupEntry[],
    initiallyOpen?: boolean,
}) {
    const dispatch = useAppDispatch();
    const sideBarSelection = useAppSelector((state) => state.editor.sideBarSelection);
    const [open, setOpen] = useState(props.initiallyOpen ?? true);
    const currentTabIndex = useAppSelector(selectCurrentTabIndex);
    const sheet = useAppSelector(selectSheet(currentTabIndex));
    const sheetScale = sheet.scale;
    const sheetPosition = sheet.position;
    const gridPixelSize = sheet.gridPixelSize;
    const blockPreview = useAppSelector(state => state.editor.blockPreview);

    const onStartBlockCreation = useDrag({
        onMouseDown({ event }) {
            const data: {
                type: "memory" | "operator",
                label: string,
            } = (event.target as any).getAttribute("data-id");

            console.log("onMouseDown", data);
        },
        onMouseMove({ event }) {
            console.log({blockPreview});
            if (blockPreview) {
                console.log("Preview already exists");
                return;
            }

            if (sideBarSelection === null) {
                console.error("Side bar selection is null");
                return;
            }

            const sheetBackground = document.getElementById("sheetBackground");

            if (!sheetBackground) {
                console.error("Sheet background not found");
                return;
            }

            const boundingRect = sheetBackground.getBoundingClientRect();

            const sheetSize = {
                x: (boundingRect.width ?? 0) / sheetScale,
                y: (boundingRect.height ?? 0) / sheetScale,
            };

            const source = {
                x: event.clientX - boundingRect.left,
                y: event.clientY - boundingRect.top,
            };

            const positionWithOffset: Point2D = {
                x: sheetPosition.x - sheetSize.x / 2,
                y: sheetPosition.y - sheetSize.y / 2,
            };

            const bottom = positionWithOffset.y % gridPixelSize - gridPixelSize;
            const right = positionWithOffset.x % gridPixelSize - gridPixelSize;

            const target = {
                x: source.x / sheetScale + sheetPosition.x - sheetSize.x / 2 - gridPixelSize - right,
                y: source.y / sheetScale + sheetPosition.y - sheetSize.y / 2 - gridPixelSize - bottom,
            };

            switch (sideBarSelection.type) {
                case "operator":
                    dispatch(createOperatorPreview(
                        sideBarSelection.label,
                        currentTabIndex,
                        target,
                        "drag",
                    ));
                    break;
                case "memory":
                    dispatch(createMemoryRefPreview(
                        sideBarSelection.label,
                        currentTabIndex,
                        target,
                        "drag",
                    ));
                    break;
                default:
                    console.error("Unknown side bar selection type");
                    return;
            }

            console.log("Creating block", sideBarSelection, target);
        },
        onMouseUp() {
            dispatch(insertPreview());
        },
    }, [sideBarSelection, blockPreview, currentTabIndex, sheetScale, sheetPosition, gridPixelSize]);

    return <div className={css.sideBarGroup}>
        <div
            className={classNames(css.sideBarItem, css.sideBarGroupTitle)}
            onClick={() => {
                if (open && sideBarSelection?.group === props.label) {
                    dispatch(setSideBarSelection(null));
                }

                setOpen(!open);
            }}
        >
            {props.label}
            {open && <FontAwesomeIcon icon={faAngleUp}/>}
            {!open && <FontAwesomeIcon icon={faAngleDown}/>}
        </div>
        {props.items.map((item) => {
            const isThisSelected =
                sideBarSelection?.type === item.type &&
                sideBarSelection.group === props.label &&
                sideBarSelection.label === item.label;
            return <div
                key={item.label}
                data-id={JSON.stringify({ type: item.type, label: item.label})}
                className={classNames(css.sideBarItem, {
                    [css.sideBarItemSelected]: isThisSelected,
                    [css.sideBarItemClosed]: !open,
                })}
                onMouseDown={event => {
                    // const selection: SideBarSelection = isThisSelected
                    //     ? null
                    //     : { type: item.type, group: props.label, label: item.label };
                    const selection = { type: item.type, group: props.label, label: item.label };
                    dispatch(setSideBarSelection(selection));
                    onStartBlockCreation(event);
                }}
            >{item.label}</div>;
        })}
    </div>;
}

export function SideBar() {
    const logics = useAppSelector(selectLogics);

    const customGroups = useMemo(() => {
        const customMemoryGroups = Object.values(logics.memory).map<[string, string[]]>(memory => [memory.name, memory.tags]);
        const customOperatorGroups = Object.values(logics.operator).map<[string, string[]]>(operator => [operator.name, operator.tags]);

        const customGroups = new Map<string, SideBarGroupEntry[]>();

        for (const [groupName, groupTags] of customMemoryGroups) {
            groupTags.forEach(tag => {
                if (!customGroups.has(tag)) {
                    customGroups.set(tag, []);
                }
                customGroups.get(tag)!.push({
                    label: groupName,
                    type: "memory",
                });
            });
        }

        for (const [groupName, groupTags] of customOperatorGroups) {
            groupTags.forEach(tag => {
                if (!customGroups.has(tag)) {
                    customGroups.set(tag, []);
                }
                customGroups.get(tag)!.push({
                    label: groupName,
                    type: "operator",
                });
            });
        }

        return customGroups;
    }, [logics]);

    console.log(customGroups);

    return <div className={css.sideBar}>
        <SideBarGroup
            label="Memories"
            items={Object.entries(logics.memory).map(([_key, value]) => ({ label: value.name, type: "memory" }))}
        />
        <SideBarGroup
            label="Operators"
            items={Object.entries(logics.operator).map(([_key, value]) => ({ label: value.name, type: "operator" }))}
        />
        {Array.from(customGroups.entries()).toSorted().map(([label, items]) => <SideBarGroup
            key={label}
            label={label}
            items={items}
            initiallyOpen={false}
        />)}
        <div className={css.sideBarEmptySpace}/>
    </div>;
}
