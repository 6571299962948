import classNames from "classnames";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { pgbBuild } from "../../../../common/ProjectGraph";
import { env } from "../../../../env/env";
import { selectBackendVersion, selectFetchBackendStatus, selectFetchLogicsStatus } from "../../../logics/logicsSlice";
import { MenuType } from "../../../menuManager/menu/menuImpl";
import { closeMenu, openNormalMenu, selectCurrentMenu } from "../../../menuManager/menuManagerSlice";
import { createWindow } from "../../../windowManager/windowManagerSlice";
import { selectProjectName, updateProjectName } from "../../editorSlice";
import css from "./Header.module.css";

interface MenuBarItemProps {
    label: string,
    menuType: MenuType,
}

const menuBarItems: MenuBarItemProps[] = [
    { label: "Project", menuType: "project" },
    // { label: "Edit", menuType: "edit" },
    { label: "View", menuType: "view" },
    // { label: "Help", menuType: "help" },
];

function MenuBarItem(props: MenuBarItemProps) {
    const dispatch = useAppDispatch();
    const currentMenu = useAppSelector(selectCurrentMenu);

    function open(rect: DOMRect) {
        const { left, bottom } = rect;
        dispatch(openNormalMenu({
            sourceId: props.menuType,
            position: { x: left, y: bottom },
        }));
    }

    return <div
        className={classNames(
            css.menuItem,
            {
                [css.menuItemOpen]: currentMenu?.sourceId === props.menuType
            }
        )}
        onMouseDown={event => event.stopPropagation()}
        onClick={event => {
            if (currentMenu === null) {
                open(event.currentTarget.getBoundingClientRect());
            } else if (currentMenu.sourceId === props.menuType) {
                dispatch(closeMenu());
            }
        }}
        onMouseEnter={event => {
            if (currentMenu === null) {
                return;
            }

            const menuTypes = menuBarItems.map(item => item.menuType);
            if (menuTypes.includes(currentMenu.sourceId)) {
                open(event.currentTarget.getBoundingClientRect());
            }
        }}
    >{props.label}</div>;
}

export function ProjectName() {
    const dispatch = useAppDispatch();
    const projectName = useAppSelector(selectProjectName);

    const [editing, setEditing] = useState(false);

    return <div className={css.titleBar}>
        {editing
            ? <input
                className={css.titleContent}
                defaultValue={projectName}
                autoFocus={true}
                onFocus={event => {
                    event.target.select();
                    event.target.style.width = "0px";
                    const boundingRect = event.target.getBoundingClientRect();
                    const size = Math.min(event.target.scrollWidth + 2 - boundingRect.width, 300);
                    event.target.style.width = `${size}px`;
                }}
                onBlur={event => {
                    setEditing(false);
                    dispatch(updateProjectName(event.currentTarget.value));
                }}
                onChange={event => {
                    event.target.style.width = "0px";
                    const boundingRect = event.target.getBoundingClientRect();
                    const size = Math.min(event.target.scrollWidth + 2 - boundingRect.width, 300);
                    event.target.style.width = `${size}px`;
                }}
                onKeyDown={event => {
                    const key = event.key;

                    if (key === "Enter" || key === "Escape") {
                        setEditing(false);
                    }

                    if (key === "Enter") {
                        dispatch(updateProjectName(event.currentTarget.value));
                    }
                }}
            />
            : <div
                className={css.titleContent}
                onClick={() => setEditing(true)}
            >{projectName}</div>
        }
    </div>;
}

function VersionBox(props: { type: string, version: string, tooltip?: React.ReactNode, mirrored?: boolean }) {
    const [tooltipVisible, setTooltipVisible] = useState(false);

    return <div
        className={css.versionBox}
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
    >
        <div className={css.versionType}>{props.type}</div>
        <div className={css.versionName}>{props.version}</div>
        {props.tooltip && tooltipVisible && <div className={css.tooltipWrapper}>
            <div
                className={css.tooltip}
                style={{ right: props.mirrored ? -18 : undefined }}
            >{props.tooltip}</div>
        </div>}
    </div>;
}

export function Header() {
    const dispatch = useAppDispatch();
    const currentMenu = useAppSelector(selectCurrentMenu);
    const logicsStatus = useAppSelector(selectFetchLogicsStatus);
    const backendStatus = useAppSelector(selectFetchBackendStatus);
    const editor = useAppSelector(state => state.editor);
    const projectName = useAppSelector(selectProjectName);

    const [frontendVersion, frontendBuild] = env.frontendVersion.split("-");

    return <div className={css.header}>
        <div className={css.headerColumn}>
            <div className={css.headerLine}>
                <div className={css.brandArea}>
                    <div className={css.brandIcon}></div>
                </div>
                <div className={css.headerColumn}>
                    <div className={css.headerLine}>
                        <ProjectName />
                    </div>
                    <div className={css.menuBar}>
                        <div className={css.headerLine}>
                            {menuBarItems.map(item => <MenuBarItem key={item.label} {...item} />)}
                        </div>
                    </div>
                </div>
                <div className={css.versionArea}>
                    <VersionBox
                        type="Frontend"
                        version={parseInt(env.frontendVersion.split("-")[1]) === 0 ? `${frontendVersion}` : env.frontendVersion.split("-")[2].slice(1)}
                        tooltip={
                            env.branch !== "main"
                                ? <div className={css.versionName}>
                                    <div>Commit: {env.frontendVersion.split("-")[2].slice(1)}</div>
                                    <div>Version tag: {frontendVersion}</div>
                                    <div>Version build: {env.frontendVersion.split("-")[1]}</div>
                                    <div>Branch: {env.branch}</div>
                                    {/* {env.branch !== "main" && `-${env.branch}`} */}
                                </div>
                                : undefined
                        }
                    />
                    {backendStatus.code === "succeeded" && <VersionBox
                        type="Backend"
                        version={
                            parseInt(backendStatus.backendVersion.split("-")[1]) === 0
                                ? `${backendStatus.backendVersion.split("-")[0]}`
                                : backendStatus.backendVersion.split("-")[2].slice(1)
                            }
                        tooltip={
                            env.branch !== "main"
                                ? <div className={css.versionName}>
                                    <div>Commit: {backendStatus.backendVersion.split("-")[2].slice(1)}</div>
                                    <div>Version tag: {backendStatus.backendVersion.split("-")[0]}</div>
                                    <div>Version build: {backendStatus.backendVersion.split("-")[1]}</div>
                                    <div>Branch: {env.branch}</div>
                                    {/* {env.branch !== "main" && `-${env.branch}`} */}
                                </div>
                                : undefined
                        }
                    />}
                    {backendStatus.code !== "succeeded" && <div className={css.versionBox}>
                        <div className={css.versionType}>Backend</div>
                        <div className={css.versionName}>
                            <div className={css.versionNotLoaded}>Not loaded</div>
                        </div>
                    </div>}
                    {logicsStatus.code === "succeeded" && backendStatus.code === "succeeded" && <VersionBox
                        type="Logics"
                        version={
                            `${logicsStatus.logics.commit.name}${
                                logicsStatus.logics.commit.hash === backendStatus.commits.find(
                                    commit => commit.name === logicsStatus.logics.commit.name
                                )!.hash ? "" : "*"
                            }`
                        }
                        tooltip={
                            env.branch !== "main"
                                ? <div className={css.versionName}>
                                    <div>Version branch: {logicsStatus.logics.commit.name}</div>
                                    <div>Commit: {logicsStatus.logics.commit.hash.slice(0, 7)}</div>
                                    <div>Dataset version: {
                                        logicsStatus.logics.commit.datasetVersion
                                            ? `V${logicsStatus.logics.commit.datasetVersion}`
                                            : <span className={css.versionNotLoaded}>Not available</span>
                                    }</div>
                                </div>
                                : undefined
                        }
                        mirrored
                    />}
                    {logicsStatus.code !== "succeeded" && <div className={css.versionBox}>
                        <div className={css.versionType}>Logics</div>
                        <div className={css.versionName}>
                            <div className={css.versionNotLoaded}>Not loaded</div>
                        </div>
                    </div>}
                </div>
            </div>
            <div className={css.toolBar}>
                {logicsStatus.code === "succeeded" && <>
                    <div
                        className={css.toolBarItem}
                        onClick={async () => {
                            const projectGraph = pgbBuild(editor, logicsStatus.logics);
                            const graph = JSON.stringify(projectGraph);

                            const commitHash = logicsStatus.logics.commit.hash;
                            // const commitHash = "7b968ef7daf5b6e3edbb3b1b9e465bf15e49eeed";

                            const packet = {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify({ graph, commitHash }),
                            };

                            const fetch_result = await fetch(`${env.apiUrl}/export?commitHash=${commitHash}`, packet);

                            if (fetch_result.ok) {
                                function download(content: any, fileName: string, contentType: string) {
                                    let a = document.createElement("a");
                                    let file = new Blob([content], { type: contentType });
                                    a.href = URL.createObjectURL(file);
                                    a.download = fileName;
                                    a.click();
                                }

                                download(await fetch_result.blob(), `${projectName}.dst`, "blob");
                            }
                        }}
                    >Export</div>
                    {/* <div className={css.toolBarItem}>Generate Metadata</div> */}
                    <div className={css.separator} />
                    <div
                        className={css.toolBarItem}
                        onClick={() => {
                            const windowSize = {
                                x: 600,
                                y: 500,
                            };

                            const windowPosition = {
                                x: (window.innerWidth - windowSize.x) / 2,
                                y: (window.innerHeight - windowSize.y) / 2,
                            };

                            dispatch(createWindow({
                                title: "Memory Manager",
                                typeAndProps: {
                                    type: "memoryManager",
                                    props: {},
                                },
                                position: windowPosition,
                                size: windowSize,
                            }));
                        }}
                    >Memory Manager</div>
                    <div
                        className={css.toolBarItem}
                        onClick={() => {
                            const windowSize = {
                                x: 600,
                                y: 500,
                            };

                            const windowPosition = {
                                x: (window.innerWidth - windowSize.x) / 2,
                                y: (window.innerHeight - windowSize.y) / 2,
                            };

                            dispatch(createWindow({
                                title: "Device Manager",
                                typeAndProps: {
                                    type: "deviceManager",
                                    props: {},
                                },
                                position: windowPosition,
                                size: windowSize,
                            }));
                        }}
                    >Device Manager</div>
                </>}
            </div>
        </div>
    </div>;
}
