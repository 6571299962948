import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { fetchLogicsConfiguration, resetLogics, selectCommitRefs, selectLogics } from "../../../../logics/logicsSlice";
import { WindowTypeAndPropsDefinition, closeWindow } from "../../../windowManagerSlice";
import css from "./LogicsMigrationWindow.module.css";

export interface LogicsMigrationWindowProps { }

export type LogicsMigrationWindowTypeAndProps = WindowTypeAndPropsDefinition<
    "logicsMigration",
    LogicsMigrationWindowProps
>;

export function LogicsMigrationWindow(props: LogicsMigrationWindowProps & { id: string }) {
    const dispatch = useAppDispatch();
    const commitRefs = useAppSelector(selectCommitRefs);
    const logics = useAppSelector(selectLogics);
    const isDevModeActive = useAppSelector(state => state.system.devMode);
    const currentLogicsDatasetVersion = commitRefs.find(ref => ref.name === logics.commit.name)!.datasetVersion;

    const [selectedCommitIndex, setSelectedCommitIndex] = useState<number>(commitRefs.findIndex(ref => ref.name === logics.commit.name));

    console.log(selectedCommitIndex);

    if (!isDevModeActive && !commitRefs[selectedCommitIndex].name.startsWith("JSON_")) {
        setSelectedCommitIndex(commitRefs.findIndex(ref => ref.name.startsWith("JSON_")));
    }

    console.log({commitRefs});
    console.log(currentLogicsDatasetVersion);

    return <div className={css.content}>
        <div className={css.selectorInfo}>
            Please select which logics version you would like to migrate to.
        </div>
        <select className={css.selectorSelect} value={selectedCommitIndex} onChange={event => setSelectedCommitIndex(Number(event.target.value))}>
            {commitRefs
                .map((ref, index) => (
                    <option key={`${ref.hash}-${ref.name}`} value={index}>
                        {ref.name.startsWith("JSON_") ? `Firmware ${ref.name.slice(5)}` : ref.name} {ref.datasetVersion && ` / Dataset V${ref.datasetVersion}`}
                    </option>
                ))
                .filter((_, index) => {
                    if (commitRefs[index].datasetVersion !== currentLogicsDatasetVersion) {
                        return false;
                    }

                    if (isDevModeActive) {
                        return true;
                    }

                    return commitRefs[index].name.startsWith("JSON_");
                })}
        </select>
        <button
            className={css.migrateButton}
            onClick={() => {
                dispatch(closeWindow(props.id));
                dispatch(resetLogics());
                dispatch(fetchLogicsConfiguration(commitRefs[selectedCommitIndex]));
            }}
        >Migrate</button>
    </div>;
}
