import { RawLogicsConfig } from "../rawLogics/RawConfig";
import { LogicsVariable, fromRawVariables } from "./LogicsVariable";

export interface LogicsConfig {
    label: string,
    configType: string,
    type: string,
    variables: LogicsVariable[],
}

export function fromRawConfigs(rawConfigs: RawLogicsConfig[]): LogicsConfig[] {
    return rawConfigs.map(fromRawConfig);
}

function fromRawConfig(rawConfig: RawLogicsConfig): LogicsConfig {
    return {
        label: rawConfig.name,
        configType: rawConfig.config_type,
        type: rawConfig.type,
        variables: fromRawVariables(rawConfig.variables).filter(variable => variable.name !== "config_type"),
    };
}
