import { useAppDispatch, useAppSelector } from "../../app/hooks";
import css from "./WindowManager.module.css";
import { TypedWindow } from "./window/TypedWindow";
import { selectWindows } from "./windowManagerSlice";

export function WindowManager() {
    const dispatch = useAppDispatch();
    const windows = useAppSelector(selectWindows);

    return <div className={css.windowManager}>
        {Object.keys(windows).map(id => <TypedWindow key={id} id={id}/>)}
    </div>;
}
