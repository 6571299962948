import { CommitRef } from "../logicsSlice";
import { RawLogics } from "../rawLogics/RawLogics";
import { LogicsConfig, fromRawConfigs } from "./LogicsConfig";
import { LogicsDevice, fromRawDevices } from "./LogicsDevice";
import { LogicsMemory, fromRawMemories } from "./LogicsMemory";
import { LogicsOperator, fromRawOperators } from "./LogicsOperator";

export interface Logics {
    device: Record<string, LogicsDevice>,
    memory: Record<string, LogicsMemory>,
    operator: Record<string, LogicsOperator>,
    config: Record<string, LogicsConfig>,
    readme: any[],
    commit: CommitRef,
    backendVersion?: string,
}

export function buildLogics(
    rawLogics: RawLogics,
    readme: any,
    commit: CommitRef,
    backendVersion?: string,
): Logics {
    return {
        config: Object.fromEntries(
            fromRawConfigs(rawLogics.config).map(item => [item.type, item])
        ),
        device: Object.fromEntries(
            fromRawDevices(rawLogics.device).map(item => [item.name, item])
        ),
        memory: Object.fromEntries(
            fromRawMemories(rawLogics.memory).map(item => [item.name, item])
        ),
        operator: Object.fromEntries(
            fromRawOperators(rawLogics.operator).map(item => [item.name, item])
        ),
        readme,
        commit,
        backendVersion,
    };
}
