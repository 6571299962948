import { RawLogicsData, RawLogicsVariable } from "../rawLogics/RawVariable";

export namespace LogicsTypes {
    export interface LogicsEnum {
        type: "enum";
        default: string;
        options: Record<string, number>;
    }

    export interface LogicsNumber {
        type: "number";
        default: number;
        min?: number;
        max?: number;
    }

    export interface LogicsBoolean {
        type: "boolean";
        default: boolean;
    }
}

export type LogicsVariableContent = LogicsTypes.LogicsEnum | LogicsTypes.LogicsNumber | LogicsTypes.LogicsBoolean;

export interface LogicsVariable {
    label: string;
    name: string;
    description: string;
    content: LogicsVariableContent;
    private: boolean;
}

export interface LogicsData {
    label: string;
    description: string;
    type: string;
    size: string;
}

const supportedNumberTypes = new Set([
    "uint8_t",
    "uint16_t",
    "uint32_t",
    "int8_t",
    "int16_t",
    "int32_t",
]);

const supportedBooleanTypes = new Set([
    "bool",
]);

const supportedTypes = new Set([
    ...supportedNumberTypes,
    ...supportedBooleanTypes,
]);

export function fromRawVariables(rawVariables: RawLogicsVariable[]): LogicsVariable[] {
    return rawVariables
        .filter(rawVariable => rawVariable.schema === "variable")
        .map(fromRawVariable);
}

function fromRawVariable(rawVariable: RawLogicsVariable): LogicsVariable {
    if (rawVariable.schema === "variable") {
        if (!supportedTypes.has(rawVariable.type)) {
            throw new Error(`Unsupported variable type: "${rawVariable.type}"`);
        }

        if (rawVariable.mode && ("options" in rawVariable.mode)) {
            if (Object.keys(rawVariable.mode.options).length === 0) {
                throw new Error(`Enum variable "${rawVariable.name}" has no options`);
            }

            let defaultOption = Object.keys(rawVariable.mode.options)[0];

            if (typeof rawVariable.default === "string") {
                if (!rawVariable.mode.options[rawVariable.default]) {
                    throw new Error(`Enum variable "${rawVariable.name}" has invalid default value`);
                }

                defaultOption = rawVariable.default;
            }

            return {
                label: rawVariable.name ?? rawVariable.variable_name,
                name: rawVariable.variable_name,
                description: rawVariable.description ?? "[ No description ]",
                private: rawVariable.private ?? false,
                content: {
                    type: "enum",
                    default: defaultOption,
                    options: rawVariable.mode.options,
                },
            };
        }

        if (supportedNumberTypes.has(rawVariable.type)) {
            let defaultNumber = rawVariable.mode?.value?.min ?? 0;

            if (typeof rawVariable.default === "number") {
                defaultNumber = rawVariable.default;
            }

            if (rawVariable.mode && rawVariable.mode.value) {
                if (rawVariable.mode.value.min !== undefined && rawVariable.mode.value.max !== undefined && rawVariable.mode.value.min > rawVariable.mode.value.max) {
                    throw new Error(`Number variable "${rawVariable.name}" has invalid value because min is greater than max`);
                }

                if (rawVariable.mode.value.min !== undefined && defaultNumber < rawVariable.mode.value.min) {
                    throw new Error(`Number variable "${rawVariable.name}" has invalid default value because it is less than min`);
                }

                if (rawVariable.mode.value.max !== undefined && defaultNumber > rawVariable.mode.value.max) {
                    throw new Error(`Number variable "${rawVariable.name}" has invalid default value because it is greater than max`);
                }
            }

            return {
                label: rawVariable.name ?? rawVariable.variable_name,
                name: rawVariable.variable_name,
                description: rawVariable.description ?? "[ No description ]",
                private: rawVariable.private ?? false,
                content: {
                    type: "number",
                    default: defaultNumber,
                    min: rawVariable.mode?.value?.min,
                    max: rawVariable.mode?.value?.max,
                },
            };
        }

        if (supportedBooleanTypes.has(rawVariable.type)) {
            let defaultBoolean = false;

            if (typeof rawVariable.default === "boolean") {
                defaultBoolean = rawVariable.default;
            }

            return {
                label: rawVariable.name ?? rawVariable.variable_name,
                name: rawVariable.variable_name,
                description: rawVariable.description ?? "[ No description ]",
                private: rawVariable.private ?? false,
                content: {
                    type: "boolean",
                    default: defaultBoolean,
                },
            };
        }

        throw new Error(`Unsupported variable type: "${rawVariable.type}"`);
    }

    throw new Error(`Unsupported variable schema: "${rawVariable.schema}"`);
}

export function fromRawData(rawData: RawLogicsData): LogicsData {
    return {
        label: rawData.name,
        description: rawData.description,
        type: rawData.type,
        size: rawData.size,
    };
}
