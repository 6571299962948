import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import App from "./App"
import { store } from "./app/store"
import { env } from "./env/env"
import "./index.css"

if (env.branch !== "main") {
    document.title = `[${env.branch}] Panda`;
} else {
    document.title = "Panda";
}

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
)
