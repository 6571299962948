import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { env } from "../../env/env";
import { fetchLogics } from "./fetchLogics";
import { Logics } from "./logics/Logics";

export interface CommitRef {
    name: string,
    hash: string,
    tag: string,
    datasetVersion: string,
}

type CommitRefsStatus =
    | { code: "idle" }
    | { code: "loading" }
    | { code: "succeeded", commits: CommitRef[], backendVersion: string }
    | { code: "failed", error: string }
;

type LogicsStatus =
    | { code: "idle" }
    | { code: "loading", commit: CommitRef }
    | { code: "succeeded", logics: Logics }
    | { code: "failed", error: string }
;

export interface LogicsState {
    fetchBackendStatus: CommitRefsStatus,
    logicsStatus: LogicsStatus,
}

const initialState: LogicsState = {
    fetchBackendStatus: { code: "idle" },
    logicsStatus: { code: "idle" },
};

export const fetchLogicsCommits = createAsyncThunk(
    "logics/fetchLogicsCommits",
    async () => {
        const response = await fetch(`${env.apiUrl}/reflist`);
        const { refs }: { refs: CommitRef[] } = await response.json();
        const sortedRefs = refs.sort().reverse();

        console.log("sortedRefs", sortedRefs);

        const backendVersion = await fetch(`${env.apiUrl}/version`)
            .then(response => response.json())
            .then(data => data.version)
            .catch(err => {
                console.error(err);
                return undefined;
            });

        return { sortedRefs, backendVersion };
    }
);

export const cachedLogics: Partial<Record<string, Logics>> = {};

export const fetchLogicsConfiguration = createAsyncThunk(
    "logics/fetchLogicsConfiguration",
    async (commit: CommitRef) => {
        if (cachedLogics[commit.hash]) {
            return cachedLogics[commit.hash]!;
        }

        try {
            const response = await fetchLogics(commit);
            cachedLogics[commit.hash] = response;

            return response;
        } catch (err) {
            console.error(err);
            throw err;
        }
    }
);

export const logicsSlice = createSlice({
    name: "logics",
    initialState,
    reducers: {
        resetLogics: (state) => {
            state.logicsStatus = { code: "idle" };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLogicsCommits.pending, (state) => {
                state.fetchBackendStatus = { code: "loading" };
            })
            .addCase(fetchLogicsCommits.fulfilled, (state, action) => {
                state.fetchBackendStatus = {
                    code: "succeeded",
                    commits: action.payload.sortedRefs,
                    backendVersion: action.payload.backendVersion,
                };
            })
            .addCase(fetchLogicsCommits.rejected, (state) => {
                state.fetchBackendStatus = {
                    code: "failed",
                    error: "Could not load commit list."
                };
            })
            .addCase(fetchLogicsConfiguration.pending, (state, action) => {
                state.logicsStatus = {
                    code: "loading",
                    commit: action.meta.arg
                };
            })
            .addCase(fetchLogicsConfiguration.fulfilled, (state, action) => {
                state.logicsStatus = {
                    code: "succeeded",
                    logics: action.payload
                };
            })
            .addCase(fetchLogicsConfiguration.rejected, (state) => {
                state.logicsStatus = {
                    code: "failed",
                    error: "Could not load configuration."
                };
            })
    },
});

export const {
    resetLogics,
} = logicsSlice.actions;

export const selectFetchBackendStatus = (state: RootState) => state.logics.fetchBackendStatus;

export const selectCommitRefs = (state: RootState) => {
    if (state.logics.fetchBackendStatus.code === "succeeded") {
        return state.logics.fetchBackendStatus.commits;
    }

    throw "invalid selectReflist when fetchBackendStatus code is not \"succeeded\"";
};

export const selectBackendVersion = (state: RootState) => {
    if (state.logics.fetchBackendStatus.code === "succeeded") {
        return state.logics.fetchBackendStatus.backendVersion;
    }

    throw "invalid selectBackendVersion when fetchBackendStatus code is not \"succeeded\"";
};

export const selectFetchLogicsStatus = (state: RootState) => state.logics.logicsStatus;

export const selectLogics = (state: RootState) => {
    if (state.logics.logicsStatus.code === "succeeded") {
        return cachedLogics[state.logics.logicsStatus.logics.commit.hash]!;
    }

    throw "invalid selectLogics when logicsStatus code is not \"succeeded\"";
};

export default logicsSlice.reducer;
