import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { disableDevMode, enableDevMode } from "../../../../app/systemSlice";
import { moveSheetTo, selectCurrentTabIndex } from "../../../editor/editorSlice";
import { selectFetchLogicsStatus } from "../../../logics/logicsSlice";
import { closeMenu } from "../../menuManagerSlice";
import { MenuContent, menuItem } from "../Menu";

export type ViewMenuType = "view";

export function ViewMenu() {
    const dispatch = useAppDispatch();
    const currentTabIndex = useAppSelector(selectCurrentTabIndex);
    const logicsStatus = useAppSelector(selectFetchLogicsStatus);
    const isDevModeActive = useAppSelector(state => state.system.devMode);

    return <MenuContent
        parts={[
            menuItem({
                label: "Go to center",
                effect: () => {
                    console.log("Go to center");
                    dispatch(moveSheetTo({ index: currentTabIndex, position: { x: 0, y: 0 } }));
                    dispatch(closeMenu());
                },
                disabled: logicsStatus.code !== "succeeded"
            }),
            isDevModeActive
                ? menuItem({
                    label: "Disable dev mode",
                    effect: () => {
                        console.log("Disable dev mode");
                        dispatch(disableDevMode());
                        dispatch(closeMenu());
                    },
                    disabled: logicsStatus.code === "succeeded" && !logicsStatus.logics.commit.name.startsWith("JSON_"),
                })
                : menuItem({
                    label: "Enable dev mode",
                    effect: () => {
                        console.log("Enable dev mode");
                        dispatch(enableDevMode());
                        dispatch(closeMenu());
                    },
                }),
        ]}
    />;
}
