import { Point2D } from "../../../common/Point2D";
import { generateUniqueId } from "../../../common/generateUniqueId";
import Connection, { ConnectionIo } from "./components/Connection";
import MemoryRef from "./components/MemoryRef";
import Operator from "./components/Operator";
import Widget from "./components/Widget";

export interface Sheet {
    id: string,
    title?: string,

    position: Point2D,
    gridSize: Point2D,
    gridPixelSize: number,

    operators: Operator[],
    connections: Connection[],

    memoryRefs: MemoryRef[],

    widgets: Widget[],

    selection: Selection,

    scale: number,
}

export type Selection =
    | SelectionOperator
    | SelectionConnection
    | SelectionMemoryRef
    | SelectionWidget
    | SelectionMultiple
    | SelectionIo
    | null
;

export interface SelectionOperator {
    type: "operator",
    operatorId: string,
}

export interface SelectionConnection {
    type: "connection",
    connectionId: string,
}

export interface SelectionMemoryRef {
    type: "memoryRef",
    memoryRefId: string,
}

export interface SelectionWidget {
    type: "widget",
    widgetId: string,
}

export interface SelectionIo {
    type: "io",
    io: ConnectionIo,
}

export interface SelectionMultiple {
    type: "multiple",
    selection: Selection[],
}

export function isSameSelection(a: Selection, b: Selection): boolean {
    if (a === b) {
        return true;
    }

    if (a === null || b === null) {
        return false;
    }

    switch (a.type) {
        case "operator":
            return b.type === "operator" && a.operatorId === b.operatorId;
        case "connection":
            return b.type === "connection" && a.connectionId === b.connectionId;
        case "memoryRef":
            return b.type === "memoryRef" && a.memoryRefId === b.memoryRefId;
        case "widget":
            return b.type === "widget" && a.widgetId === b.widgetId;
        case "io":
            if (b.type !== "io") {
                return false;
            }

            switch (a.io.type) {
                case "operator":
                    return b.io.type === "operator"
                        && a.io.operatorId === b.io.operatorId
                        && a.io.ioType === b.io.ioType
                        && a.io.ioGroup === b.io.ioGroup
                        && a.io.ioIndex === b.io.ioIndex;
                case "memoryRef":
                    return b.io.type === "memoryRef"
                        && a.io.memoryRefId === b.io.memoryRefId
                        && a.io.ioType === b.io.ioType;
            }
        case "multiple":
            return b.type === "multiple" && isSameSelectionMultiple(a, b);
    }
}

function isSameSelectionMultiple(a: SelectionMultiple, b: SelectionMultiple): boolean {
    if (a.selection.length !== b.selection.length) {
        return false;
    }

    for (let i = 0; i < a.selection.length; i++) {
        if (!isSameSelection(a.selection[i], b.selection[i])) {
            return false;
        }
    }

    return true;
}

export function isInSelection(pool: Selection, tested: Selection): boolean {
    if (pool === null) {
        return false;
    }

    if (isSameSelection(pool, tested)) {
        return true;
    }

    if (pool.type === "multiple") {
        return pool.selection.some(selection => isInSelection(selection, tested));
    }

    return false;
}

export function createSheetState(): Sheet {
    return {
        id: generateUniqueId("sheet"),
        position: { x: 0, y: 0 },
        gridSize: { x: 0, y: 0 },
        gridPixelSize: 20,

        operators: [],
        connections: [],
        memoryRefs: [],
        widgets: [],

        selection: null,
        scale: 1,
    };
};
