import { useState } from "react";

interface Zoom {
    currentLevel: number,
    scalings: number[],
}

export function useZoom(args: {
    maxAbsoluteScaling: number,
    maxAbsoluteLevel: number,
}) {
    const { maxAbsoluteScaling, maxAbsoluteLevel } = args;

    const scalingPerLevel = Math.pow(maxAbsoluteScaling, 1 / maxAbsoluteLevel);

    const positiveScalings = Array.from(Array(maxAbsoluteLevel).keys())
        .map(i => maxAbsoluteScaling / Math.pow(scalingPerLevel, i));

    const negativeScalings = positiveScalings.map((scaling) => {
        return 1 / scaling;
    });

    const scalings = [...negativeScalings, 1, ...positiveScalings.reverse()];

    const [zoom, setZoom] = useState<Zoom>({
        currentLevel: maxAbsoluteLevel,
        scalings,
    });

    return {
        zoom: zoom.scalings[zoom.currentLevel],
        zoomIn: () => {
            let currentLevel = zoom.currentLevel;

            if (zoom.currentLevel < zoom.scalings.length - 1) {
                currentLevel = zoom.currentLevel + 1;
                setZoom({
                    currentLevel,
                    scalings: zoom.scalings,
                });
            }

            return zoom.scalings[currentLevel];
        },
        zoomOut: () => {
            let currentLevel = zoom.currentLevel;

            if (zoom.currentLevel > 0) {
                currentLevel = zoom.currentLevel - 1;
                setZoom({
                    currentLevel,
                    scalings: zoom.scalings,
                });
            }

            return zoom.scalings[currentLevel];
        },
    };

}
