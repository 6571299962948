import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { fetchLogicsConfiguration, selectCommitRefs, selectFetchLogicsStatus } from "../../../logics/logicsSlice";
import css from "./LogicsSelector.module.css";

export function LogicsSelector() {
    const dispatch = useAppDispatch();
    const commitRefs = useAppSelector(selectCommitRefs);
    const logicsStatus = useAppSelector(selectFetchLogicsStatus);
    const isDevModeActive = useAppSelector(state => state.system.devMode);

    const [selectedCommitIndex, setSelectedCommitIndex] = useState<number>(0);

    console.log(selectedCommitIndex);

    if (!isDevModeActive && !commitRefs[selectedCommitIndex].name.startsWith("JSON_")) {
        setSelectedCommitIndex(commitRefs.findIndex(ref => ref.name.startsWith("JSON_")));
    }

    return <div className={css.logicsSelector}>
        {logicsStatus.code !== "loading" && <div className={css.selectorWrapper}>
            <div className={css.selectorInfo}>
                To start, please select a Logics version or load a project in the Project menu.
            </div>
            <select className={css.selectorSelect} value={selectedCommitIndex} onChange={event => setSelectedCommitIndex(Number(event.target.value))}>
                {commitRefs.map((ref, index) => <option key={`${ref.hash}-${ref.name}`} value={index}>
                    {ref.name.startsWith("JSON_") ? `Firmware ${ref.name.slice(5)}` : ref.name} {ref.datasetVersion && ` / Dataset V${ref.datasetVersion}`}
                </option>).filter((_, index) => {
                    if (isDevModeActive) {
                        return true;
                    }
                    return commitRefs[index].name.startsWith("JSON_");
                })}
            </select>
        </div>}
        {logicsStatus.code === "loading" && <div className={css.selectorWrapper}>
            <div className={css.selectorInfo}>
                {`Loading ${logicsStatus.commit.name}...`}
            </div>
        </div>}
        {logicsStatus.code !== "loading" && <button
            className={css.selectorButton}
            // disabled={logicsStatus.code === "loading"}
            onClick={() => dispatch(fetchLogicsConfiguration(commitRefs[selectedCommitIndex]))}
        >Load</button>}
    </div>;
}
