import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { moveSheetTo, selectCurrentTabIndex } from "../../../editor/editorSlice";
import { closeMenu } from "../../menuManagerSlice";
import { MenuContent, menuItem } from "../Menu";

export type SheetMenuType = "sheet";

export function SheetMenu() {
    const dispatch = useAppDispatch();
    const currentTabIndex = useAppSelector(selectCurrentTabIndex);

    return <MenuContent
        parts={[
            menuItem({
                label: "Go to center",
                effect: () => {
                    console.log("Go to center");
                    dispatch(moveSheetTo({ index: currentTabIndex, position: { x: 0, y: 0 } }));
                    dispatch(closeMenu());
                }
            }),
        ]}
    />;
}
