export type UniqueIdMap = Map<string, number>;
export type UniqueIdRecord = Record<string, number>;
const idMap: UniqueIdMap = new Map<string, number>();

export function seed(category: string, value: number) {
    idMap.set(category, value);
}

export function seedAll(seedMap: UniqueIdRecord) {
    idMap.clear();
    for (const [category, value] of Object.entries(seedMap)) {
        idMap.set(category, value);
    }
}

export function generateUniqueId(category: string) {
    const oldId = idMap.get(category) ?? 0;
    idMap.set(category, oldId + 1);
    return `${category}-${oldId}`;
}

export function getUniqueIdMap(): UniqueIdRecord {
    return Object.fromEntries(idMap.entries());
}
