import { useSelector } from "react-redux";
import "./App.css";
import { useAppDispatch } from "./app/hooks";
import { Editor } from "./features/editor/Editor";
import { fetchLogicsCommits, selectFetchBackendStatus } from "./features/logics/logicsSlice";
import { MenuManager } from "./features/menuManager/MenuManager";
import { WindowManager } from "./features/windowManager/WindowManager";
import { LoadingApp } from "./pages/LoadingApp/LoadingApp";

export const appMainElementId = "app-main-element";

function App() {
    const dispatch = useAppDispatch();
    const reflistStatus = useSelector(selectFetchBackendStatus);

    if (reflistStatus.code === "idle") {
        dispatch(fetchLogicsCommits());
    }

    return <div className="App" id={appMainElementId}>
        {/* <select>
            {reflistStatus.code === "succeeded" &&
                reflistStatus.commits.map(
                    commit => <option key={commit.hash} value={commit.hash}>{commit.tag}</option>
                )
            }
        </select> */}
        {(reflistStatus.code === "loading" || reflistStatus.code === "failed") && <>
            <LoadingApp/>
        </>}
        {reflistStatus.code === "succeeded" && <>
            <Editor/>
            <WindowManager/>
            <MenuManager/>
        </>}
    </div>;
}

export default App
