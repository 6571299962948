import { faAngleDown, faAngleUp, faFloppyDisk, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { createDeviceSlot, deleteDeviceSlot, deleteMemory, updateDeviceSlot } from "../../../../editor/editorSlice";
import { Device, Input, Output, createDevice } from "../../../../editor/interfaces/components/Device";
import Memory from "../../../../editor/interfaces/components/Memory";
import { VariableContent } from "../../../../editor/interfaces/components/Variable";
import { selectLogics } from "../../../../logics/logicsSlice";
import { WindowTypeAndPropsDefinition } from "../../../windowManagerSlice";
import { BooleanVariable, EnumVariable, NumberVariable } from "../BlockConfigWindow/BlockConfigWindow";
import css from "./DeviceManagerWindow.module.css";

export interface DeviceManagerWindowProps {
    initialCategory?: string,
}

export type DeviceManagerWindowTypeAndProps = WindowTypeAndPropsDefinition<
    "deviceManager",
    DeviceManagerWindowProps
>;

export function DeviceManagerWindow(props: DeviceManagerWindowProps & { id: string }) {
    const dispatch = useAppDispatch();

    const logics = useAppSelector(selectLogics);
    const devices = useAppSelector(state => state.editor.devices);
    const memories = useAppSelector(state => state.editor.memories);
    const [selectedDeviceIndex, setSelectedDeviceIndex] = useState<number | null>(null);
    const [deviceScratchPad, setDeviceScratchPad] = useState<Device | null>(null);

    const deviceConfig = deviceScratchPad ? logics.device[deviceScratchPad.name] : undefined;

    const setVariableContent = (configType: string, configIndex: number, variableName: string) => (content: VariableContent) => {
        console.log(configType, configIndex, variableName, deviceScratchPad?.configs);
        if (deviceScratchPad !== null) {
            const device = {
                ...deviceScratchPad,
                configs: {
                    ...deviceScratchPad.configs,
                    [configType]: deviceScratchPad.configs[configType].map((config, index) => {
                        if (index !== configIndex) {
                            return config;
                        }

                        return {
                            ...config,
                            variables: {
                                ...config.variables,
                                [variableName]: {
                                    ...config.variables[variableName],
                                    content,
                                },
                            },
                        }
                    }),
                },
            };

            setDeviceScratchPad(device);
        }
    };

    const setInput = (inputIndex: number, newInput: Input) => {
        if (deviceScratchPad !== null) {
            const device = {
                ...deviceScratchPad,
                inputs: deviceScratchPad.inputs.map((input, index) => {
                    if (index !== inputIndex) {
                        return input;
                    }

                    return newInput;
                }),
            };

            setDeviceScratchPad(device);
        }
    }

    const setOutput = (outputIndex: number, newOutput: Output) => {
        if (deviceScratchPad !== null) {
            const device = {
                ...deviceScratchPad,
                outputs: deviceScratchPad.outputs.map((output, index) => {
                    if (index !== outputIndex) {
                        return output;
                    }

                    return newOutput;
                }),
            };

            setDeviceScratchPad(device);
        }
    }

    const [metadataOpen, setMetadataOpen] = useState<boolean>(false);
    const [inputsOpen, setInputsOpen] = useState<boolean>(false);
    const [outputsOpen, setOutputsOpen] = useState<boolean>(false);
    const [configurationsOpen, setConfigurationsOpen] = useState<boolean>(false);

    const [inputOpen, setInputOpen] = useState<boolean[]>([]);
    const [outputOpen, setOutputOpen] = useState<boolean[]>([]);
    const [configurationOpen, setConfigurationOpen] = useState<boolean[]>([]);
    const [configurationDetailsOpen, setConfigurationDetailsOpen] = useState<boolean[][]>([]);

    return <div className={css.content}>
        <div className={css.sideBar}>
            <button
                className={css.creatorButton}
                onClick={() => {
                    dispatch(createDeviceSlot());
                }}
            >
                Create device
            </button>
            <div className={css.list}>
                {devices.map((device, index) => {
                    return <>
                        <div
                            key={index}
                            className={classNames(css.listItem, {
                                [css.listItemSelected]: index === selectedDeviceIndex,
                                [css.listItemUninitialized]: device === null ||
                                    device.address === undefined ||
                                    devices.map(device => device?.address)
                                        .filter((device, otherIndex) => device !== undefined && otherIndex !== index)
                                        .includes(device?.address),
                            })}
                            onClick={() => {
                                setSelectedDeviceIndex(index);
                                setDeviceScratchPad(device);
                                if (device) {
                                    setMetadataOpen(device.address === undefined || device.address === "");
                                    setInputsOpen(false);
                                    setOutputsOpen(false);
                                    setConfigurationsOpen(false);

                                    setInputOpen(device.inputs.map(() => false));
                                    setOutputOpen(device.outputs.map(() => false));

                                    const configs = Object.values(device.configs);
                                    setConfigurationOpen(configs.map(() => false));
                                    setConfigurationDetailsOpen(configs.map(config => config.map(() => false)));
                                }
                            }}
                        >{device?.label ?? "[ Uninitialized ]"}</div>
                    </>;
                })}
            </div>
        </div>
        {selectedDeviceIndex !== null && <div key={selectedDeviceIndex} className={css.deviceEditor}>
            <div className={css.row}>
                <button
                    className={css.button}
                    onClick={() => {
                        if (deviceScratchPad !== null) {
                            dispatch(updateDeviceSlot({
                                index: selectedDeviceIndex,
                                device: deviceScratchPad,
                            }));

                            const device = devices[selectedDeviceIndex];

                            if (device === null) {
                                return;
                            }

                            console.log(deviceScratchPad.name, device.name);

                            if (deviceScratchPad.name && deviceScratchPad.name !== device.name) {
                                const associatedMemories = Object.values(memories).flatMap(memories => {
                                    return memories
                                        .map((memory, index) => [memory, index] as [Memory, number])
                                        .filter(([memory]) => memory.source.type !== "local" && memory.source.deviceId === device.id);
                                });

                                if (associatedMemories) {
                                    console.log("associated memories", associatedMemories);
                                }

                                associatedMemories.toReversed().forEach(([memory, index]) => {
                                    dispatch(deleteMemory({
                                        memoryName: memory.name,
                                        index,
                                    }));
                                });
                            }
                        }
                    }}
                ><FontAwesomeIcon icon={faFloppyDisk}/></button>
                <select
                    className={classNames(css.select, css.deviceLabelSelector)}
                    value={deviceScratchPad?.label ?? ""}
                    onChange={event => {
                        console.log("DEVICE CHANGE");
                        const device = createDevice(
                            event.target.value,
                            logics
                        );
                        console.log({device});
                        setDeviceScratchPad(device);

                        setMetadataOpen(true);
                        setInputsOpen(false);
                        setOutputsOpen(false);
                        setConfigurationsOpen(false);

                        setInputOpen(device.inputs.map(() => false));
                        setOutputOpen(device.outputs.map(() => false));

                        const configs = Object.values(device.configs);
                        setConfigurationOpen(configs.map(() => false));
                        setConfigurationDetailsOpen(configs.map(config => config.map(() => false)));
                    }}
                >
                    {deviceScratchPad === null && <option value={""}/>}
                    {Object.keys(logics.device).map(name => {
                            return <option key={name} value={name}>{name}</option>;
                        })
                    }
                </select>
                {selectedDeviceIndex !== null && <button
                    className={classNames(css.button, css.deleteButton)}
                    onClick={() => {
                        setDeviceScratchPad(null);
                        setSelectedDeviceIndex(null);
                        dispatch(deleteDeviceSlot({
                            index: selectedDeviceIndex,
                        }));

                        const device = devices[selectedDeviceIndex];

                        if (device === null) {
                            return;
                        }

                        const targetAddress = device.address;

                        const associatedMemories = Object.values(memories).flatMap(memories => {
                            return memories
                                .map((memory, index) => [memory, index] as [Memory, number])
                                .filter(([memory]) => memory.source.type !== "local" && memory.source.deviceId === device.id);
                        });

                        if (associatedMemories) {
                            console.log("associated memories", associatedMemories);
                        }

                        associatedMemories.toReversed().forEach(([memory, index]) => {
                            dispatch(deleteMemory({
                                memoryName: memory.name,
                                index,
                            }));
                        });
                    }}
                ><FontAwesomeIcon icon={faTrash}/></button>}
            </div>
            {deviceScratchPad !== null && deviceConfig && <>
                <div className={css.row}>
                    <div className={css.sectionTitle}
                        onClick={() => {
                            setMetadataOpen(!metadataOpen);
                        }}
                    >
                        Metadata
                        {metadataOpen && <FontAwesomeIcon icon={faAngleUp}/>}
                        {!metadataOpen && <FontAwesomeIcon icon={faAngleDown}/>}
                    </div>
                </div>
                {metadataOpen && <div className={css.innerRow}>
                    <div className={css.row}>
                        <div className={css.label}>Address</div>
                        <select
                            className={css.select}
                            value={deviceScratchPad.address}
                            onChange={event => {
                                if (deviceScratchPad !== null) {
                                    setDeviceScratchPad({
                                        ...deviceScratchPad,
                                        address: event.target.value,
                                    });
                                }
                            }}
                        >
                            {deviceScratchPad.address === undefined && <option value={""}/>}
                            {deviceConfig.addresses.map(address => {
                                return <option key={address} value={address}>{address}</option>;
                            })}
                        </select>
                    </div>
                </div>}
                <div className={css.row}>
                    <div className={css.sectionTitle}
                        onClick={() => {
                            setInputsOpen(!inputsOpen);
                        }}
                    >
                        Inputs
                        {inputsOpen && <FontAwesomeIcon icon={faAngleUp}/>}
                        {!inputsOpen && <FontAwesomeIcon icon={faAngleDown}/>}
                    </div>
                </div>
                {inputsOpen && <div className={css.innerRow}>
                    {deviceScratchPad.inputs.map((input, index) => {
                        const inputConfig = deviceConfig.input[input.name];

                        return <React.Fragment key={index}>
                            <div
                                className={css.row}
                                onClick={() => {
                                    setInputOpen(inputs => {
                                        const newInputs = [...inputs];
                                        newInputs[index] = !newInputs[index];
                                        return newInputs;
                                    });
                                }}
                            >
                                <div className={css.sectionTitle}>
                                    {input.name}
                                    {inputOpen[index] && <FontAwesomeIcon icon={faAngleUp}/>}
                                    {!inputOpen[index] && <FontAwesomeIcon icon={faAngleDown}/>}
                                </div>
                            </div>
                            {inputOpen[index] && <div className={css.innerRow}>
                                <div className={css.row}>
                                    <div className={css.label}>
                                        Mode
                                    </div>
                                    <select
                                        className={css.select}
                                        value={input.mode}
                                        onChange={event => {
                                            setInput(index, {
                                                ...input,
                                                mode: event.target.value,
                                            });
                                        }}
                                    >
                                        {inputConfig.mode.map(mode => <option
                                            key={mode}
                                            value={mode}
                                        >{mode}</option>)}
                                    </select>
                                </div>
                                <div className={css.row}>
                                    <div className={css.label}>
                                        Pull
                                    </div>
                                    <select
                                        className={css.select}
                                        value={input.pull}
                                        onChange={event => {
                                            setInput(index, {
                                                ...input,
                                                pull: event.target.value,
                                            });
                                        }}
                                    >
                                        {inputConfig.pull.map(pull => <option
                                            key={pull}
                                            value={pull}
                                        >{pull}</option>)}
                                    </select>
                                </div>
                            </div>}
                        </React.Fragment>;
                    })}
                </div>}
                <div className={css.row}>
                    <div className={css.sectionTitle}
                        onClick={() => {
                            setOutputsOpen(!outputsOpen);
                        }}
                    >
                        Outputs
                        {outputsOpen && <FontAwesomeIcon icon={faAngleUp}/>}
                        {!outputsOpen && <FontAwesomeIcon icon={faAngleDown}/>}
                    </div>
                </div>
                {outputsOpen && <div className={css.innerRow}>
                    {deviceScratchPad.outputs.map((output, index) => {
                        const outputConfig = deviceConfig.output[output.name];

                        return <React.Fragment key={index}>
                            <div
                                className={css.row}
                                onClick={() => {
                                    setOutputOpen(outputs => {
                                        const newOutputs = [...outputs];
                                        newOutputs[index] = !newOutputs[index];
                                        return newOutputs;
                                    });
                                }}
                            >
                                <div className={css.sectionTitle}>
                                    {output.name}
                                    {outputOpen[index] && <FontAwesomeIcon icon={faAngleUp}/>}
                                    {!outputOpen[index] && <FontAwesomeIcon icon={faAngleDown}/>}
                                </div>
                            </div>
                            {outputOpen[index] && <div className={css.innerRow}>
                                <div className={css.row}>
                                    <div className={css.label}>
                                        Mode
                                    </div>
                                    <select
                                        className={css.select}
                                        value={output.mode}
                                        onChange={event => {
                                            setOutput(index, {
                                                ...output,
                                                mode: event.target.value,
                                            });
                                        }}
                                    >
                                        {outputConfig.mode.map(mode => <option
                                            key={mode}
                                            value={mode}
                                        >{mode}</option>)}
                                    </select>
                                </div>
                                <div className={css.row}>
                                    <div className={css.label}>
                                        Signal
                                    </div>
                                    <select
                                        className={css.select}
                                        value={output.signal}
                                        onChange={event => {
                                            setOutput(index, {
                                                ...output,
                                                signal: event.target.value,
                                            });
                                        }}
                                    >
                                        {outputConfig.signal.map(signal => <option
                                            key={signal}
                                            value={signal}
                                        >{signal}</option>)}
                                    </select>
                                </div>
                            </div>}
                        </React.Fragment>;
                    })}
                </div>}
                <div className={css.row}>
                    <div className={css.sectionTitle}
                        onClick={() => {
                            setConfigurationsOpen(!configurationsOpen);
                        }}
                    >
                        Configurations
                        {configurationsOpen && <FontAwesomeIcon icon={faAngleUp}/>}
                        {!configurationsOpen && <FontAwesomeIcon icon={faAngleDown}/>}
                    </div>
                </div>
                {configurationsOpen && <div className={css.innerRow}>
                    {Object.entries(deviceConfig.config).map(([configType, config], index) => {
                        const configConfig = logics.config[configType];
                        const deviceConfigs = deviceScratchPad.configs[configConfig.type];
                        const configVariables = logics.config[configConfig.type].variables;

                        return <React.Fragment key={index}>
                            <div
                                className={css.row}
                                onClick={() => {
                                    setConfigurationOpen(configuration => {
                                        const newConfigurations = [...configuration];
                                        newConfigurations[index] = !newConfigurations[index];
                                        return newConfigurations;
                                    });
                                }}
                            >
                                <div className={css.sectionTitle}>
                                    {configConfig.label}
                                    {configurationOpen[index] && <FontAwesomeIcon icon={faAngleUp}/>}
                                    {!configurationOpen[index] && <FontAwesomeIcon icon={faAngleDown}/>}
                                </div>
                            </div>
                            {configurationOpen[index] && <div className={css.innerRow}>
                                {deviceConfigs.map((config, configIndex) => {
                                    const variableHtml = configVariables.map((logicsVariable, variableIndex) => {
                                        const variable = config.variables[logicsVariable.label];

                                        return <div key={variableIndex} className={css.row}>
                                            <div className={css.label}>
                                                {logicsVariable.label}
                                            </div>
                                            {logicsVariable.private && variable.content.value}
                                            {!logicsVariable.private && <>
                                                {variable.content.type === "boolean" && <BooleanVariable
                                                    content={variable.content}
                                                    config={logicsVariable}
                                                    onChange={setVariableContent(
                                                        configConfig.type,
                                                        configIndex,
                                                        logicsVariable.label
                                                    )}
                                                />}
                                                {variable.content.type === "enum" && <EnumVariable
                                                    content={variable.content}
                                                    config={logicsVariable}
                                                    onChange={setVariableContent(
                                                        configConfig.type,
                                                        configIndex,
                                                        logicsVariable.label
                                                    )}
                                                />}
                                                {variable.content.type === "number" && <NumberVariable
                                                    content={variable.content}
                                                    config={logicsVariable}
                                                    onChange={setVariableContent(
                                                        configConfig.type,
                                                        configIndex,
                                                        logicsVariable.label
                                                    )}
                                                />}
                                            </>}
                                        </div>;
                                    });

                                    return <React.Fragment key={configIndex}>
                                        {<div className={css.innerRow}>
                                            <div
                                                className={css.row}
                                                onClick={() => {
                                                    setConfigurationDetailsOpen(details => {
                                                        const newDetails = [...details];
                                                        newDetails[index][configIndex] = !newDetails[index][configIndex];
                                                        return newDetails;
                                                    });
                                                }}
                                            >
                                                <div className={css.sectionTitle}>
                                                    {config.details ?? configIndex}
                                                    {configurationDetailsOpen[index][configIndex] && <FontAwesomeIcon icon={faAngleUp}/>}
                                                    {!configurationDetailsOpen[index][configIndex] && <FontAwesomeIcon icon={faAngleDown}/>}
                                                </div>
                                            </div>
                                            {configurationDetailsOpen[index][configIndex] && <div className={css.innerRow}>
                                                {variableHtml}
                                            </div>}
                                        </div>}
                                    </React.Fragment>
                                })}
                            </div>}
                        </React.Fragment>;
                    })}
                </div>}
            </>}
            <div className={css.bottomMargin}/>
        </div>}
    </div>;
}
