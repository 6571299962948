import { forwardRef } from "react";
import { useAppDispatch } from "../../../../../app/hooks";
import { openContextMenu } from "../../../../menuManager/menuManagerSlice";
import { cancelPreview } from "../../../editorSlice";
import css from "./SheetBackground.module.css";

interface SheetBackgroundProps {
    bottom: number,
    right: number,
    gridPixelSize: number,
    onMovementDragStart: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    onStartBlockCreation: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
}

export const SheetBackground = forwardRef((
    props: SheetBackgroundProps,
    ref: React.ForwardedRef<HTMLDivElement>,
) => {
    const dispatch = useAppDispatch();
    const {
        bottom,
        right,
        gridPixelSize,
        onMovementDragStart,
        onStartBlockCreation,
        onClick,
    } = props;

    return <div
        ref={ref}
        id="sheetBackground"
        className={css.sheetBackground}
        style={{
            width: `calc(100% + ${2 * gridPixelSize}px)`,
            height: `calc(100% + ${2 * gridPixelSize}px)`,
            bottom,
            right,
            backgroundSize: `${gridPixelSize}px ${gridPixelSize}px`,
        }}
        onMouseDown={(event) => {
            onMovementDragStart(event);
            onStartBlockCreation(event);
        }}
        onClick={event => {
            onClick?.(event);
        }}
        onAuxClick={event => {
            if (event.button == 2) {
                dispatch(openContextMenu({
                    sourceId: 'sheet',
                    position: {
                        x: event.clientX,
                        y: event.clientY,
                    }
                }));
                dispatch(cancelPreview());
            }
        }}
    />;
});
