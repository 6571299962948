import { useRef } from "react";
import { useAppSelector } from "../../app/hooks";
import { selectFetchLogicsStatus } from "../logics/logicsSlice";
import css from "./Editor.module.css";
import { Header } from "./components/header/Header";
import { LogicsSelector } from "./components/logicsSelector/LogicsSelector";
import { Sheet } from "./components/sheet/Sheet";
import { SideBar } from "./components/sideBar/SideBar";
import { Tabs } from "./components/tabs/Tabs";
import { selectCurrentTabIndex } from "./editorSlice";

function TabsAndSheets() {
    const currentTabIndex = useAppSelector(selectCurrentTabIndex);

    return <div className={css.tabsAndSheets}>
        <Tabs/>
        <Sheet sheetIndex={currentTabIndex}/>
    </div>;
}

function DevArea() {
    return <div className={css.devArea}>
        <SideBar/>
        <TabsAndSheets/>
    </div>;
}

export function Editor() {
    const ref = useRef<HTMLDivElement>(null);
    const logicsStatus = useAppSelector(selectFetchLogicsStatus);

    return <div className={css.editor}>
        <Header/>
        {logicsStatus.code !== "succeeded" && <LogicsSelector/>}
        {logicsStatus.code === "succeeded" && <DevArea/>}
    </div>;
}
