import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import { env } from "../env/env";
import editorReducer from "../features/editor/editorSlice";
import logicsReducer from "../features/logics/logicsSlice";
import menuManagerReducer from "../features/menuManager/menuManagerSlice";
import windowManagerReducer from "../features/windowManager/windowManagerSlice";
import systemReducer from "./systemSlice";

export const store = configureStore({
    reducer: {
        editor: editorReducer,
        windowManager: windowManagerReducer,
        menuManager: menuManagerReducer,
        logics: logicsReducer,
        system: systemReducer,
    },
    devTools: env.branch === "dev",
});

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>

export type GenericSelector<S> = (state: RootState) => S;
