import classNames from "classnames";
import React, { ReactNode } from "react";
import { Point2D } from "../../../common/Point2D";
import css from "./Menu.module.css";

interface MenuItem {
    type: "item",
    icon?: string,
    label: string,
    effect: () => void,
    disabled?: boolean,
}

export function menuItem(arg: Omit<MenuItem, "type">): MenuItem {
    return {
        type: "item",
        disabled: false,
        ...arg,
    };
}

interface MenuSeparator {
    type: "separator",
}

export function menuSeparator(): MenuSeparator {
    return { type: "separator" };
}

export type MenuPart =
    | MenuItem
    | MenuSeparator
;

export interface MenuProps {
    children?: ReactNode;
    position: Point2D,
}

export function Menu(props: MenuProps) {
    return <div
        className={css.menu}
        style={{
            top: 0,
            left: 0,
            "--menu-x": `${props.position.x}px`,
            "--menu-y": `${props.position.y}px`,
        } as React.CSSProperties}
        onMouseDown={event => event.stopPropagation()}
    >
        {props.children}
    </div>;
}

export interface MenuContentProps {
    parts: MenuPart[],
}

export function MenuContent(props: MenuContentProps) {
    return props.parts.map((part, index) => {
        switch (part.type) {
            case "item":
                return <div
                    key={index}
                    className={
                        classNames(css.menuItem, { [css.menuItemDisabled]: part.disabled })
                    }
                    onClick={() => {
                        if (!part.disabled) {
                            part.effect()
                        }
                    }}
                >{part.label}</div>;
            case "separator":
                return <div
                    key={index}
                    className={css.menuSeparator}
                />;
        }
    });
}
