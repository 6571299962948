import React, { MouseEventHandler, ReactNode, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Point2D } from "../../../common/Point2D";
import { useDrag } from "../../../common/useDrag";
import { closeWindow, createWindow, focusWindow, moveWindowByOffset, resizeWindowByOffset, selectWindow } from "../windowManagerSlice";
import css from "./Window.module.css";
import { selectLogics } from "../../logics/logicsSlice";

interface WindowProps {
    children?: ReactNode;
    id: string,
}

export const Window: React.FC<WindowProps> = props => {
    const dispatch = useAppDispatch();
    const window = useAppSelector(selectWindow(props.id));
    const logics = useAppSelector(selectLogics);

    const titleBarRef = useRef<HTMLDivElement>(null);
    const resizeCornerRef = useRef<HTMLDivElement>(null);
    const lastMouseClientPosition = useRef<Point2D>({ x: 0, y: 0 });

    const onMovementDragStart = useDrag({
        onMouseMove({delta}) {
            dispatch(
                moveWindowByOffset({
                    windowId: props.id,
                    delta,
                })
            );
        },
    });

    const resizeHandlerMouseMove = (event: MouseEvent) => {
        const dx = event.clientX - lastMouseClientPosition.current.x;
        const dy = event.clientY - lastMouseClientPosition.current.y;

        lastMouseClientPosition.current = {
            x: event.clientX,
            y: event.clientY,
        };

        dispatch(
            resizeWindowByOffset({
                windowId: props.id,
                delta: { x: dx, y: dy },
            })
        );
    };

    const resizeHandlerMouseUp = () => {
        document.removeEventListener("mousemove", resizeHandlerMouseMove);
        document.removeEventListener("mouseup", resizeHandlerMouseUp);
    };

    const resizeHandlerMouseDown: MouseEventHandler = event => {
        lastMouseClientPosition.current = {
            x: event.clientX,
            y: event.clientY,
        };

        document.addEventListener("mousemove", resizeHandlerMouseMove);
        document.addEventListener("mouseup", resizeHandlerMouseUp);
    };

    return <div
        className={css.window}
        style={{
            left: window.position.x,
            top: window.position.y,
            width: window.size.x,
            height: window.size.y,
        }}
        onMouseDown={() => dispatch(focusWindow(props.id))}
    >
        <div
            ref={titleBarRef}
            onMouseDown={onMovementDragStart}
            className={css.windowTitleBar}
        >
            <div className={css.windowTitle}>
                {window.title}
            </div>
            {logics.readme.find(([id, _]) => id === window.title)?.[1] && <div
                className={css.windowTitleBarButton}
                onClick={() => {
                    dispatch(createWindow({
                        title: `Readme - ${window.title}`,
                        size: { x: 800, y: 600 },
                        typeAndProps: {
                            type: "readme",
                            props: {
                                source: window.title,
                            },
                        },
                    }));
                }}
            >?</div>}
            <div
                className={css.windowTitleBarButton}
                onClick={() => dispatch(closeWindow(props.id))}
            >X</div>
        </div>
        <div className={css.windowContent}>
            {props.children}
        </div>
        <div
            className={css.windowResizeCorner}
            onMouseDown={resizeHandlerMouseDown}
        ></div>
    </div>;
}
