import classNames from "classnames";
import { useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { createSheet, deleteSheet, moveTab, selectCurrentTabIndex, selectSheets, selectTab, updateSheetTitle } from "../../editorSlice";
import { Sheet } from "../../interfaces/Sheet";
import css from "./Tabs.module.css";

interface TabProps {
    index: number;
    sheet: Sheet;
}

function Tab(props: TabProps) {
    const dispatch = useAppDispatch();
    const currentTabIndex = useAppSelector(selectCurrentTabIndex);

    const isSelected = props.index === currentTabIndex;

    const [editing, setEditing] = useState(false);

    return <div
        className={
            classNames(
                css.tab,
                { [css.selected]: isSelected }
            )
        }
        draggable={!editing}
        onDragStart={event => {
            event.dataTransfer.setData("text", props.sheet.id);
        }}
        onDragOver={event => {
            event.preventDefault();
        }}
        onDrop={event => {
            dispatch(moveTab({
                fromId: event.dataTransfer.getData("text"),
                toId: props.sheet.id,
            }));
        }}
        onClick={() => {
            console.log(props.sheet.id);
            dispatch(selectTab(props.index));
        }}
        onDoubleClick={() => setEditing(true)}
        onAuxClick={event => {
            if (event.button === 1) {
                event.stopPropagation();
                dispatch(deleteSheet({ sheetIndex: props.index }));
            }
        }}
    >
        {editing
            ? <input
                className={classNames(
                    css.tabTitle,
                    css.tabEdit,
                    { [css.tabEditSelected]: isSelected }
                )}
                defaultValue={props.sheet.title ?? "[ New tab ]"}
                autoFocus={true}
                onFocus={event => {
                    event.target.select();
                    event.target.style.width = "0px";
                    const boundingRect = event.target.getBoundingClientRect();
                    const size = Math.min(event.target.scrollWidth - boundingRect.width, 300);
                    event.target.style.width = `${size}px`;
                }}
                onBlur={event => {
                    setEditing(false);
                    dispatch(updateSheetTitle({
                        index: currentTabIndex,
                        title: event.currentTarget.value,
                    }));
                }}
                onChange={event => {
                    event.target.style.width = "0px";
                    const boundingRect = event.target.getBoundingClientRect();
                    const size = Math.min(event.target.scrollWidth - boundingRect.width, 300);
                    event.target.style.width = `${size}px`;
                }}
                onKeyDown={event => {
                    const key = event.key;

                    if (key === "Enter" || key === "Escape") {
                        setEditing(false);
                    }

                    if (key === "Enter") {
                        dispatch(updateSheetTitle({
                            index: currentTabIndex,
                            title: event.currentTarget.value,
                        }));
                    }
                }}
            />
            : <span
                className={css.tabTitle}
            >{ props.sheet.title ?? "[ New tab ]" }</span>
        }
        <CloseTabButton isSelected={isSelected} sheetIndex={props.index}/>
    </div>;
}

function CloseTabButton(props: { isSelected: boolean, sheetIndex: number }) {
    const dispatch = useAppDispatch();

    const lineClassname = classNames(
        css.tabButtonLine,
        { [css.selectedCloseTabButton]: props.isSelected }
    );

    return <div
        className={css.closeTabButton}
        onClick={event => {
            event.stopPropagation();
            dispatch(deleteSheet({ sheetIndex: props.sheetIndex }));
        }}
    >
        <svg height={12} width={12} xmlns="http://www.w3.org/2000/svg">
            <line className={lineClassname} x1="15%" y1="15%" x2="85%" y2="85%"/>
            <line className={lineClassname} x1="85%" y1="15%" x2="15%" y2="85%"/>
        </svg>
    </div>;
}

function CreateTabButton() {
    const dispatch = useAppDispatch();

    return <div className={css.createTabButton} onClick={
        () => dispatch(createSheet())
    }>
        <svg height={13} width={13} xmlns="http://www.w3.org/2000/svg">
            <line className={css.tabButtonLine} x1="50%" y1="0" x2="50%" y2="100%"/>
            <line className={css.tabButtonLine} x1="0" y1="50%" x2="100%" y2="50%"/>
        </svg>
    </div>;
}

export function Tabs() {
    const dispatch = useAppDispatch();
    const sheets = useAppSelector(selectSheets);
    const ref = useRef<HTMLDivElement>(null);

    return <div
        ref={ref}
        className={css.tabContainer}
        onWheel={event => {
            if (ref.current === null) {
                return;
            }

            console.log(event.deltaY);
            console.log({ width: ref.current.scrollWidth, left: ref.current.scrollLeft });
            ref.current.scrollLeft += event.deltaY;
        }}
    >
        {sheets.map(
            (sheet, index) => <Tab key={sheet.id} index={index} sheet={sheet}/>
        )}
        <CreateTabButton/>
    </div>;
}
