import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { Point2D } from "./Point2D";

export function useDrag(
    callbacks: {
        onMouseDown?: (args: { event: MouseEvent }) => void,
        onMouseMove?: (args: { event: MouseEvent, delta: Point2D, totalDelta: Point2D }) => void,
        onMouseUp?: (args: { event: MouseEvent, totalDelta: Point2D, didMouseMove: boolean }) => void,
        preventOnMouseDownIf?: (event: MouseEvent) => boolean,
        preventOnMouseMoveIf?: (event: MouseEvent) => boolean,
        preventOnMouseUpIf?: (event: MouseEvent) => boolean,
    },
    dependencies: any[] = [],
) {
    const lastMouseClientPosition = useRef<Point2D>({ x: 0, y: 0 });
    const initialMouseClientPosition = useRef<Point2D>({ x: 0, y: 0 });
    const mouseMoved = useRef(false);

    const [dragging, setDragging] = useState(false);

    const mouseMove = (event: MouseEvent) => {
        if (callbacks.preventOnMouseMoveIf?.(event)) {
            return;
        }

        const dx = event.clientX - lastMouseClientPosition.current.x;
        const dy = event.clientY - lastMouseClientPosition.current.y;

        if (dx !== 0 || dy !== 0) {
            mouseMoved.current = true;
        }

        const totalDx = event.clientX - initialMouseClientPosition.current.x;
        const totalDy = event.clientY - initialMouseClientPosition.current.y;

        callbacks.onMouseMove?.({
            event,
            delta: { x: dx, y: dy },
            totalDelta: { x: totalDx, y: totalDy },
        });

        lastMouseClientPosition.current = {
            x: event.clientX,
            y: event.clientY,
        };
    };

    const mouseUp = (event: MouseEvent) => {
        if (callbacks.preventOnMouseUpIf?.(event)) {
            return;
        }

        const totalDx = event.clientX - initialMouseClientPosition.current.x;
        const totalDy = event.clientY - initialMouseClientPosition.current.y;

        callbacks.onMouseUp?.({
            event,
            totalDelta: { x: totalDx, y: totalDy },
            didMouseMove: mouseMoved.current,
        });

        setDragging(false);
        document.removeEventListener("mousemove", mouseMove);
        document.removeEventListener("mouseup", mouseUp);
    };

    const mouseDown: MouseEventHandler = event => {
        if (callbacks.preventOnMouseDownIf?.(event.nativeEvent)) {
            return;
        }

        mouseMoved.current = false;

        lastMouseClientPosition.current = {
            x: event.clientX,
            y: event.clientY,
        };

        initialMouseClientPosition.current = {
            x: event.clientX,
            y: event.clientY,
        };

        callbacks.onMouseDown?.({event: event.nativeEvent});

        setDragging(true);
    };

    useEffect(() => {
        if (dragging) {
            document.addEventListener("mousemove", mouseMove);
            document.addEventListener("mouseup", mouseUp);
        }

        return () => {
            document.removeEventListener("mousemove", mouseMove);
            document.removeEventListener("mouseup", mouseUp);
        };
    }, [dragging, ...dependencies]);

    return mouseDown;
}
