import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

export type WindowManagerState = {
    devMode: boolean,
}

const initialState: WindowManagerState = {
    devMode: false,
};

export const systemSlice = createSlice({
    name: "system",
    initialState,
    reducers: {
        enableDevMode: (state) => {
            state.devMode = true;
        },
        disableDevMode: (state) => {
            state.devMode = false;
        },
        toggleDevMode: (state) => {
            state.devMode = !state.devMode;
        }
    },
    extraReducers: (builder) => {
    },
});

export const {
    enableDevMode,
    disableDevMode,
    toggleDevMode,
} = systemSlice.actions;

export default systemSlice.reducer;
