import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import css from "./MenuManager.module.css";
import { Menu } from "./menu/Menu";
import { MenuType } from "./menu/menuImpl";
import { ProjectMenu } from "./menu/menuImpl/ProjectMenu";
import { SheetMenu } from "./menu/menuImpl/SheetMenu";
import { ViewMenu } from "./menu/menuImpl/ViewMenu";
import { closeMenu, selectCurrentMenu } from "./menuManagerSlice";

function OutsideMenuArea() {
    const dispatch = useAppDispatch();

    return <div
        className={css.outsideMenuArea}
        onClick={() => dispatch(closeMenu())}
    />;
}

export function MenuManager() {
    const dispatch = useAppDispatch();
    const currentMenu = useAppSelector(selectCurrentMenu);

    useEffect(() => {
        const handleClickEvent = () => dispatch(closeMenu());

        if (currentMenu) {
            window.addEventListener("mousedown", handleClickEvent);
            return () => window.removeEventListener("mousedown", handleClickEvent);
        }
    }, [currentMenu]);

    return <div className={css.menuManager}>
        {currentMenu && currentMenu.metaType === "context" && <OutsideMenuArea/>}
        {currentMenu && <Menu position={currentMenu.position}>
            <MenuImpl sourceId={currentMenu.sourceId}/>
        </Menu>}
    </div>;
}

function MenuImpl(props: { sourceId: MenuType }) {
    switch (props.sourceId) {
        case "project": return <ProjectMenu/>;
        case "view": return <ViewMenu/>;
        case "sheet": return <SheetMenu/>;
        default: const _: never = props.sourceId;
    }
}
