import { Sheet } from "../../../editor/components/sheet/Sheet";
import { WindowTypeAndPropsDefinition } from "../../windowManagerSlice";

export interface SheetWindowProps {}

export type SheetWindowTypeAndProps = WindowTypeAndPropsDefinition<
    "sheet",
    SheetWindowProps
>;

export function SheetWindow(props: SheetWindowProps & { id: string }) {
    return <Sheet sheetIndex={1}/>;
}
