import { useAppDispatch } from "../../../../../app/hooks";
import { resetEditor } from "../../../../editor/editorSlice";
import { resetLogics } from "../../../../logics/logicsSlice";
import { resetMenuManager } from "../../../../menuManager/menuManagerSlice";
import { WindowTypeAndPropsDefinition, closeWindow, resetWindowManager } from "../../../windowManagerSlice";
import css from "./ConfirmNewWindow.module.css";

export interface ConfirmNewWindowProps { }

export type ConfirmNewWindowTypeAndProps = WindowTypeAndPropsDefinition<
    "confirmNew",
    ConfirmNewWindowProps
>;

export function ConfirmNewWindow(props: ConfirmNewWindowProps & { id: string }) {
    const dispatch = useAppDispatch();

    return <div className={css.content}>
        <div className={css.textChunk}>
            <div>Confirming this action will create a new project.</div>
            <div style={{ fontWeight: "bold", color: "red" }}>All unsaved progress will be lost.</div>
        </div>
        <div className={css.confirmButtonChunk}>
            <button
                className={css.confirmButton}
                onClick={() => dispatch(closeWindow(props.id))}
            >Cancel</button>
            <button
                className={css.confirmButton}
                onClick={() => {
                    dispatch(resetWindowManager());
                    dispatch(resetMenuManager());
                    dispatch(resetEditor());
                    dispatch(resetLogics());
                }}
            >Confirm</button>
        </div>
    </div>;
}
