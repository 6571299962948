import { Point2D } from "./Point2D";

export function snapToGrid(value: number, gridSize: number) {
    return Math.round(value / gridSize) * gridSize;
}

export function snapToGridCeil(value: number, gridSize: number) {
    return Math.ceil(value / gridSize) * gridSize;
}

export function snapToGridFloor(value: number, gridSize: number) {
    return Math.floor(value / gridSize) * gridSize;
}

export function snapPoint2DToGrid(point: Point2D, gridSize: number): Point2D {
    return {
        x: snapToGrid(point.x, gridSize),
        y: snapToGrid(point.y, gridSize),
    };
}
