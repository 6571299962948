import classNames from "classnames";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { Point2D } from "../../../../../common/Point2D";
import { nonNullableFilter } from "../../../../../common/nonNullableFilter";
import { snapToGrid, snapToGridCeil } from "../../../../../common/snapToGrid";
import { useDrag } from "../../../../../common/useDrag";
import { useMountEffect } from "../../../../../common/useMountEffect";
import { zip } from "../../../../../common/zip";
import { LogicsMemory as MemoryConfig } from "../../../../logics/logics/LogicsMemory";
import { LogicsIo, LogicsOperator as OperatorConfig } from "../../../../logics/logics/LogicsOperator";
import { selectLogics } from "../../../../logics/logicsSlice";
import { selectIoOrCreateConnection, selectSheet, setSelection, toggleFromSelection, updateBlockBoundingBox, updateBlockPreviewBoundingBox, updateMemoryRefSource } from "../../../editorSlice";
import { Selection, SelectionIo, isInSelection } from "../../../interfaces/Sheet";
import { ConnectionIo } from "../../../interfaces/components/Connection";
import MemoryRef from "../../../interfaces/components/MemoryRef";
import Metadata from "../../../interfaces/components/Metadata";
import Operator, { Io } from "../../../interfaces/components/Operator";
import css from "./Block.module.css";
import { devModeLog } from "../../../../../common/devModeLog";

function InputRow(props: {
    label: string,
    io: ConnectionIo,
    disableInput?: boolean,
}) {
    const dispatch = useAppDispatch();
    const currentSheet = useAppSelector(state => state.editor.sheets[state.editor.currentTabIndex]);
    const thisSelection: SelectionIo = {
        type: "io",
        io: props.io,
    };
    const isSelected = isInSelection(currentSheet.selection, thisSelection);

    return <div
        className={classNames(css.row, css.inputRow, { [css.selected]: isSelected })}
    >
        {!props.disableInput && <div
            className={classNames(css.io, css.inputIo)}
            onMouseDown={event => {
                event.stopPropagation();
                dispatch(selectIoOrCreateConnection(thisSelection));
            }}
        >
            <div className={css.ioHoverable}/>
        </div>}
        {props.label}
    </div>;
}

function OutputRow(props: {
    label: string,
    io: ConnectionIo,
}) {
    const dispatch = useAppDispatch();
    const currentSheet = useAppSelector(state => state.editor.sheets[state.editor.currentTabIndex]);
    const thisSelection: SelectionIo = {
        type: "io",
        io: props.io,
    };
    const isSelected = isInSelection(currentSheet.selection, thisSelection);

    return <div className={classNames(css.row, css.outputRow, { [css.selected]: isSelected })}>
        {props.label}
        <div
            className={classNames(css.io, css.outputIo)}
            onMouseDown={event => {
                event.stopPropagation();
                dispatch(selectIoOrCreateConnection({
                    type: "io",
                    io: props.io,
                }));
            }}
        >
            <div className={css.ioHoverable}/>
        </div>
    </div>;
}

function TaggedVariable(props: {
    label: string,
}) {
    return <div className={classNames(css.row, css.taggedVariableRow)}>
        <div className={css.taggedVariableLabel}>
            {props.label}
        </div>
    </div>;
}

function MemorySource(props: {
    label: string,
}) {
    return <div className={classNames(css.row, css.taggedVariableRow)}>
        {props.label}
    </div>;
}

interface SourceOperator {
    type: "operator",
    sheetIndex: number,
    operatorId: string,
}

interface SourceMemoryRef {
    type: "memoryRef",
    sheetIndex: number,
    memoryRefId: string,
}

type Source = SourceOperator | SourceMemoryRef;

export interface BlockProps {
    sheetIndex: number,
    source: Source,
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    onDoubleClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
}

export interface BlockPreviewProps {
    sheetIndex: number,
    blockedFromPlacing: boolean,
}

interface IoGroup {
    label: string;
    ioCount: number;
}

function ioGroup(logicIo: LogicsIo, io: Io, metadata: Metadata): IoGroup {
    if (io.enabled === false) {
        return {
            ioCount: 0,
            label: logicIo.label,
        };
    }

    switch (logicIo.connection.mode) {
        case "single":
            return {
                ioCount: 1,
                label: logicIo.label,
            };

        case "multiple":
            return {
                ioCount: 1,
                label: logicIo.label,
            };

        case "spread":
            const counter = logicIo.connection.counter;
            const ioCount = metadata.variables[counter].content.value as number;

            return {
                ioCount,
                label: logicIo.label,
            };
    }
}

export function ioGroups(logicIos: LogicsIo[], ios: Io[], metadata: Metadata) {
    return zip(logicIos, ios).map(([logicIo, io]) => ioGroup(logicIo, io, metadata));
}

interface BlockDataOperator {
    type: "operator",
    data: Operator,
    config: OperatorConfig,
    label: string,
}

interface BlockDataMemoryRef {
    type: "memoryRef",
    data: MemoryRef,
    config: MemoryConfig,
    label: string,
}

type BlockData = BlockDataOperator | BlockDataMemoryRef;

function useBlock(source: Source): BlockData {
    const logics = useAppSelector(selectLogics);
    const editor = useAppSelector(state => state.editor);
    const sheet = useAppSelector(selectSheet(source.sheetIndex));

    switch (source.type) {
        case "operator": {
            const operator = sheet.operators.find(
                operator => operator.id === source.operatorId
            )!;
            const config = logics.operator[operator.name];

            return {
                type: "operator",
                data: operator,
                config,
                label: operator.label,
            };
        }

        case "memoryRef": {
            const memoryRef = sheet.memoryRefs.find(
                memory => memory.id === source.memoryRefId
            )!;
            const config = logics.memory[memoryRef.name];
            const memory = editor.memories[memoryRef.name].find(
                memory => memory.id === memoryRef.sourceMemoryId
            );

            return {
                type: "memoryRef",
                data: memoryRef,
                config,
                label: memory?.label ?? memoryRef.name,
            };
        }
    }
}

function useBlockPreview(): {
    type: "operator",
    data: Operator,
    config: OperatorConfig,
} | {
    type: "memoryRef",
    data: MemoryRef,
    config: MemoryConfig,
} {
    const blockPreview = useAppSelector(state => state.editor.blockPreview);

    if (blockPreview === null) {
        throw new Error("blockPreview should NEVER be null if a BlockPreview is rendered");
    }

    const logics = useAppSelector(selectLogics);

    switch (blockPreview.type) {
        case "operator": {
            return {
                type: "operator",
                data: blockPreview.block,
                config: logics.operator[blockPreview.block.name],
            };
        }

        case "memoryRef": {
            return {
                type: "memoryRef",
                data: blockPreview.block,
                config: logics.memory[blockPreview.block.name],
            };
        }
    }
}

function blockToSelection(blockData: BlockData): Selection {
    switch (blockData.type) {
        case "operator": {
            return {
                type: "operator",
                operatorId: blockData.data.id,
            };
        }

        case "memoryRef": {
            return {
                type: "memoryRef",
                memoryRefId: blockData.data.id,
            };
        }
    }
}

export function Block(props: BlockProps) {
    const dispatch = useAppDispatch();
    const sheet = useAppSelector(selectSheet(props.sheetIndex));
    const blockData = useBlock(props.source);
    const sheetSelection = useAppSelector(state => state.editor.sheets[props.sheetIndex].selection);

    const selection = blockToSelection(blockData);
    const isSelected = isInSelection(sheetSelection, selection);

    const frameRef = useRef<HTMLDivElement>(null);

    const fixWidth = () => {
        if (frameRef.current) {
            const element = frameRef.current;
            element.style.width = "0px";
            const snapped = snapToGridCeil(element.offsetWidth, sheet.gridPixelSize);
            element.style.width = `${snapped + 1}px`;

            if (blockData.data.boundingBox.width !== snapped + 1) {
                const newBoundingBox = {
                    top: blockData.data.boundingBox.top,
                    left: blockData.data.boundingBox.left,
                    width: snapped + 1,
                    height: frameRef.current.offsetHeight,
                };

                dispatch(updateBlockBoundingBox({
                    sheetIndex: props.sheetIndex,
                    source: props.source,
                    boundingBox: newBoundingBox,
                }));
            }
        }
    }

    useEffect(() => {
        fixWidth();
    }, [blockData]);

    const [dragStart, setDragStart] = useState<Point2D>({ x: 0, y: 0 });
    const [dragDelta, setDragDelta] = useState<Point2D>({ x: 0, y: 0 });

    const onMovementDragStart = useDrag({
        onMouseDown({event}) {
            if (frameRef.current) {
                frameRef.current.style.cursor = "grabbing";
            }

            if (event.ctrlKey || event.metaKey) {
                devModeLog("toggle");
                dispatch(toggleFromSelection(selection));
            } else {
                dispatch(setSelection(selection));
            }

            setDragStart({
                x: blockData.data.boundingBox.left,
                y: blockData.data.boundingBox.top,
            });
            setDragDelta({ x: 0, y: 0 });
        },
        onMouseMove({delta}) {
            const newDelta = {
                x: dragDelta.x + delta.x / sheet.scale,
                y: dragDelta.y + delta.y / sheet.scale,
            };

            setDragDelta(newDelta);

            const newBoundingBox = {
                ...blockData.data.boundingBox,
                left: snapToGrid(dragStart.x + newDelta.x, sheet.gridPixelSize),
                top: snapToGrid(dragStart.y + newDelta.y, sheet.gridPixelSize),
            };

            dispatch(updateBlockBoundingBox({
                sheetIndex: props.sheetIndex,
                source: props.source,
                boundingBox: newBoundingBox,
            }));
        },
        onMouseUp() {
            if (frameRef.current !== null) {
                frameRef.current.style.cursor = "grab";
            }
        },
        preventOnMouseDownIf(event) {
            return event.button !== 0;
        },
        preventOnMouseUpIf(event) {
            return event.button !== 0;
        },
    }, [sheet.scale, blockData]);

    return <div
        className={css.block}
        style={{
            top: blockData.data.boundingBox.top,
            left: blockData.data.boundingBox.left,
            "--grid-size": `${sheet.gridPixelSize}px`,
        } as React.CSSProperties}
    >
        <div
            ref={frameRef}
            onClick={event => {
                if (event.button === 0) {

                }

                props.onClick?.(event);
            }}
            onDoubleClick={props.onDoubleClick}
            onMouseDown={onMovementDragStart}
            className={classNames(
                css.taggedFrame,
                { [css.selected]: isSelected },
            )}
        >
            <div className={css.label}>{blockData.label}</div>
            <div className={css.body} />
            <div className={css.outline} />
            {blockData.type === "memoryRef" && <MemoryRefBody config={blockData.config} memoryRef={blockData.data}/>}
            {blockData.type === "operator" && <OperatorBody config={blockData.config} operator={blockData.data}/>}
        </div>
    </div>;
}

export const BlockPreview = forwardRef((props: BlockPreviewProps, ref) => {
    const dispatch = useAppDispatch();
    const sheet = useAppSelector(selectSheet(props.sheetIndex));
    const block = useBlockPreview();

    const frameRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
        getBoundingClientRect() {
            return frameRef.current!.getBoundingClientRect();
        },
    }));

    const fixWidth = () => {
        if (frameRef.current) {
            const element = frameRef.current;
            element.style.width = "0px";
            const snapped = snapToGridCeil(element.offsetWidth, sheet.gridPixelSize);
            element.style.width = `${snapped + 1}px`;

            if (block.data.boundingBox.width !== snapped + 1) {
                const newBoundingBox = {
                    top: block.data.boundingBox.top,
                    left: block.data.boundingBox.left,
                    width: snapped + 1,
                    height: frameRef.current.offsetHeight,
                };

                dispatch(updateBlockPreviewBoundingBox(newBoundingBox));
            }
        }
    }

    fixWidth();

    useMountEffect(() => {
        fixWidth();
    });

    return <div
        className={classNames(css.block, css.preview, { [css.blocked]: props.blockedFromPlacing })}
        style={{
            top: block.data.boundingBox.top,
            left: block.data.boundingBox.left,
            "--grid-size": `${sheet.gridPixelSize}px`,
        } as React.CSSProperties}
    >
        <div
            ref={frameRef}
            className={css.taggedFrame}
        >
            <div className={css.body} />
            <div className={classNames(css.outline, { [css.blocked]: props.blockedFromPlacing })} />
            {block.type === "memoryRef" && <MemoryRefBody config={block.config} memoryRef={block.data}/>}
            {block.type === "operator" && <OperatorBody config={block.config} operator={block.data}/>}
        </div>
    </div>;
});


function MemoryRefBody(props: {
    config: MemoryConfig,
    memoryRef: MemoryRef,
}) {
    const { config, memoryRef } = props;

    const dispatch = useAppDispatch();

    const memory = useAppSelector(
        state => state.editor.memories[memoryRef.name].find(
            memory => memory.id === memoryRef.sourceMemoryId
        )
    );

    devModeLog(memory, memoryRef);

    if (memory === undefined) {
        devModeLog("MemoryRefBody: memory is undefined");
        dispatch(updateMemoryRefSource({
            sheetId: memoryRef.sheetId,
            memoryRefId: memoryRef.id,
            sourceMemoryId: null,
        }));
    }

    return <>
        <MemoryRefInputColumn config={config} memoryRef={memoryRef}/>
        <MemoryRefTaggedVariableColumn config={config} memoryRef={memoryRef}/>
        <MemoryRefOutputColumn config={config} memoryRef={memoryRef}/>
    </>;
}

function MemoryRefInputColumn(props: {
    config: MemoryConfig,
    memoryRef: MemoryRef,
}) {
    const { config, memoryRef } = props;

    const isInput = ["INPUT"].includes(config.name);

    return <div className={classNames(css.column, css.inputColumn)}>
        <InputRow label={""} disableInput={isInput} io={{
            type: "memoryRef",
            memoryRefId: memoryRef.id,
            ioType: "input",
        }}/>
    </div>;
}

function MemoryRefTaggedVariableColumn(props: {
    config: MemoryConfig,
    memoryRef: MemoryRef,
}) {
    const { config, memoryRef } = props;

    const memory = useAppSelector(
        state => state.editor.memories[memoryRef.name].find(
            memory => memory.id === memoryRef.sourceMemoryId
        )
    );

    if (!memory) {
        return <div className={classNames(css.column, css.taggedVariableColumn, css.unassignedIo)}>
            <MemorySource label="Unassigned" />
        </div>;
    }

    if (memory.source.type === "local") {
        return <div className={classNames(css.column, css.taggedVariableColumn)}>
            <MemorySource label="Local" />
        </div>;
    }

    const device = useAppSelector(
        state => state.editor.devices.filter(nonNullableFilter).find(
            device => device.id === memory.source.deviceId
        )
    );

    if (!device) {
        throw new Error(`Device ${memory.source.deviceId} not found`);
    }

    if (device.address === undefined) {
        throw new Error(`Device ${device.id} has no address`);
    }

    switch (memory.source.type) {
        case "io":
            return <div className={classNames(css.column, css.taggedVariableColumn)}>
                <div className={css.row}>
                    <TaggedVariable label={device.address} />
                    <div style={{ minWidth: 10 }}/>
                    <TaggedVariable label={memory.source.io} />
                </div>
            </div>;
        case "address":
            return <div className={classNames(css.column, css.taggedVariableColumn)}>
                <div className={css.row}>
                    <TaggedVariable label={device.address} />
                    <div style={{ minWidth: 10 }}/>
                    <TaggedVariable label={memory.source.address.toString()} />
                </div>
            </div>;
    }
}

function MemoryRefOutputColumn(props: {
    config: MemoryConfig,
    memoryRef: MemoryRef,
}) {
    const { config, memoryRef } = props;

    return <div className={classNames(css.column, css.outputColumn)}>
        <OutputRow label={""} io={{
            type: "memoryRef",
            memoryRefId: memoryRef.id,
            ioType: "output",
        }}/>
    </div>;
}

function OperatorBody(props: {
    config: OperatorConfig,
    operator: Operator,
}) {
    const { config, operator } = props;

    const noEnabledIos = operator.inputs.concat(operator.outputs).every(io => io.enabled === false);

    if (noEnabledIos) {
        return <div className={classNames(css.row)} style={{ width: 60 }}/>;
    }

    return <>
        <OperatorInputColumn config={config} operator={operator}/>
        <OperatorTaggedVariableColumn config={config} operator={operator}/>
        <OperatorOutputColumn config={config} operator={operator}/>
    </>;
}

function OperatorInputColumn(props: {
    config: OperatorConfig,
    operator: Operator,
}) {
    const { config, operator } = props;

    return <div className={classNames(css.column, css.inputColumn)}>
        {ioGroups(config.inputs, operator.inputs, operator.metadata).map((group, groupIndex) => {
            if (group.ioCount === 1) {
                return <InputRow key={group.label} label={group.label} io={{
                    type: "operator",
                    operatorId: operator.id,
                    ioType: "input",
                    ioGroup: groupIndex,
                    ioIndex: 0,
                }}/>;
            }

            const labels = Array.from({ length: group.ioCount }, (_, index) => `${group.label}_${index + 1}`);
            return labels.map((label, ioIndex) => <InputRow key={label} label={label} io={{
                type: "operator",
                operatorId: operator.id,
                ioType: "input",
                ioGroup: groupIndex,
                ioIndex,
            }}/>);
        })}
    </div>;
}

function OperatorTaggedVariableColumn(props: {
    config: OperatorConfig,
    operator: Operator,
}) {
    const { config, operator } = props;

    return <div className={classNames(css.column, css.taggedVariableColumn)}>
        {/* <TaggedVariable label="Test tag" /> */}
    </div>;
}

function OperatorOutputColumn(props: {
    config: OperatorConfig,
    operator: Operator,
}) {
    const { config, operator } = props;

    return <div className={classNames(css.column, css.outputColumn)}>
        {ioGroups(config.outputs, operator.outputs, operator.metadata).map((group, groupIndex) => {
            if (group.ioCount === 1) {
                return <OutputRow key={group.label} label={group.label} io={{
                    type: "operator",
                    operatorId: operator.id,
                    ioType: "output",
                    ioGroup: groupIndex,
                    ioIndex: 0,
                }}/>;
            }

            const labels = Array.from({ length: group.ioCount }, (_, index) => `${group.label}_${index + 1}`);
            return labels.map((label, ioIndex) => <OutputRow key={label} label={label} io={{
                type: "operator",
                operatorId: operator.id,
                ioType: "output",
                ioGroup: groupIndex,
                ioIndex,
            }}/>);
        })}
    </div>;
}
