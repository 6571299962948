import { EffectCallback, useEffect, useRef } from "react";

export function useMountEffect(effect: EffectCallback) {
    const ref = useRef({ effect, ran: false });
    useEffect(() => {
        if (ref.current.ran) {
            return;
        }

        ref.current.effect();
        ref.current.ran = true;
    }, []);
}
