import { generateUniqueId } from "../../../../common/generateUniqueId";
import { Logics } from "../../../logics/logics/Logics";
import Variable, { variableFromLogics } from "./Variable";

export interface Device {
    id: string;
    label: string;
    name: string;
    address?: string;

    inputs: Input[];
    outputs: Output[];

    configs: Record<string, Config[]>;
}

export interface Input {
    id: number;
    name: string;
    pull: string;
    mode: string;
}

export interface Output {
    id: number;
    name: string;
    signal: string;
    mode: string;
}

export interface Config {
    label: string;
    details?: string;
    variables: Record<string, Variable>;
}

export default Device;

export function createDevice(
    name: string,
    logics: Logics,
): Device {
    const logicsDevice = logics.device[name];

    const device: Device = {
        id: generateUniqueId("device"),
        name,
        label: name,
        inputs: Object.entries(logicsDevice.input).map(([name, input]) => {
            return {
                id: input.id,
                name,
                pull: input.pull[0],
                mode: input.mode[0],
            };
        }),
        outputs: Object.entries(logicsDevice.output).map(([name, output]) => {
            return {
                id: output.id,
                name,
                signal: output.signal[0],
                mode: output.mode[0],
            };
        }),
        configs: Object.fromEntries(
            Object.entries(logicsDevice.config).map(([name, config]) => {
                const configConfig = logics.config[name];

                const configItems: Config[] = config.map(item => {
                    const details = item.details;

                    const variables = Object.fromEntries(
                        configConfig.variables.map(logicsVariable => {
                            const variable = variableFromLogics(logicsVariable);

                            const value = item.properties?.[logicsVariable.name];
                            if (value !== undefined) {
                                variable.content.value = value;
                            }

                            return [variable.label, variable];
                        })
                    );

                    return {
                        label: configConfig.label,
                        details,
                        variables,
                    };
                });

                return [name, configItems];
            })
        ),
    };

    return device;
}
