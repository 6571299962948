import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, GenericSelector, RootState } from "../../app/store";
import { BoundingBox } from "../../common/BoundingBox";
import { generateUniqueId } from "../../common/generateUniqueId";
import { Point2D } from "../../common/Point2D";
import { fetchLogicsConfiguration, selectLogics } from "../logics/logicsSlice";
import { BlockSource } from "../windowManager/window/windowImpl/BlockConfigWindow/BlockConfigWindow";
import { ConnectionIo, ConnectionIoMemoryRef, ConnectionIoOperator } from "./interfaces/components/Connection";

import EditorState, { BlockPreview, SideBarSelection } from './EditorState'
import Device from "./interfaces/components/Device";
import Memory from "./interfaces/components/Memory";
import MemoryRef from "./interfaces/components/MemoryRef";
import Operator from "./interfaces/components/Operator";
import { VariableContent, variableFromLogics } from "./interfaces/components/Variable";

import { createSheetState, isInSelection, isSameSelection, Selection, SelectionIo, Sheet } from "./interfaces/Sheet";

export function findDeviceAddress(deviceId: string, devices: (Device | null)[]): string | undefined {
    const device = devices.find(device => device?.id === deviceId);

    if (device === undefined || device === null) {
        return undefined;
    }

    return device.address ?? undefined;
}

export * from './EditorState';

export type BlockPreviewTrigger =
    | "click"
    | "drag"
    | null
;

interface EditorStateExtraProps {
    dummy: undefined;

    blockPreviewTrigger: BlockPreviewTrigger;
}

type FullEditorState = EditorState & EditorStateExtraProps;

const initialExtraState: EditorStateExtraProps = {
    dummy: undefined,
    blockPreviewTrigger: null,
};

const initialState: FullEditorState = {
    projectName: "New Project",
    currentTabIndex: 0,
    sheets: [
        createSheetState(),
    ],
    memories: {},
    devices: [],
    sideBarSelection: null,
    blockPreview: null,

    ...initialExtraState
};

export const editorSlice = createSlice({
    name: "editor",
    initialState,
    reducers: {
        resetEditor: () => {
            return initialState;
        },
        loadEditorState: (_state, action: PayloadAction<EditorState>) => {
            return {
                ...action.payload,
                ...initialExtraState
            };
        },
        createDeviceSlot: (state) => {
            state.devices.push(null);
        },
        updateDeviceSlot: (state, action: PayloadAction<{
            index: number,
            device: Device,
        }>) => {
            state.devices[action.payload.index] = action.payload.device;
        },
        deleteDeviceSlot(state, action: PayloadAction<{
            index: number,
        }>) {
            state.devices.splice(action.payload.index, 1);
        },
        setSideBarSelection: (state, action: PayloadAction<SideBarSelection>) => {
            state.sideBarSelection = action.payload;
        },
        updateProjectName: (state, action: PayloadAction<string>) => {
            if (action.payload) {
                state.projectName = action.payload;
            }
        },
        selectTab: (state, action: PayloadAction<number>) => {
            if (action.payload < state.sheets.length) {
                state.currentTabIndex = action.payload;
            }
        },
        moveTab: (state, action: PayloadAction<{
            fromId: string,
            toId: string,
        }>) => {
            const currentId = state.sheets[state.currentTabIndex].id;

            const fromIndex = state.sheets.findIndex(sheet => sheet.id === action.payload.fromId);
            const toIndex = state.sheets.findIndex(sheet => sheet.id === action.payload.toId);
            const removed = state.sheets.splice(fromIndex, 1);

            state.sheets.splice(toIndex, 0, ...removed);
            console.log(state.currentTabIndex, fromIndex, toIndex);

            state.currentTabIndex = state.sheets.findIndex(sheet => sheet.id === currentId);
        },
        createSheet: (state) => {
            state.sheets.push(createSheetState());
        },
        deleteSheet: (state, action: PayloadAction<{ sheetIndex: number }>) => {
            state.sheets.splice(action.payload.sheetIndex, 1);

            if (state.sheets.length === 0) {
                state.sheets.push(createSheetState());
                state.currentTabIndex = 0;
            }

            if (action.payload.sheetIndex < state.currentTabIndex) {
                state.currentTabIndex -= 1;
            }

            if (state.currentTabIndex >= state.sheets.length) {
                state.currentTabIndex = state.sheets.length - 1;
            }
        },
        moveSheetByOffset: (state, action: PayloadAction<{
            index: number,
            delta: Point2D,
        }>) => {
            const sheet = state.sheets[action.payload.index];
            sheet.position.x += action.payload.delta.x;
            sheet.position.y += action.payload.delta.y;
        },
        moveSheetTo: (state, action: PayloadAction<{
            index: number,
            position: Point2D,
        }>) => {
            const sheet = state.sheets[action.payload.index];
            sheet.position.x = action.payload.position.x;
            sheet.position.y = action.payload.position.y;
        },
        setSheetGridSize: (state, action: PayloadAction<{
            index: number,
            size: Point2D,
        }>) => {
            const sheet = state.sheets[action.payload.index];
            sheet.gridSize.x = action.payload.size.x;
            sheet.gridSize.y = action.payload.size.y;
        },
        updateSheetTitle: (state, action: PayloadAction<{
            index: number,
            title: string,
        }>) => {
            if (action.payload) {
                state.sheets[action.payload.index].title = action.payload.title;
            }
        },
        updateSheetScale: (state, action: PayloadAction<{
            index: number,
            scale: number,
        }>) => {
            if (action.payload) {
                state.sheets[action.payload.index].scale = action.payload.scale;
            }
        },
        setBlockPreview: (state, action: PayloadAction<{
            preview: BlockPreview,
            trigger: BlockPreviewTrigger,
        }>) => {
            state.blockPreview = action.payload.preview;
            state.blockPreviewTrigger = action.payload.trigger;
        },
        updateBlockPreviewBoundingBox: (state, action: PayloadAction<BoundingBox>) => {
            if (state.blockPreview) {
                state.blockPreview.block.boundingBox = action.payload;
            }
        },
        insertOperator(state, action: PayloadAction<{
            operator: Operator,
        }>) {
            const sheet = state.sheets.find(sheet => sheet.id === action.payload.operator.sheetId);

            if (sheet) {
                sheet.operators.push(action.payload.operator);
            }
        },
        insertMemoryRef(state, action: PayloadAction<{
            memoryRef: MemoryRef,
        }>) {
            const sheet = state.sheets.find(sheet => sheet.id === action.payload.memoryRef.sheetId);

            if (sheet) {
                sheet.memoryRefs.push(action.payload.memoryRef);
            }
        },
        insertMemory(state, action: PayloadAction<{
            memory: Memory,
        }>) {
            if (state.memories[action.payload.memory.name] === undefined) {
                state.memories[action.payload.memory.name] = [];
            }

            state.memories[action.payload.memory.name].push(action.payload.memory);
        },
        updateMemory(state, action: PayloadAction<{
            memory: Memory,
            index: number,
        }>) {
            const category = state.memories[action.payload.memory.name];
            category[action.payload.index] = action.payload.memory;
        },
        deleteOperator(state, action: PayloadAction<{
            sheetIndex: number,
            operatorId: string,
        }>) {
            const sheet = state.sheets[action.payload.sheetIndex];
            sheet.operators = sheet.operators.filter(operator => operator.id !== action.payload.operatorId);
        },
        deleteMemoryRef(state, action: PayloadAction<{
            sheetIndex: number,
            memoryRefId: string,
        }>) {
            const sheet = state.sheets[action.payload.sheetIndex];
            sheet.memoryRefs = sheet.memoryRefs.filter(memoryRef => memoryRef.id !== action.payload.memoryRefId);
        },
        deleteMemory(state, action: PayloadAction<{
            memoryName: string,
            index: number,
        }>) {
            const category = state.memories[action.payload.memoryName];
            category.splice(action.payload.index, 1);
        },
        updateBlockBoundingBox(state, action: PayloadAction<{
            sheetIndex: number,
            source: BlockSource,
            boundingBox: BoundingBox,
        }>) {
            const { sheetIndex, source, boundingBox } = action.payload;

            switch (source.type) {
                case "operator":
                    const operator = state.sheets[sheetIndex].operators.find(
                        operator => operator.id === source.operatorId
                    );

                    if (operator) {
                        operator.boundingBox = boundingBox;
                    }

                    break;

                case "memoryRef":
                    const memoryRef = state.sheets[sheetIndex].memoryRefs.find(
                        memoryRef => memoryRef.id === source.memoryRefId
                    );

                    if (memoryRef) {
                        memoryRef.boundingBox = boundingBox;
                    }

                    break;
            }
        },
        setSelection(state, action: PayloadAction<Selection>) {
            const currentTabIndex = state.currentTabIndex;
            state.sheets[currentTabIndex].selection = action.payload;
        },
        addToSelection(state, action: PayloadAction<Selection>) {
            const currentTabIndex = state.currentTabIndex;
            const selection = state.sheets[currentTabIndex].selection;

            if (selection === null) {
                state.sheets[currentTabIndex].selection = action.payload;
                return;
            }

            if (selection.type === "multiple") {
                selection.selection.push(action.payload);
                return;
            }

            state.sheets[currentTabIndex].selection = {
                type: "multiple",
                selection: [selection, action.payload],
            };
        },
        removeFromSelection(state, action: PayloadAction<Selection>) {
            const currentTabIndex = state.currentTabIndex;
            const selection = state.sheets[currentTabIndex].selection;

            if (selection === null) {
                return;
            }

            if (selection.type === "multiple") {
                selection.selection = selection.selection
                    .filter(selection => !isSameSelection(selection, action.payload));
                return;
            }

            if (isSameSelection(selection, action.payload)) {
                state.sheets[currentTabIndex].selection = null;
            }
        },
        updateBlockVariableContent(state, action: PayloadAction<{
            source: BlockSource,
            variableName: string,
            content: VariableContent,
        }>) {
            const { source, variableName, content } = action.payload;

            console.log({source, variableName, content});

            switch (source.type) {
                case "operator":
                    const operator = state.sheets[source.sheetIndex].operators.find(
                        operator => operator.id === source.operatorId
                    );

                    if (operator) {
                        operator.metadata.variables[variableName].content = content;
                    }

                    break;

                case "memoryRef":
                    const memoryRef = state.sheets[source.sheetIndex].memoryRefs.find(
                        memoryRef => memoryRef.id === source.memoryRefId
                    );

                    if (memoryRef === undefined) {
                        break;
                    }

                    const category = state.memories[memoryRef.name];
                    const memory = category.find(memory => memory.id === memoryRef.sourceMemoryId);

                    if (memory) {
                        memory.metadata.variables[variableName].content = content;
                    }

                    break;
            }
        },
        updateBlockDataValue(state, action: PayloadAction<{
            source: BlockSource,
            dataName: string,
            value: string,
        }>) {
            const { source, dataName: variableName, value: content } = action.payload;

            console.log({source, variableName, content});

            switch (source.type) {
                case "operator":
                    const operator = state.sheets[source.sheetIndex].operators.find(
                        operator => operator.id === source.operatorId
                    );

                    if (operator) {
                        operator.metadata.data[variableName] = content;
                    }

                    break;

                case "memoryRef":
                    const memoryRef = state.sheets[source.sheetIndex].memoryRefs.find(
                        memoryRef => memoryRef.id === source.memoryRefId
                    );

                    if (memoryRef === undefined) {
                        break;
                    }

                    const category = state.memories[memoryRef.name];
                    const memory = category.find(memory => memory.id === memoryRef.sourceMemoryId);

                    if (memory) {
                        memory.metadata.data[variableName] = content;
                    }

                    break;
            }
        },
        updateOperatorEnabledIos(state, action: PayloadAction<{
            sheetIndex: number,
            operatorId: string,
            ioType: "input" | "output",
            ioName: string,
            enabled: boolean,
        }>) {
            const operator = state.sheets[action.payload.sheetIndex].operators.find(
                operator => operator.id === action.payload.operatorId
            );

            if (operator) {
                switch (action.payload.ioType) {
                    case "input":
                        operator.inputs.find(io => io.name === action.payload.ioName)!.enabled = action.payload.enabled;
                        break;

                    case "output":
                        operator.outputs.find(io => io.name === action.payload.ioName)!.enabled = action.payload.enabled;
                        break;
                }
            }
        },
        updateMemoryRefSource(state, action: PayloadAction<{
            sheetId: string,
            memoryRefId: string,
            sourceMemoryId: string | null,
        }>) {
            const { sheetId, memoryRefId, sourceMemoryId } = action.payload;

            const memoryRef = state.sheets.find(sheet => sheet.id === sheetId)?.memoryRefs.find(
                memoryRef => memoryRef.id === memoryRefId
            );

            if (memoryRef) {
                memoryRef.sourceMemoryId = sourceMemoryId;
            }
        },
        createConnection(state, action: PayloadAction<{
            id: string,
            origin: ConnectionIo,
            destination: ConnectionIo,
        }>) {
            let { id, origin, destination } = action.payload;

            if (origin.ioType === "input" && destination.ioType === "input") {
                return;
            }

            if (origin.ioType === "output" && destination.ioType === "output") {
                return;
            }

            if (origin.ioType === "input") {
                [origin, destination] = [destination, origin];
            }

            const currentSheet = state.sheets[state.currentTabIndex];

            // check if destination is already connected by checking the type and id
            switch (destination.type) {
                case "operator": {
                    const destinationIo = destination as ConnectionIoOperator;
                    const operator = currentSheet.operators.find(operator => operator.id === destinationIo.operatorId);

                    if (operator === undefined) {
                        return;
                    }

                    if (currentSheet.connections.some(
                        connection =>
                            connection.to.type === "operator" &&
                            connection.to.operatorId === destinationIo.operatorId &&
                            connection.to.ioGroup === destinationIo.ioGroup &&
                            connection.to.ioIndex === destinationIo.ioIndex
                    )) {
                        return;
                    }

                    break;
                }
                case "memoryRef": {
                    const destinationIo = destination as ConnectionIoMemoryRef;
                    const memoryRef = currentSheet.memoryRefs.find(memoryRef => memoryRef.id === destinationIo.memoryRefId);

                    if (memoryRef === undefined) {
                        return;
                    }

                    if (currentSheet.connections.some(
                        connection =>
                            connection.to.type === "memoryRef" &&
                            connection.to.memoryRefId === destinationIo.memoryRefId
                    )) {
                        return;
                    }

                    break;
                }
            }

            currentSheet.connections.push({
                id,
                from: origin,
                to: destination,
            });
        },
        deleteConnection(state, action: PayloadAction<{
            id: string,
        }>) {
            const currentSheet = state.sheets[state.currentTabIndex];
            const index = currentSheet.connections.findIndex(connection => connection.id === action.payload.id);
            if (index !== -1) {
                currentSheet.connections.splice(index, 1);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLogicsConfiguration.fulfilled, (state, action) => {
                Object.keys(action.payload.memory).forEach(name => {
                    if (state.memories[name] === undefined) {
                        state.memories[name] = [];
                    }
                });
            })
    },
});

export const createOperatorPreview = (
    operatorName: string,
    sheetIndex: number,
    position: Point2D,
    trigger: BlockPreviewTrigger,
): AppThunk => (
    dispatch,
    getState
) => {
    const logics = selectLogics(getState());
    const logicsOperator = logics.operator[operatorName];

    console.log({config: logicsOperator});

    const operator: Operator = {
        id: generateUniqueId("operator"),
        label: operatorName,
        name: operatorName,
        inputs: logicsOperator.inputs.map(input => ({
            name: input.label,
            enabled: input.enabledByDefault,
        })),
        outputs: logicsOperator.outputs.map(output => ({
            name: output.label,
            enabled: output.enabledByDefault,
        })),
        metadata: {
            variables: Object.fromEntries(
                logicsOperator.variables.map(variable => [
                    variable.name,
                    variableFromLogics(variable),
                ])
            ),
            data: logicsOperator.data
                ? Object.fromEntries(
                    logicsOperator.data.map(data => [
                        data.size,
                        "",
                    ]))
                : {},
        },
        sheetId: getState().editor.sheets[sheetIndex].id,
        boundingBox: {
            left: position.x,
            top: position.y,
            width: 0,
            height: 0,
        },
    };

    const preview: BlockPreview = {
        type: "operator",
        block: operator,
    };

    dispatch(editorSlice.actions.setBlockPreview({ preview, trigger }));

    console.log({preview});
};

export const createMemoryRefPreview = (
    memoryName: string,
    sheetIndex: number,
    position: Point2D,
    trigger: BlockPreviewTrigger,
): AppThunk => (
    dispatch,
    getState
) => {
    const logics = selectLogics(getState());
    const config = logics.memory[memoryName];

    console.log({config});

    const memoryRef: MemoryRef = {
        id: generateUniqueId("memoryRef"),
        name: memoryName,
        sheetId: getState().editor.sheets[sheetIndex].id,
        boundingBox: {
            left: position.x,
            top: position.y,
            width: 0,
            height: 0,
        },
        sourceMemoryId: null,
    };

    const preview: BlockPreview = {
        type: "memoryRef",
        block: memoryRef,
    };

    dispatch(editorSlice.actions.setBlockPreview({ preview, trigger }));

    console.log({preview});
};

export const insertPreview = (): AppThunk =>
    (dispatch, getState) => {
        const blockPreview = getState().editor.blockPreview;

        if (!blockPreview) {
            return;
        }

        switch (blockPreview.type) {
            case "operator":
                dispatch(editorSlice.actions.insertOperator({
                    operator: blockPreview.block,
                }));
                break;
            case "memoryRef":
                dispatch(editorSlice.actions.insertMemoryRef({
                    memoryRef: blockPreview.block,
                }));
                break;
        }
        dispatch(editorSlice.actions.setBlockPreview({ preview: null, trigger: null }));
    }

export const cancelPreview = (): AppThunk =>
    (dispatch, getState) => {
        dispatch(editorSlice.actions.setBlockPreview({ preview: null, trigger: null }));
    }

const deleteSelectionLeaf = (selection: Selection): AppThunk =>
    (dispatch, getState) => {
        if (selection === null) {
            return;
        }

        switch (selection.type) {
            case "operator":
                dispatch(editorSlice.actions.deleteOperator({
                    sheetIndex: getState().editor.currentTabIndex,
                    operatorId: selection.operatorId,
                }));
                break;
            case "memoryRef":
                dispatch(editorSlice.actions.deleteMemoryRef({
                    sheetIndex: getState().editor.currentTabIndex,
                    memoryRefId: selection.memoryRefId,
                }));
                break;
            case "connection":
                dispatch(editorSlice.actions.deleteConnection({
                    id: selection.connectionId,
                }));
                break;
        }
    }

export const deleteSelection = (): AppThunk =>
    (dispatch, getState) => {
        const selection = getState().editor.sheets[getState().editor.currentTabIndex].selection;

        if (selection === null) {
            return;
        }

        if (selection.type === "multiple") {
            selection.selection.forEach(selection => dispatch(deleteSelectionLeaf(selection)));
            dispatch(editorSlice.actions.setSelection(null));
            return;
        }

        dispatch(deleteSelectionLeaf(selection));
        dispatch(editorSlice.actions.setSelection(null));
    }

export const toggleFromSelection = (selection: Selection): AppThunk =>
    (dispatch, getState) => {
        const currentTabIndex = getState().editor.currentTabIndex;
        const currentSelection = getState().editor.sheets[currentTabIndex].selection;

        console.log(currentSelection);

        if (currentSelection === null) {
            dispatch(editorSlice.actions.setSelection(selection));
            return;
        }

        if (currentSelection.type === "multiple") {
            if (isInSelection(currentSelection, selection)) {
                dispatch(editorSlice.actions.removeFromSelection(selection));
            } else {
                dispatch(editorSlice.actions.addToSelection(selection));
            }
            return;
        }

        if (isSameSelection(currentSelection, selection)) {
            dispatch(editorSlice.actions.setSelection(null));
        } else {
            dispatch(editorSlice.actions.addToSelection(selection));
        }
    }

export const selectIoOrCreateConnection = (io: SelectionIo): AppThunk =>
    (dispatch, getState) => {
        const currentTabIndex = getState().editor.currentTabIndex;
        const currentSelection = getState().editor.sheets[currentTabIndex].selection;

        if (currentSelection === null) {
            dispatch(editorSlice.actions.setSelection(io));
            return;
        }

        if (isSameSelection(currentSelection, io)) {
            dispatch(editorSlice.actions.setSelection(null));
        } else if (currentSelection.type === "io") {
            if (!(currentSelection.io.type === "memoryRef" && io.io.type === "memoryRef")) {
                dispatch(editorSlice.actions.createConnection({
                    id: generateUniqueId("connection"),
                    origin: currentSelection.io,
                    destination: io.io,
                }));
            }
            dispatch(editorSlice.actions.setSelection(null));
        } else {
            dispatch(editorSlice.actions.setSelection(io));
        }
    }

export const {
    resetEditor,
    loadEditorState,
    setSideBarSelection,
    selectTab,
    moveTab,
    createSheet,
    deleteSheet,
    moveSheetByOffset,
    moveSheetTo,
    setSheetGridSize,
    updateSheetTitle,
    updateSheetScale,
    updateProjectName,
    setBlockPreview,
    updateBlockPreviewBoundingBox,
    updateBlockBoundingBox,
    updateBlockVariableContent,
    updateBlockDataValue,
    updateMemoryRefSource,
    insertMemory,
    updateMemory,
    createDeviceSlot,
    updateDeviceSlot,
    setSelection,
    addToSelection,
    removeFromSelection,
    deleteDeviceSlot,
    deleteOperator,
    deleteMemoryRef,
    deleteMemory,
    insertOperator,
    insertMemoryRef,
    createConnection,
    deleteConnection,
    updateOperatorEnabledIos,
} = editorSlice.actions;

export const selectProjectName = (state: RootState) => state.editor.projectName;

export const selectSheets = (state: RootState) => state.editor.sheets;
export const selectSheet = (index: number): GenericSelector<Sheet> =>
    createSelector(
        selectSheets,
        sheets => sheets[index]
    );

export const selectCurrentTabIndex = (state: RootState) => state.editor.currentTabIndex;

export default editorSlice.reducer;
