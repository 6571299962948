import { useAppSelector } from "../../app/hooks";
import { selectFetchBackendStatus } from "../../features/logics/logicsSlice";
import css from "./LoadingApp.module.css";

export function LoadingApp() {
    const reflistStatus = useAppSelector(selectFetchBackendStatus);

    return <div className={css.loadingArea}>
        {reflistStatus.code === "loading" && <>
            Loading Panda Configurator...
        </>}
        {reflistStatus.code === "failed" && <>
            {reflistStatus.error}
        </>}
    </div>;
}
