import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { Point2D } from '../../../../common/Point2D';
import css from './Sheet.module.css';

import { useEffect, useMemo, useRef, useState } from "react";
import { boundingBoxesOverlap } from '../../../../common/BoundingBox';
import { devModeLog } from '../../../../common/devModeLog';
import { snapPoint2DToGrid } from '../../../../common/snapToGrid';
import { useDrag } from '../../../../common/useDrag';
import { useZoom } from '../../../../common/useZoom';
import { createWindow } from '../../../windowManager/windowManagerSlice';
import {
    cancelPreview,
    createMemoryRefPreview,
    createOperatorPreview,
    deleteSelection,
    insertPreview,
    moveSheetByOffset,
    selectSheet,
    setSelection,
    setSheetGridSize,
    updateBlockPreviewBoundingBox,
    updateSheetScale,
} from '../../editorSlice';
import { isInSelection } from '../../interfaces/Sheet';
import { Block, BlockPreview } from './block/Block';
import { ConnectionLine } from './connection/Connection';
import { InfoBox } from './infoBox/InfoBox';
import { SheetBackground } from './sheetBackground/SheetBackground';

export interface SheetProps {
    sheetIndex: number;
};

export const Sheet: React.FC<SheetProps> = props => {
    const dispatch = useAppDispatch();

    const sideBarSelection = useAppSelector(state => state.editor.sideBarSelection);
    const sheet = useAppSelector(selectSheet(props.sheetIndex));
    const gridPixelSize = sheet.gridPixelSize;
    const blockPreview = useAppSelector(state => state.editor.blockPreview);
    const blockPreviewTrigger = useAppSelector(state => state.editor.blockPreviewTrigger);

    const { zoomIn, zoomOut } = useZoom({
        maxAbsoluteScaling: 3,
        maxAbsoluteLevel: 5,
    });

    const sheetScale = sheet.scale;

    // const ref = useRef<HTMLDivElement>(null);
    const [ref, setRef] = useState<HTMLDivElement | null>(null);

    const previewRef = useRef<HTMLDivElement>(null);

    const sheetPosition = sheet.position;

    const sheetSize = {
        x: (ref?.getBoundingClientRect().width ?? 0) / sheetScale,
        y: (ref?.getBoundingClientRect().height ?? 0) / sheetScale,
    };

    useEffect(() => {
        if (ref === null) {
            return;
        }

        const resizeObserver = new ResizeObserver(([observed]) => {
            // devModeLog("Sheet size changed", observed.contentRect.width, observed.contentRect.height);
            dispatch(setSheetGridSize({
                index: props.sheetIndex,
                size: {
                    x: observed.contentRect.width,
                    y: observed.contentRect.height,
                }
            }))
        });

        resizeObserver.observe(ref);

        return () => resizeObserver.disconnect();
    }, [props.sheetIndex, ref]);

    const sheetBoundingBox = {
        left: sheet.position.x - sheetSize.x / 2,
        top: sheet.position.y - sheetSize.y / 2,
        width: sheetSize.x,
        height: sheetSize.y,
    };

    const visibleOperators = useMemo(() => sheet.operators.filter(
        operator => {
            const isInView = boundingBoxesOverlap(operator.boundingBox, sheetBoundingBox);
            const isSelected = isInSelection({
                type: "operator",
                operatorId: operator.id,
            }, sheet.selection);
            return isInView || isSelected;
        }
    ), [sheet.operators, sheetBoundingBox]);

    const visibleMemoryRefs = useMemo(() => sheet.memoryRefs.filter(
        memoryRefs => boundingBoxesOverlap(memoryRefs.boundingBox, sheetBoundingBox)
    ), [sheet.memoryRefs, sheetBoundingBox]);

    const canPlacePreview = useMemo(() => {
        if (!blockPreview) {
            return false;
        }

        const previewBoundingBox = blockPreview.block.boundingBox;

        const overlapsVisibleOperators = visibleOperators.some(operator => {
            return boundingBoxesOverlap(operator.boundingBox, previewBoundingBox, { tolerance: 1 });
        });

        const overlapsVisibleMemoryRefs = visibleMemoryRefs.some(memoryRef => {
            return boundingBoxesOverlap(memoryRef.boundingBox, previewBoundingBox, { tolerance: 1 });
        });

        return !overlapsVisibleOperators && !overlapsVisibleMemoryRefs;
    }, [blockPreview?.type, visibleOperators]);

    const onSheetDragStart = useDrag({
        onMouseDown() {
            if (ref) {
                ref.style.cursor = "grabbing";
            }
        },
        onMouseMove({ delta }) {
            dispatch(moveSheetByOffset({
                index: props.sheetIndex,
                delta: {
                    x: -delta.x / sheetScale,
                    y: -delta.y / sheetScale,
                },
            }));
        },
        onMouseUp({ event, didMouseMove }) {
            if (ref !== null) {
                ref.style.cursor = "grab";
            }

            if (!didMouseMove && event.button === 0) {
                dispatch(setSelection(null));
            }
        },
        // preventOnMouseDownIf(event) {
        //     return event.button !== 1;
        // },
        // preventOnMouseUpIf(event) {
        //     return event.button !== 1 && event.button !== 2;
        // },
    });

    const onStartBlockCreation = useDrag({
        onMouseUp({ event, didMouseMove }) {
            if (ref === null) {
                return;
            }

            if (didMouseMove) {
                return;
            }

            if (sheet.selection !== null) {
                return;
            }

            if (blockPreview) {
                if (canPlacePreview && blockPreviewTrigger === "click") {
                    dispatch(insertPreview());
                }

                return;
            }

            if (sideBarSelection === null) {
                return;
            }

            const boundingRect = ref.getBoundingClientRect();

            const source = {
                x: event.clientX - boundingRect.left,
                y: event.clientY - boundingRect.top,
            };

            const target = {
                x: source.x / sheetScale + sheetPosition.x - sheetSize.x / 2 - gridPixelSize - right,
                y: source.y / sheetScale + sheetPosition.y - sheetSize.y / 2 - gridPixelSize - bottom,
            };

            switch (sideBarSelection.type) {
                case "operator":
                    dispatch(createOperatorPreview(
                        sideBarSelection.label,
                        props.sheetIndex,
                        target,
                        "click",
                    ));
                    break;
                case "memory":
                    dispatch(createMemoryRefPreview(
                        sideBarSelection.label,
                        props.sheetIndex,
                        target,
                        "click",
                    ));
                    break;
                default:
                    console.error("Unknown side bar selection type");
            }
        },
        preventOnMouseUpIf(event) {
            return event.button !== 0;
        }
    });

    const positionWithOffset: Point2D = {
        x: sheetPosition.x - sheetSize.x / 2,
        y: sheetPosition.y - sheetSize.y / 2,
    };

    const bottom = positionWithOffset.y % gridPixelSize - gridPixelSize;
    const right = positionWithOffset.x % gridPixelSize - gridPixelSize;

    const previousBlockPreview = useRef(blockPreview);

    useEffect(() => {
        if (previewRef.current !== null && blockPreview !== null && previousBlockPreview.current === null) {
            devModeLog("Initial preview snap");

            const previewBoundingRect = previewRef.current.getBoundingClientRect();
            devModeLog(previewBoundingRect);

            const newGridPosition = snapPoint2DToGrid({
                x: blockPreview.block.boundingBox.left - (previewBoundingRect.width * 0.5) / sheetScale,
                y: blockPreview.block.boundingBox.top - (previewBoundingRect.height * 0.5) / sheetScale,
            }, gridPixelSize);

            const newBoundingBox = {
                height: previewBoundingRect.height,
                width: previewBoundingRect.width,
                left: newGridPosition.x,
                top: newGridPosition.y,
            };

            devModeLog(newBoundingBox);

            dispatch(updateBlockPreviewBoundingBox(newBoundingBox));
        }

        previousBlockPreview.current = blockPreview;

        const onMouseMove = (event: MouseEvent) => {
            if (!blockPreview || !ref || !previewRef.current) {
                return;
            }

            const sheetBoundingRect = ref.getBoundingClientRect();
            const previewBoundingRect = previewRef.current.getBoundingClientRect();

            const eventSourcePosition = {
                x: event.clientX - sheetBoundingRect.left,
                y: event.clientY - sheetBoundingRect.top,
            };

            const sheetOffset = {
                x: sheetPosition.x - sheetSize.x / 2 - gridPixelSize - right,
                y: sheetPosition.y - sheetSize.y / 2 - gridPixelSize - bottom,
            };

            const newGridPosition = snapPoint2DToGrid({
                x: (eventSourcePosition.x - previewBoundingRect.width * 0.5) / sheetScale + sheetOffset.x,
                y: (eventSourcePosition.y - previewBoundingRect.height * 0.5) / sheetScale + sheetOffset.y,
            }, gridPixelSize);

            const newBoundingBox = {
                height: previewBoundingRect.height,
                width: previewBoundingRect.width,
                left: newGridPosition.x,
                top: newGridPosition.y,
            };

            if (blockPreview !== null) {
                dispatch(updateBlockPreviewBoundingBox(newBoundingBox));
            }
        };

        window.addEventListener("mousemove", onMouseMove);

        return () => window.removeEventListener("mousemove", onMouseMove);
    }, [blockPreview?.type, sheetPosition, sheetScale]);

    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                dispatch(cancelPreview());
            }
        };

        window.addEventListener("keydown", onKeyDown);

        return () => window.removeEventListener("keydown", onKeyDown);
    }, []);

    const sheetWindowRef = useRef<HTMLDivElement>(null);

    return <div
        ref={sheetWindowRef}
        className={css.sheetWindow}
        tabIndex={0}
        onKeyDown={event => {
            if (event.key === "Delete") {
                dispatch(deleteSelection());
            }
        }}
        onWheel={event => {
            event.preventDefault();
            event.stopPropagation();
            const sheetBoundingRect = ref!.getBoundingClientRect();
            devModeLog(">", sheetBoundingRect);

            const eventSourcePosition = {
                x: event.clientX - sheetBoundingRect.left,
                y: event.clientY - sheetBoundingRect.top,
            };

            const sheetOffset = {
                x: sheetPosition.x - sheetSize.x / 2 - gridPixelSize - right,
                y: sheetPosition.y - sheetSize.y / 2 - gridPixelSize - bottom,
            };

            const focus = {
                x: (eventSourcePosition.x) / sheetScale + sheetOffset.x,
                y: (eventSourcePosition.y) / sheetScale + sheetOffset.y,
            };

            const newScale = event.deltaY < 0 ? zoomIn() : zoomOut();
            const scalingRatio = newScale / sheetScale;

            const movement = {
                x: (sheetPosition.x - focus.x) * (1 - scalingRatio) / scalingRatio,
                y: (sheetPosition.y - focus.y) * (1 - scalingRatio) / scalingRatio,
            };

            dispatch(moveSheetByOffset({
                index: props.sheetIndex,
                delta: movement,
            }));

            dispatch(updateSheetScale({
                index: props.sheetIndex,
                scale: newScale,
            }));

            if (blockPreview) {
                const previewBoundingRect = previewRef.current!.getBoundingClientRect();

                const newGridPosition = snapPoint2DToGrid({
                    x: (eventSourcePosition.x - previewBoundingRect.width * 0.5) / sheetScale + sheetOffset.x,
                    y: (eventSourcePosition.y - previewBoundingRect.height * 0.5) / sheetScale + sheetOffset.y,
                }, gridPixelSize);

                const newBoundingBox = {
                    height: previewBoundingRect.height,
                    width: previewBoundingRect.width,
                    left: newGridPosition.x,
                    top: newGridPosition.y,
                };

                dispatch(updateBlockPreviewBoundingBox(newBoundingBox));
            }
        }}
    >
        <div
            className={css.sheetInternalWindow}
            style={{
                transform: `scale(${sheetScale})`,
                width: `${100 / sheetScale}%`,
                height: `${100 / sheetScale}%`,
                backgroundColor: "black",
            }}
        >
            <SheetBackground
                ref={setRef}
                bottom={bottom}
                right={right}
                gridPixelSize={gridPixelSize}
                onMovementDragStart={onSheetDragStart}
                onStartBlockCreation={onStartBlockCreation}
            />
            <div
                style={{
                    position: "absolute",
                    background: "#00F3",
                    left: `calc(50% - ${sheetPosition.x}px)`,
                    top: `calc(50% - ${sheetPosition.y}px - 1px)`,
                }}
            >
                {sheet.connections.map((connection, index) => {
                    return <ConnectionLine
                        key={index}
                        connection={connection}
                        sheetIndex={props.sheetIndex}
                        connectionIndex={index}
                    />
                })}
                {visibleOperators.map(operator => {
                    return <Block
                        key={operator.id}
                        onDoubleClick={() => {
                            const windowSize = {
                                x: 500,
                                y: 300,
                            };

                            const windowPosition = {
                                x: (window.innerWidth - windowSize.x) / 2,
                                y: (window.innerHeight - windowSize.y) / 2,
                            };

                            dispatch(createWindow({
                                typeAndProps: {
                                    type: "blockConfig",
                                    props: {
                                        source: {
                                            operatorId: operator.id,
                                            sheetIndex: props.sheetIndex,
                                            type: "operator",
                                        }
                                    }
                                },
                                title: operator.name,
                                position: windowPosition,
                                size: windowSize,
                            }));

                            sheetWindowRef.current?.blur();
                        }}
                        // onClick={event => {devModeLog(event)}}
                        source={{
                            type: "operator",
                            operatorId: operator.id,
                            sheetIndex: props.sheetIndex,
                        }}
                        sheetIndex={props.sheetIndex}
                    />;
                })}
                {visibleMemoryRefs.map(memoryRef => {
                    return <Block
                        key={`${memoryRef.id}-${memoryRef.sourceMemoryId}`}
                        onDoubleClick={() => {
                            const windowSize = {
                                x: 500,
                                y: 300,
                            };

                            const windowPosition = {
                                x: (window.innerWidth - windowSize.x) / 2,
                                y: (window.innerHeight - windowSize.y) / 2,
                            };

                            dispatch(createWindow({
                                typeAndProps: {
                                    type: "blockConfig",
                                    props: {
                                        source: {
                                            type: "memoryRef",
                                            memoryRefId: memoryRef.id,
                                            sheetIndex: props.sheetIndex,
                                        }
                                    }
                                },
                                title: memoryRef.name,
                                position: windowPosition,
                                size: windowSize,
                            }));

                            sheetWindowRef.current?.blur();
                        }}
                        // onClick={event => {devModeLog(event)}}
                        source={{
                            type: "memoryRef",
                            memoryRefId: memoryRef.id,
                            sheetIndex: props.sheetIndex,
                        }}
                        sheetIndex={props.sheetIndex}
                    />;
                })}
                {
                    blockPreview &&
                    <BlockPreview
                        ref={previewRef}
                        sheetIndex={props.sheetIndex}
                        blockedFromPlacing={!canPlacePreview}
                    />
                }
            </div>
        </div>
        <InfoBox sheetPosition={sheetPosition} zoom={sheetScale} />
    </div>;
};
