export interface BoundingBox {
    left: number;
    top: number;
    width: number;
    height: number;
};

export function boundingBoxesOverlap(a: BoundingBox, b: BoundingBox, options?: {
    tolerance?: number,
}) {
    const tolerance = options?.tolerance ?? 0;

    return (
        a.left + a.width - tolerance > b.left &&
        b.left + b.width - tolerance > a.left &&
        a.top + a.height - tolerance > b.top &&
        b.top + b.height - tolerance > a.top
    );
}
