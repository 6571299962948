import { EditorState } from "../features/editor/editorSlice";
import { ConnectionIo, ConnectionIoMemoryRef, ConnectionIoOperator } from "../features/editor/interfaces/components/Connection";
import Device, { Config } from "../features/editor/interfaces/components/Device";
import Memory, { MemorySourceAddress } from "../features/editor/interfaces/components/Memory";
import MemoryRef from "../features/editor/interfaces/components/MemoryRef";
import Metadata from "../features/editor/interfaces/components/Metadata";
import Operator, { Io } from "../features/editor/interfaces/components/Operator";
import { Logics } from "../features/logics/logics/Logics";
import { LogicsIo } from "../features/logics/logics/LogicsOperator";
import { LogicsTypes } from "../features/logics/logics/LogicsVariable";
import { nonNullableFilter } from "./nonNullableFilter";

export interface ProjectGraph {
    version: ProjectVersion,
    devices: ProjectDevice[],
    sheet: ProjectSheet,
}

export type ProjectVersion = [number, number, number];

export interface ProjectSheet {
    memories: ProjectMemory[],
    operators: ProjectOperator[],
}

export interface ProjectMemory {
    name: string,
    broadcast: boolean,
    address: string,
    id: number,
    realId: number,
    variables: ProjectMemoryVariable[],
    data: ProjectMemoryData[],
}

export interface ProjectMemoryVariable {
    variable_name: string,
    value: any,
}

export interface ProjectMemoryData {
    size: string,
    value: string,
}

export interface ProjectOperator {
    id: number,
    gridPosition: ProjectOperatorGridPosition,
    name: string,
    inputs: number[][],
    outputs: number[][],
    variables: ProjectOperatorVariable[],
}

export interface ProjectOperatorGridPosition {
    x: number,
    y: number,
}

export interface ProjectOperatorVariable {
    variable_name: string,
    value: any,
}

export interface ProjectDevice {
    address: string,
    configs: ProjectDeviceConfig[]
}

export interface ProjectDeviceConfig {
    type: string,
    variables: ProjectDeviceConfigVariable[],
}

export interface ProjectDeviceConfigVariable {
    value: number,
}

export function pgbBuild(editor: EditorState, logics: Logics): ProjectGraph {
    const builder = new PgbCreateEditorVariables(logics, editor);

    let status: PgbStatus = {
        status: "building",
        builder,
    };

    while (status.status === "building") {
        status = status.builder.step();
    }

    if (status.status === "done") {
        return status.projectGraph;
    }

    throw new Error(`Invalid status: ${status}`);
}

type IoMap = Map<string, number[][]>;

interface PgbBuilding {
    status: "building",
    builder: ProjectGraphBuilder,
}

interface PgbDone {
    status: "done",
    projectGraph: ProjectGraph,
}

type PgbStatus =
    | PgbBuilding
    | PgbDone;

class ProjectGraphBuilder {
    step(): PgbStatus {
        throw new Error("Base class method called");
    }
}

class PgbCreateEditorVariables extends ProjectGraphBuilder {
    constructor(
        private logics: Logics,
        private editor: EditorState,
    ) {
        super();
    }

    step(): PgbStatus {
        const { logics, editor } = this;

        const editorDevices = editor.devices.filter(nonNullableFilter);
        const editorMemories = Object.values(editor.memories).flat();
        const editorMemoryRefs = editor.sheets.flatMap(sheet => sheet.memoryRefs);
        const editorOperators = editor.sheets.flatMap(sheet => sheet.operators);

        return {
            status: "building",
            builder: new PgbCreateMemoryMap(
                logics,
                editor,
                editorDevices,
                editorMemories,
                editorMemoryRefs,
                editorOperators,
            ),
        };
    }
}

class PgbCreateMemoryMap extends ProjectGraphBuilder {
    getNextId: () => number;
    getNextRealId: () => number;
    createVariable: () => ProjectMemory;

    memoryMap: Map<string, ProjectMemory>;

    constructor(
        private logics: Logics,
        private editor: EditorState,
        private editorDevices: Device[],
        private editorMemories: Memory[],
        private editorMemoryRefs: MemoryRef[],
        private editorOperators: Operator[],
    ) {
        super();

        const { getNextId, getNextRealId } = this.createIdGenerator();

        this.getNextId = getNextId;
        this.getNextRealId = getNextRealId;
        this.createVariable = () => this.createVariableMemory(logics, getNextId, getNextRealId);

        this.memoryMap = this.createInitialMemoryMap();
    }

    step(): PgbStatus {
        const {
            logics,
            editor,
            editorDevices,
            editorMemories,
            editorMemoryRefs,
            editorOperators,
            createVariable,
            memoryMap,
        } = this;

        this.addEditorMemoriesToMemoryMap();

        return {
            status: "building",
            builder: new PgbCreateConnectionVariables(
                logics,
                editor,
                editorDevices,
                editorMemories,
                editorMemoryRefs,
                editorOperators,
                memoryMap,
                createVariable,
            ),
        };
    }

    private createIdGenerator() {
        let nextId = 0;
        let nextGeneratedId = 0xFFFF;

        const getNextRealId = () => {
            const id = nextGeneratedId;
            nextGeneratedId -= 1;
            return id;
        };

        const getNextId = () => {
            const id = nextId;
            nextId += 1;
            return id;
        };

        return { getNextId, getNextRealId };
    }

    private createVariableMemory(
        logics: Logics,
        getNextId: () => number,
        getNextRealId: () => number
    ) {
        return {
            id: getNextId(),
            realId: getNextRealId(),
            name: "VARIABLE",
            broadcast: false,
            address: "Local",
            variables: logics.memory["VARIABLE"].variables.map(variable => {
                return {
                    variable_name: variable.name,
                    value: +variable.content.default,
                };
            }),
            data: [],
        };
    }

    private createInitialMemoryMap() {
        const memoryMap = new Map<string, ProjectMemory>();

        memoryMap.set("___NULL_IN", this.createVariable());
        memoryMap.set("___NULL_OUT", this.createVariable());

        return memoryMap;
    }

    private addEditorMemoriesToMemoryMap(): Map<string, ProjectMemory> {
        const {
            logics,
            editorDevices,
            editorMemories,
            memoryMap,
            getNextId,
            getNextRealId,
        } = this;

        const existingAddresses = Object.fromEntries(
            Object.keys(logics.memory).map(memoryName => {
                const category = editorMemories
                .filter(other => other.name === memoryName)
                .map(other => other.source)
                .filter(source => source.type === "address") as MemorySourceAddress[];

                const existingAddresses = category.map(source => source.address).toSorted((a, b) => a - b);

                return [memoryName, existingAddresses] as [string, number[]];
            })
        );

        editorMemories.forEach(memory => {
            const variables = Object.entries(memory.metadata.variables).map(([variableName, variable]) => {
                if (variable.content.type === "enum") {
                    const logicsVariable = logics.memory[memory.name].variables.find(variable => variable.name === variableName)!;
                    const value = (logicsVariable.content as LogicsTypes.LogicsEnum).options[variable.content.value as string];

                    return {
                        variable_name: variableName,
                        value: +value,
                    };
                }

                return {
                    variable_name: variableName,
                    value: +variable.content.value,
                };
            });

            const data = Object.entries(memory.metadata.data).map(([size, value]) => {
                return {
                    size,
                    value,
                };
            });

            let realId: number;
            let address: string;

            switch (memory.source.type) {
                case "local": {
                    // let address = existingAddresses[memory.name].findIndex((address, index) => address !== index);

                    // if (address === -1) {
                    //     address = existingAddresses[memory.name].length;
                    // }

                    address = "Local";
                    realId = getNextRealId();

                    // existingAddresses[memory.name].push(address);
                    // existingAddresses[memory.name].sort((a, b) => a - b);

                    break;
                }
                case "address": {
                    const deviceId = memory.source.deviceId;
                    const device = editorDevices.filter(nonNullableFilter).find(device => device.id === deviceId);

                    if (device === undefined) {
                        throw new Error(`Device ${deviceId} not found`);
                    }

                    if (device.address === undefined) {
                        throw new Error(`Device ${device.name} has no address`);
                    }

                    address = device.address;
                    realId = memory.source.address;

                    break;
                }
                case "io": {
                    const { io, deviceId } = memory.source;
                    const device = editorDevices.filter(nonNullableFilter).find(device => device.id === deviceId);

                    if (device === undefined) {
                        throw new Error(`Device ${deviceId} not found`);
                    }

                    if (device.address === undefined) {
                        throw new Error(`Device ${device.name} has no address`);
                    }

                    address = device.address;

                    const logicsDevice = logics.device[device.name];

                    switch (memory.name) {
                        case "INPUT":
                            realId = logicsDevice.input[io].id;
                            break;
                        case "OUTPUT":
                            realId = logicsDevice.output[io].id;
                            break;
                        default:
                            throw new Error(`Memory ${memory.label} has "io" source, but name is invalid: ${memory.name}`);
                    }

                    break;
                }
            }

            const projectMemory = {
                name: memory.name,
                broadcast: memory.broadcast,
                address,
                id: getNextId(),
                realId,
                variables,
                data,
            };

            memoryMap.set(memory.label, projectMemory);
        });

        return memoryMap;
    }
}

class PgbCreateConnectionVariables extends ProjectGraphBuilder {
    projectInputMap: IoMap;
    projectOutputMap: IoMap;

    constructor(
        private logics: Logics,
        private editor: EditorState,
        private editorDevices: Device[],
        private editorMemories: Memory[],
        private editorMemoryRefs: MemoryRef[],
        private editorOperators: Operator[],
        private memoryMap: Map<string, ProjectMemory>,
        private createVariable: () => ProjectMemory,
    ) {
        super();

        this.projectInputMap = this.createIoMap("input");
        this.projectOutputMap = this.createIoMap("output");
    }

    step(): PgbStatus {
        const {
            logics,
            editorDevices,
            editorOperators,
            memoryMap,
            projectInputMap,
            projectOutputMap,
        } = this;

        this.addEditorConnectionsToIoMap();

        this.assertIoMapIsFilled("input", projectInputMap);
        this.assertIoMapIsFilled("output", projectOutputMap);

        return {
            status: "building",
            builder: new PgbCreateProjectGraph(
                logics,
                editorDevices,
                editorOperators,
                memoryMap,
                projectInputMap,
                projectOutputMap,
            ),
        };
    }

    private createIoMap(ioType: "input" | "output") {
        return new Map(
            this.editorOperators.map(operator => {
                const logicsOperator = this.logics.operator[operator.name];
                const logicsIos = ioType === "input" ? logicsOperator.inputs : logicsOperator.outputs;
                const operatorIos = ioType === "input" ? operator.inputs : operator.outputs;
                const counters = this.ioCounters(logicsIos, operatorIos, operator.metadata);
                const ios = counters.map(ioCounter => new Array<number>(ioCounter.ioCount));

                logicsIos.forEach((logicsIo, ioGroupIndex) => {
                    const rawIos = ios[ioGroupIndex];
                    const io = operatorIos[ioGroupIndex];

                    if (!io.enabled) {
                        if (ioType === "input") {
                            rawIos.fill(0);
                        } else {
                            rawIos.fill(1);
                        }
                    }
                });

                return [operator.id, ios];
            })
        );
    }

    private ioCounters(logicIos: LogicsIo[], ios: Io[], metadata: Metadata) {
        return logicIos.map((logicIo, index) => this.ioCounter(logicIo, ios[index].enabled, metadata));
    }

    private ioCounter(io: LogicsIo, enabled: boolean, metadata: Metadata) {
        switch (io.connection.mode) {
            case "single":
                return { ioCount: 1 };

            case "multiple":
                return { ioCount: 0 };

            case "spread":
                const counter = io.connection.counter;
                const ioCount = metadata.variables[counter].content.value as number;

                return { ioCount };
        }
    }

    private addEditorConnectionsToIoMap() {
        this.editor.sheets.forEach(sheet => {
            sheet.connections.forEach(connection => {
                const { from, to } = connection;

                if (from.type === "operator" && to.type === "operator") {
                    this.setOperatorToOperatorConnection(from, to);
                } else if (from.type === "memoryRef" && to.type === "operator") {
                    this.setMemoryRefToOperatorConnection(from, to);
                } else if (from.type === "operator" && to.type === "memoryRef") {
                    this.setOperatorToMemoryRefConnection(from, to);
                }
            });
        });
    }

    private setOperatorToOperatorConnection(
        from: ConnectionIoOperator,
        to: ConnectionIoOperator,
    ) {
        const key = this.ioToKey(from);

        if (!this.memoryMap.has(key)) {
            const projectMemory = this.createVariable();
            this.memoryMap.set(key, projectMemory);
        }

        const projectMemory = this.memoryMap.get(key);
        if (projectMemory === undefined) {
            throw new Error(`Variable ${key} not found`);
        }

        const projectInput = this.projectInputMap.get(to.operatorId);
        if (projectInput === undefined) {
            throw new Error(`Operator ${to.operatorId} not found`);
        }

        projectInput[to.ioGroup][to.ioIndex] = projectMemory.id;

        const projectOutput = this.projectOutputMap.get(from.operatorId);
        if (projectOutput === undefined) {
            throw new Error(`Operator ${from.operatorId} not found`);
        }

        const operator = this.editorOperators.find(operator => operator.id === from.operatorId);
        if (operator === undefined) {
            throw new Error(`Operator ${from.operatorId} not found`);
        }

        const logicsOperator = this.logics.operator[operator.name];

        if (logicsOperator.outputs[from.ioGroup].connection.mode === "multiple") {
            projectOutput[from.ioGroup].push(projectMemory.id);
        } else {
            projectOutput[from.ioGroup][from.ioIndex] = projectMemory.id;
        }
    }

    private setMemoryRefToOperatorConnection(
        from: ConnectionIoMemoryRef,
        to: ConnectionIoOperator,
    ) {
        const memory = this.ioMemoryRefToMemory(from);
        const key = this.memoryToKey(memory);

        const projectMemory = this.memoryMap.get(key);
        if (projectMemory === undefined) {
            throw new Error(`Variable ${key} not found`);
        }

        const projectInput = this.projectInputMap.get(to.operatorId);
        if (projectInput === undefined) {
            throw new Error(`Operator ${to.operatorId} not found`);
        }

        projectInput[to.ioGroup][to.ioIndex] = projectMemory.id;
    }

    private setOperatorToMemoryRefConnection(
        from: ConnectionIoOperator,
        to: ConnectionIoMemoryRef,
    ) {
        const memory = this.ioMemoryRefToMemory(to);
        const key = this.memoryToKey(memory);

        const projectMemory = this.memoryMap.get(key);
        if (projectMemory === undefined) {
            throw new Error(`Variable ${key} not found`);
        }

        const projectOutput = this.projectOutputMap.get(from.operatorId);
        if (projectOutput === undefined) {
            throw new Error(`Operator ${from.operatorId} not found`);
        }

        const operator = this.editorOperators.find(operator => operator.id === from.operatorId);
        if (operator === undefined) {
            throw new Error(`Operator ${from.operatorId} not found`);
        }

        const logicsOperator = this.logics.operator[operator.name];

        if (logicsOperator.outputs[from.ioGroup].connection.mode === "multiple") {
            projectOutput[from.ioGroup].push(projectMemory.id);
        } else {
            projectOutput[from.ioGroup][from.ioIndex] = projectMemory.id;
        }
    }

    private ioToKey(io: ConnectionIo): string {
        return Object.values(io).join("/");
    }

    private memoryToKey(memory: Memory): string {
        return memory.label;
    }

    private ioMemoryRefToMemory(io: ConnectionIoMemoryRef): Memory {
        const originMemoryRef = this.editorMemoryRefs.find(memoryRef => memoryRef.id === io.memoryRefId);

        if (originMemoryRef === undefined) {
            throw new Error(`MemoryRef ${io.memoryRefId} not found`);
        }

        const originMemory = this.editorMemories.find(memory => memory.id === originMemoryRef.sourceMemoryId);

        if (originMemory === undefined) {
            throw new Error(`Memory ${originMemoryRef.sourceMemoryId} not found`);
        }

        return originMemory;
    }

    private assertIoMapIsFilled(ioType: "input" | "output", ioMap: IoMap) {
        ioMap.forEach((ioArray, operatorId) => {
            ioArray.forEach((ioGroup, ioGroupIndex) => {
                console.log(ioGroup);
                const ioIndex = ioGroup.findIndex(io => io === undefined);
                if (ioIndex !== -1) {
                    throw new Error(`Operator ${operatorId} ${ioType} ${ioGroupIndex}/${ioIndex} not found`);
                }
            });
        });
    }
}

class PgbCreateProjectGraph extends ProjectGraphBuilder {
    constructor(
        private logics: Logics,
        private editorDevices: Device[],
        private editorOperators: Operator[],
        private memoryMap: Map<string, ProjectMemory>,
        private projectInputMap: IoMap,
        private projectOutputMap: IoMap,
    ) {
        super();
    }

    step(): PgbStatus {
        const { memoryMap } = this;

        const devices = this.toProjectDevices();
        const memories = Array.from(memoryMap.values());
        const operators = this.toProjectOperators();

        const projectGraph: ProjectGraph = {
            version: [1, 0, 0],
            devices,
            sheet: {
                memories,
                operators,
            },
        };

        return {
            status: "done",
            projectGraph,
        };
    }

    private toProjectDevices(): ProjectDevice[] {
        return this.editorDevices.filter(nonNullableFilter).map(device => {
            console.log("device.configs", device.configs);

            const logicsConfigInput = this.logics.config["config_input"];
            const configInputs = device.inputs.map(input => {
                const pullConfig = logicsConfigInput.variables.find(variable => variable.name === "pull")!;
                const modeConfig = logicsConfigInput.variables.find(variable => variable.name === "mode")!;
                const pullValue = (pullConfig!.content as LogicsTypes.LogicsEnum).options[input.pull];
                const modeValue = (modeConfig!.content as LogicsTypes.LogicsEnum).options[input.mode];

                return {
                    type: "CONFIG_INPUT",
                    variables: [
                        { value: input.id },
                        { value: pullValue },
                        { value: modeValue },
                    ],
                };
            });

            const logicsConfigOutput = this.logics.config["config_output"];
            const configOutputs = device.outputs.map(output => {
                const modeConfig = logicsConfigOutput.variables.find(variable => variable.name === "mode")!;
                const signalConfig = logicsConfigOutput.variables.find(variable => variable.name === "signal")!;
                const modeValue = (modeConfig!.content as LogicsTypes.LogicsEnum).options[output.mode];
                const signalValue = (signalConfig!.content as LogicsTypes.LogicsEnum).options[output.signal];

                return {
                    type: "CONFIG_OUTPUT",
                    variables: [
                        { value: output.id },
                        { value: modeValue },
                        { value: signalValue },
                    ],
                };
            });

            const deviceConfigs = Object.entries(device.configs)
                .flatMap(([type, config]) => config.map(config => [type, config] as [string, Config]))
                .map(([type, config]) => {
                    const logicsConfig = this.logics.config[type];
                    return {
                        type: type.toUpperCase(),
                        variables: Object.entries(config.variables).map(([variableName, variable]) => {
                            const logicsVariable = logicsConfig.variables.find(variable => variable.label === variableName)!;
                            const value = logicsVariable.content.type !== "enum"
                                ? variable.content.value
                                : (logicsVariable.content as LogicsTypes.LogicsEnum).options[variable.content.value as string];
                            return { value: +value };
                        }),
                    };
                });

            const configs = configInputs.concat(configOutputs).concat(deviceConfigs);

            console.log("configs", configs);

            if (device.address === undefined) {
                throw new Error(`Device ${device.name} has no address`);
            }

            return {
                address: device.address,
                configs,
            };
        });
    }

    private toProjectOperators(): ProjectOperator[] {
        return this.editorOperators.map((operator, operatorIndex) => {
            const inputs = this.projectInputMap.get(operator.id);
            if (inputs === undefined) {
                throw new Error(`Operator ${operator.id} not found`);
            }

            const outputs = this.projectOutputMap.get(operator.id);
            if (outputs === undefined) {
                throw new Error(`Operator ${operator.id} not found`);
            }

            const logicsOperator = this.logics.operator[operator.name];
            const variables = Object.entries(operator.metadata.variables).map(([variableName, variable]) => {
                const ioGroup = logicsOperator.outputs.findIndex(output => {
                    if (output.connection.mode === "single") {
                        return false;
                    }

                    return output.connection.counter === variableName;
                });

                if (ioGroup !== -1) {
                    return {
                        variable_name: variableName,
                        value: outputs[ioGroup].length,
                    };
                }

                if (variable.content.type === "enum") {
                    const logicsVariable = this.logics.operator[operator.name].variables.find(variable => variable.name === variableName)!;
                    const value = (logicsVariable.content as LogicsTypes.LogicsEnum).options[variable.content.value as string];

                    return {
                        variable_name: variableName,
                        value: +value,
                    };
                }

                return {
                    variable_name: variableName,
                    value: +variable.content.value,
                };
            });

            const projectOperator: ProjectOperator = {
                id: operatorIndex,
                gridPosition: {
                    x: operator.boundingBox.left,
                    y: operator.boundingBox.top,
                },
                name: operator.name,
                inputs,
                outputs,
                variables,
            };

            return projectOperator;
        }).toSorted((a, b) => a.gridPosition.y - b.gridPosition.y);
    }
}
