import { LogicsVariable } from "../../../logics/logics/LogicsVariable";

export namespace Types {
    export interface Enum {
        type: "enum";
        value: string;
    }

    export interface Number {
        type: "number";
        value: number;
    }

    export interface Boolean {
        type: "boolean";
        value: boolean;
    }
}

export type VariableContent = Types.Enum | Types.Number | Types.Boolean;

interface Variable {
    label: string;
    content: VariableContent;
}

export default Variable;

export function variableFromLogics(logicsVariable: LogicsVariable): Variable {
    switch (logicsVariable.content.type) {
        case "enum":
            return {
                label: logicsVariable.label,
                content: {
                    type: logicsVariable.content.type,
                    value: logicsVariable.content.default as string,
                }
            };
        case "number":
            return {
                label: logicsVariable.label,
                content: {
                    type: logicsVariable.content.type,
                    value: logicsVariable.content.default as number,
                }
            };
        case "boolean":
            return {
                label: logicsVariable.label,
                content: {
                    type: logicsVariable.content.type,
                    value: logicsVariable.content.default as boolean,
                }
            };
    }
}
