import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { zip } from "../../../../../common/zip";
import { updateBlockDataValue, updateBlockVariableContent, updateMemoryRefSource, updateOperatorEnabledIos } from "../../../../editor/editorSlice";
import Memory from "../../../../editor/interfaces/components/Memory";
import Operator from "../../../../editor/interfaces/components/Operator";
import { Types, VariableContent } from "../../../../editor/interfaces/components/Variable";
import { LogicsMemory } from "../../../../logics/logics/LogicsMemory";
import { LogicsOperator } from "../../../../logics/logics/LogicsOperator";
import { LogicsData as DataConfig, LogicsTypes as TypesConfig, LogicsVariable as VariableConfig } from "../../../../logics/logics/LogicsVariable";
import { selectLogics } from "../../../../logics/logicsSlice";
import { WindowTypeAndPropsDefinition, closeWindow } from "../../../windowManagerSlice";
import css from "./BlockConfigWindow.module.css";

interface BlockSourceOperator {
    type: "operator";
    operatorId: string;
    sheetIndex: number;
}

interface BlockSourceMemory {
    type: "memoryRef";
    memoryRefId: string;
    sheetIndex: number;
}

export type BlockSource = BlockSourceOperator | BlockSourceMemory;

export interface BlockConfigWindowProps {
    source: BlockSource;
}

export type BlockConfigWindowTypeAndProps = WindowTypeAndPropsDefinition<
    "blockConfig",
    BlockConfigWindowProps
>;

function OperatorConfigWindow(props: BlockSourceOperator & { id: string }) {
    const dispatch = useAppDispatch();
    const logics = useAppSelector(selectLogics);
    const operator = useAppSelector(
        state => state.editor.sheets[props.sheetIndex].operators.find(
            operator => operator.id === props.operatorId
        )
    );

    if (!operator) {
        dispatch(closeWindow(props.id));
        return null;
    }

    const config = logics.operator[operator.name];

    return <div className={css.content}>
        <BlockConfigWindowVariables
            source={props}
            block={operator}
            config={config}
        />
        <BlockConfigWindowData
            source={props}
            block={operator}
            config={config}
        />
        <BlockConfigWindowIoEnables
            source={props}
            operator={operator}
            logicsOperator={config}
        />
    </div>;
}

function MemoryRefConfigWindow(props: BlockSourceMemory & { id: string }) {
    const dispatch = useAppDispatch();

    const logics = useAppSelector(selectLogics);
    const memories = useAppSelector(state => state.editor.memories);
    const memoryRef = useAppSelector(
        state => state.editor.sheets[props.sheetIndex].memoryRefs.find(
            memoryRef => memoryRef.id === props.memoryRefId
        )
    );

    if (!memoryRef) {
        dispatch(closeWindow(props.id));
        return null;
    }

    const config = logics.memory[memoryRef.name];

    const memory = useAppSelector(
        state => state.editor.memories[memoryRef.name].find(
            memory => memory.id === memoryRef.sourceMemoryId
        )
    );

    console.log({memory});

    const isIo = ["INPUT", "OUTPUT"].includes(config.name);

    return <div className={css.content}>
        <div className={css.row}>
            <div className={css.sectionTitle}>Memory</div>
            <select
                value={memory?.label ?? ""}
                onChange={event => {
                    const associatedMemory = memories[memoryRef.name].find(memory => memory.label === event.target.value);
                    console.log(associatedMemory, memories[memoryRef.name]);
                    if (associatedMemory) {
                        dispatch(updateMemoryRefSource({
                            sheetId: memoryRef.sheetId,
                            memoryRefId: memoryRef.id,
                            sourceMemoryId: associatedMemory.id,
                        }));
                    }
                }}
            >
                {memory === undefined && <option value={""}/>}
                {memories[config.name].map(memory => {
                    return <option key={memory.label} value={memory.label}>
                        {memory.label}
                    </option>;
                })}
            </select>
        </div>
        {memory && <React.Fragment key={memory.label}>
            <BlockConfigWindowVariables
                source={props}
                block={memory}
                config={config}
            />
            <BlockConfigWindowData
                source={props}
                block={memory}
                config={config}
            />
        </React.Fragment>}
    </div>;
}

function BlockConfigWindowVariables(props: {
    source: BlockSource,
    block: Operator | Memory,
    config: LogicsOperator | LogicsMemory,
}) {
    const { source, block, config } = props;

    const dispatch = useAppDispatch();

    const nonPrivateVariables = config.variables.filter(variable => !variable.private);
    const [variables, setVariables] = useState(block.metadata.variables);
    const setVariableContent = (name: string) => {
        return (content: VariableContent) => setVariables(variables => {
            return {
                ...variables,
                [name]: {
                    ...variables[name],
                    content,
                },
            };
        });
    };

    const hasVariableChanges = useMemo(() => {
        return Object.entries(variables)
        .some(([name, variable]) => {
            const originalJson = JSON.stringify(block.metadata.variables[name].content);
            const newJson = JSON.stringify(variable.content);
            return originalJson !== newJson;
        });
    }, [variables]);

    const areVariablesValid = useMemo(() => {
        return Object.entries(variables)
            .every(([_name, variable]) => {
                switch (variable.content.type) {
                    case "boolean":
                    case "enum":
                        return true;
                    case "number":
                        return !isNaN(variable.content.value);
                }
            });
    }, [variables]);

    const canSaveVariables = hasVariableChanges && areVariablesValid;

    if (nonPrivateVariables.length === 0) {
        return null;
    }

    return <>
        <div className={css.row}>
            <div className={css.sectionTitle}>Variables</div>
            <div className={css.sectionTitle}>
                <button
                    className={css.saveButton}
                    disabled={!canSaveVariables}
                    onClick={() => {
                        Object.entries(variables).forEach(([name, variable]) => {
                            dispatch(updateBlockVariableContent({
                                source,
                                variableName: name,
                                content: variable.content,
                            }));
                            setVariableContent(name)(variable.content);
                        });
                    }}
                ><FontAwesomeIcon icon={faFloppyDisk}/></button>
            </div>
        </div>
        {nonPrivateVariables.map((config, index) => {
            const variable = variables[config.name];

            return <div key={index} className={css.row}>
                <div className={css.label}>
                    {variable.label}
                </div>
                <React.Fragment key={index}>
                    {variable.content.type === "boolean" && <BooleanVariable
                        content={variable.content}
                        config={config}
                        onChange={setVariableContent(config.name)}
                    />}
                    {variable.content.type === "enum" && <EnumVariable
                        content={variable.content}
                        config={config}
                        onChange={setVariableContent(config.name)}
                    />}
                    {variable.content.type === "number" && <NumberVariable
                        content={variable.content}
                        config={config}
                        onChange={setVariableContent(config.name)}
                    />}
                </React.Fragment>
            </div>;
        })}
    </>;
}

function BlockConfigWindowIoEnables(props: {
    source: BlockSource,
    operator: Operator,
    logicsOperator: LogicsOperator,
}) {
    const { source, operator, logicsOperator } = props;

    const dispatch = useAppDispatch();

    const inputs = operator.inputs;
    const logicsInputs = logicsOperator.inputs;
    const nonRequiredInputs = logicsInputs.filter(input => !input.required);

    const [inputEnables, setInputEnables] = useState(() => Object.fromEntries(
        inputs.map(io => [io.name, io.enabled])
    ));

    const outputs = operator.outputs;
    const logicsOutputs = logicsOperator.outputs;
    const nonRequiredOutputs = logicsOutputs.filter(output => !output.required);

    const [outputEnables, setOutputEnables] = useState(() => Object.fromEntries(
        outputs.map(io => [io.name, io.enabled])
    ));

    const setEnable = (name: string, ioType: "input" | "output") => {
        return (enabled: Types.Boolean) => {
            switch (ioType) {
                case "input":
                    setInputEnables(enables => {
                        return {
                            ...enables,
                            [name]: enabled.value,
                        };
                    });
                    break;

                case "output":
                    setOutputEnables(enables => {
                        return {
                            ...enables,
                            [name]: enabled.value,
                        };
                    });
                    break;
            }
        }
    };

    const hasVariableChanges = useMemo(() => {
        return zip(Object.values(inputEnables), inputs.map(io => io.enabled))
            .concat(zip(Object.values(outputEnables), outputs.map(io => io.enabled)))
            .some(([a, b]) => a !== b);
    }, [inputEnables, outputEnables, props]);

    const canSaveVariables = hasVariableChanges;

    if (nonRequiredInputs.length === 0 && nonRequiredOutputs.length === 0) {
        return null;
    }

    return <>
        <div className={css.row}>
            <div className={css.sectionTitle}>IO group configuration</div>
            <div className={css.sectionTitle}>
                <button
                    className={css.saveButton}
                    disabled={!canSaveVariables}
                    onClick={() => {
                        Object.entries(inputEnables).forEach(([name, enabled]) => {
                            dispatch(updateOperatorEnabledIos({
                                sheetIndex: source.sheetIndex,
                                operatorId: operator.id,
                                ioType: "input",
                                ioName: name,
                                enabled,
                            }));
                            // setEnable(name, "input")({ type: "boolean", value: enabled });
                        });
                        Object.entries(outputEnables).forEach(([name, enabled]) => {
                            dispatch(updateOperatorEnabledIos({
                                sheetIndex: source.sheetIndex,
                                operatorId: operator.id,
                                ioType: "output",
                                ioName: name,
                                enabled,
                            }));
                            // setEnable(name, "output")({ type: "boolean", value: enabled });
                        });
                    }}
                ><FontAwesomeIcon icon={faFloppyDisk}/></button>
            </div>
        </div>
        {nonRequiredInputs.map(logicsInput => {
            const enabled = inputEnables[logicsInput.label];

            return <div key={logicsInput.label} className={css.row}>
                <div className={css.label}>
                    Enable {logicsInput.label}
                </div>
                <BooleanVariable
                    content={{
                        type: "boolean",
                        value: enabled,
                    }}
                    onChange={setEnable(logicsInput.label, "input")}
                />
            </div>;
        })}
        {nonRequiredOutputs.map(logicsOutput => {
            const enabled = outputEnables[logicsOutput.label];

            return <div key={logicsOutput.label} className={css.row}>
                <div className={css.label}>
                    Enable {logicsOutput.label}
                </div>
                <BooleanVariable
                    content={{
                        type: "boolean",
                        value: enabled,
                    }}
                    onChange={setEnable(logicsOutput.label, "output")}
                />
            </div>;
        })}
    </>;
}

function BlockConfigWindowData(props: {
    source: BlockSource,
    block: Operator | Memory,
    config: LogicsOperator | LogicsMemory,
}) {
    const { source, block, config } = props;

    const dispatch = useAppDispatch();

    const [data, setData] = useState(block.metadata.data);
    const setDataContent = (name: string) => {
        return (content: string) => setData(data => {
            return {
                ...data,
                [name]: content,
            };
        });
    };

    const hasDataChanges = useMemo(() => {
        return Object.entries(data)
        .some(([name, value]) => {
            return block.metadata.data[name] !== value;
        });
    }, [data]);

    const areDataValid = useMemo(() => {
        return Object.entries(data)
            .every(([_name, value]) => {
                const isAscii = /^[\x00-\x7F]*$/.test(value);
                return isAscii;
            });
    }, [data]);

    const canSaveVariables = hasDataChanges && areDataValid;

    if (!config.data || Object.keys(config.data).length === 0) {
        return null;
    }

    return <>
        <div className={css.row}>
            <div className={css.sectionTitle}>Data</div>
            <div className={css.sectionTitle}>
                <button
                    className={css.saveButton}
                    disabled={!canSaveVariables}
                    onClick={() => {
                        Object.entries(data).forEach(([name, value]) => {
                            dispatch(updateBlockDataValue({
                                source,
                                dataName: name,
                                value,
                            }));
                            dispatch(updateBlockVariableContent({
                                source,
                                variableName: name,
                                content: {
                                    type: "number",
                                    value: value.length,
                                },
                            }));
                            setDataContent(name)(value);
                        });
                    }}
                ><FontAwesomeIcon icon={faFloppyDisk}/></button>
            </div>
        </div>
        {config.data.map((config, index) => {
            const currentData = data[config.size];

            return <div key={index} className={css.row}>
                <div className={css.label}>
                    {config.label}
                </div>
                <DataField
                    content={currentData}
                    config={config}
                    onChange={setDataContent(config.size)}
                />
            </div>;
        })}
    </>;
}

export function BlockConfigWindow(props: BlockConfigWindowProps & { id: string }) {
    switch (props.source.type) {
        case "operator": return <OperatorConfigWindow {...props.source} id={props.id}/>;
        case "memoryRef": return <MemoryRefConfigWindow {...props.source} id={props.id}/>;
    }
}

export function DataField(props: {
    content: string,
    config: DataConfig,
    onChange: (value: string) => void,
}) {
    return <div className={css.dataField}>
        <input
            type="text"
            value={props.content}
            onChange={event => {
                props.onChange(event.target.value);
            }}
        />
    </div>;
}

export function BooleanVariable(props: {
    content: Types.Boolean,
    config?: VariableConfig,
    onChange: (value: Types.Boolean) => void,
}) {
    return <div className={css.booleanVariableInput}>
        <input
            type="checkbox"
            checked={props.content.value}
            onChange={event => {
                props.onChange({
                    ...props.content,
                    value: event.target.checked,
                });
            }}
        />
    </div>;
}

export function EnumVariable(props: {
    content: Types.Enum,
    config: VariableConfig,
    onChange: (value: Types.Enum) => void,
}) {
    const content = props.config.content as TypesConfig.LogicsEnum;

    return <div className={css.enumVariableInput}>
        <select
            value={props.content.value}
            onChange={event => {
                props.onChange({
                    ...props.content,
                    value: event.target.value,
                });
            }}
        >
            {Object.keys(content.options).map(key => {
                return <option value={key}>{key}</option>;
            })}
        </select>
    </div>;
}

export function NumberVariable(props: {
    content: Types.Number,
    config: VariableConfig,
    onChange: (value: Types.Number) => void,
}) {
    const content = props.config.content as TypesConfig.LogicsNumber;

    return <div className={css.numberVariableInput}>
        <input
            className={css.numberVariableInput}
            type="text"
            min={content.min}
            max={content.max}
            value={isNaN(props.content.value) ? "" : props.content.value}
            onChange={event => {
                let newValue = parseInt(event.target.value.replace(/[^0-9]/g, ""));

                const isNegative = event.target.value.startsWith("-");
                if (isNegative) {
                    newValue *= -1;
                }

                const nativeEvent = (event.nativeEvent as InputEvent);
                if (nativeEvent.data === "-") {
                    newValue *= -1;
                }

                // if (content.min !== undefined) {
                //     newValue = Math.max(newValue, content.min);
                // }

                // if (content.max !== undefined) {
                //     newValue = Math.min(newValue, content.max);
                // }

                props.onChange({
                    ...props.content,
                    value: newValue,
                });
            }}

        />
    </div>;
}
