import { faFloppyDisk, faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { generateUniqueId } from "../../../../../common/generateUniqueId";
import { nonNullableFilter } from "../../../../../common/nonNullableFilter";
import { deleteMemory, insertMemory, updateMemory } from "../../../../editor/editorSlice";
import Memory, { MemoryScratchPad, MemorySource, MemorySourceAddress, MemorySourceIo, isSameMemorySource } from "../../../../editor/interfaces/components/Memory";
import { VariableContent, variableFromLogics } from "../../../../editor/interfaces/components/Variable";
import { LogicsMemory as MemoryConfig } from "../../../../logics/logics/LogicsMemory";
import { selectLogics } from "../../../../logics/logicsSlice";
import { WindowTypeAndPropsDefinition } from "../../../windowManagerSlice";
import { BooleanVariable, DataField, EnumVariable, NumberVariable } from "../BlockConfigWindow/BlockConfigWindow";
import css from "./MemoryManagerWindow.module.css";

export interface MemoryManagerWindowProps {
    initialCategory?: string,
}

export type MemoryManagerWindowTypeAndProps = WindowTypeAndPropsDefinition<
    "memoryManager",
    MemoryManagerWindowProps
>;

function createMemoryScratchPad(config: MemoryConfig) {
    const memory: MemoryScratchPad = {
        label: config.name,
        name: config.name,
        broadcast: false,
        source: null,
        metadata: {
            variables: Object.fromEntries(
                config.variables.map(variable => [
                    variable.name,
                    variableFromLogics(variable),
                ])
            ),
            data: config.data
                ? Object.fromEntries(
                    config.data.map(data => [
                        data.size,
                        "",
                    ]))
                : {},
        },
    };

    console.log({memory});

    return memory;
}

export function MemoryManagerWindow(props: MemoryManagerWindowProps & { id: string }) {
    const dispatch = useAppDispatch();

    const logics = useAppSelector(selectLogics);
    const editorMemories = useAppSelector(state => state.editor.memories);
    const editorDevices = useAppSelector(state => state.editor.devices);

    const [selectedCategory, setSelectedCategory] = useState<string | undefined>(props.initialCategory);
    const [searchBoxText, setSearchBoxText] = useState<string>("");
    const [memoryScratchPad, setMemoryScratchPad] = useState<MemoryScratchPad | null>(null);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [unableToSaveMessage, setUnableToSaveMessage] = useState<string | null>(null);

    useEffect(() => {
        if (selectedCategory && selectedIndex !== null) {
            const category = editorMemories[selectedCategory];

            if (selectedIndex >= category.length) {
                if (!isEditing) {
                    setMemoryScratchPad(null);
                }
                setSelectedIndex(null);
                return;
            }

            const source = category[selectedIndex].source;

            console.log(editorDevices);
            const availableDeviceIds = editorDevices.filter(nonNullableFilter).map(device => device.id);

            if (source.type !== "local" && availableDeviceIds.includes(source.deviceId)) {
                if (!isEditing) {
                    setMemoryScratchPad(editorMemories[selectedCategory][selectedIndex]);
                }
            } else {
                if (!isEditing) {
                    setMemoryScratchPad(null);
                }
                setSelectedIndex(null);
            }
        }
    }, [editorDevices]);

    const memoryCategories = Object.keys(logics.memory);

    const config = selectedCategory ? logics.memory[selectedCategory] : undefined;
    const nonPrivateVariables = config?.variables.filter(variable => !variable.private);
    const isIo = !!(config && ["INPUT", "OUTPUT"].includes(config.name));

    const setVariableContent = (name: string) => {
        return (content: VariableContent) => setMemoryScratchPad(memory => {
            if (memory === null) {
                return null;
            }

            return {
                ...memory,
                metadata: {
                    ...memory.metadata,
                    variables: {
                        ...memory.metadata.variables,
                        [name]: {
                            ...memory.metadata.variables[name],
                            content,
                        },
                    },
                },
            };
        });
    };

    const setDataContent = (name: string) => {
        return (content: string) => setMemoryScratchPad(memory => {
            if (memory === null) {
                return null;
            }

            return {
                ...memory,
                metadata: {
                    ...memory.metadata,
                    data: {
                        ...memory.metadata.data,
                        [name]: content,
                    },
                },
            };
        });
    };

    const setSourceDeviceId = (deviceId: string) => {
        setMemoryScratchPad(memory => {
            if (memory === null) {
                return null;
            }

            let source: MemorySource | null = null;
            console.log(deviceId);

            if (deviceId === "null") {
                source = {
                    type: "local",
                };
            } else {
                if (isIo) {
                    source = {
                        type: "io",
                        deviceId,
                        io: "",
                    };
                } else {
                    const originalSource = selectedIndex !== null ? editorMemories[memory.name][selectedIndex].source : null;

                    if (originalSource === null || originalSource.type !== "address") {
                        const category = editorMemories[memory.name]
                            .map(memory => memory.source)
                            .filter(nonNullableFilter)
                            .filter(source => "address" in source) as MemorySourceAddress[];

                        const existingAddresses = category.map(source => source.address).toSorted((a, b) => a - b);
                        let address = existingAddresses.findIndex((address, index) => address !== index);

                        if (address === -1) {
                            address = existingAddresses.length;
                        }

                        source = {
                            type: "address",
                            deviceId,
                            address,
                        };
                    } else {
                        source = {
                            type: "address",
                            deviceId,
                            address: originalSource.address,
                        };
                    }
                }
            }

            return {
                ...memory,
                source
            };
        });
    }

    const setSourceAddress = (address: number) => {
        setMemoryScratchPad(memory => {
            if (memory === null) {
                return null;
            }

            const originalSource = memory.source as MemorySourceAddress;

            return {
                ...memory,
                source: {
                    ...originalSource,
                    address,
                }
            };
        });
    };

    const setSourceIo = (io: string) => {
        setMemoryScratchPad(memory => {
            if (memory === null) {
                return null;
            }

            const originalSource = memory.source as MemorySourceIo;

            return {
                ...memory,
                source: {
                    ...originalSource,
                    io,
                }
            };
        });
    }

    const canSaveMemory = useMemo(() => {
        if (memoryScratchPad === null) {
            setUnableToSaveMessage("Memory is not defined");
            return false;
        }

        if (memoryScratchPad.source === null) {
            setUnableToSaveMessage("Memory source is not defined");4
            return false;
        }

        // Check if label is valid
        const existingMemoryNames = Object.keys(editorMemories);
        const existingMemoryLabels = Object.values(editorMemories).flatMap(memories => memories.map(memory => memory.label));

        const isLabelAMemoryName = existingMemoryNames.includes(memoryScratchPad.label);

        if (isLabelAMemoryName) {
            setUnableToSaveMessage("Memory label can't be a memory name");
            return false;
        }

        const isCurrentLabelInUse = existingMemoryLabels.includes(memoryScratchPad.label);

        if (selectedIndex !== null) {
            const selfMemoryLabel = editorMemories[memoryScratchPad.name][selectedIndex].label;
            if (isCurrentLabelInUse && selfMemoryLabel !== memoryScratchPad.label) {
                setUnableToSaveMessage("Memory label is already in use");
                return false;
            }
        } else {
            if (isCurrentLabelInUse) {
                setUnableToSaveMessage("Memory label is already in use");
                return false;
            }
        }


        if (config === undefined) {
            setUnableToSaveMessage("[INTERNAL ERROR] Memory category is not defined");
            return false;
        }

        const logicsVariables = config.variables;
        const areVariablesValid = Object.entries(memoryScratchPad.metadata.variables)
            .every(([name, variable]) => {
                switch (variable.content.type) {
                    case "boolean":
                    case "enum":
                        return true;
                    case "number":
                        if (isNaN(variable.content.value)) {
                            return false;
                        }

                        const config = logicsVariables.find(variable => variable.name === name);

                        if (config === undefined) {
                            setUnableToSaveMessage("[INTERNAL ERROR] Variable config is not defined");
                            return false;
                        }

                        const configContent = config.content;

                        if (configContent.type !== "number") {
                            setUnableToSaveMessage("[INTERNAL ERROR] Variable config is not a number");
                            return false;
                        }

                        if (configContent.min !== undefined && variable.content.value < configContent.min) {
                            setUnableToSaveMessage(`Variable "${config.label}" value is below minimum`);
                            return false;
                        }

                        if (configContent.max !== undefined && variable.content.value > configContent.max) {
                            setUnableToSaveMessage(`Variable "${config.label}" value is above maximum`);
                            return false;
                        }

                        return true;
                }
            });

        if (!areVariablesValid) {
            return false;
        }

        // Check if source is valid
        if (memoryScratchPad.source.type !== "local") {
            if (memoryScratchPad.source.type === "io") {
                if (memoryScratchPad.source.io === "") {
                    setUnableToSaveMessage("IO is not selected");
                    return false;
                }
            } else {
                if (isNaN(memoryScratchPad.source.address)) {
                    setUnableToSaveMessage("Address is not a number");
                    return false;
                }
            }

            const source = memoryScratchPad.source;

            const sourceIsTaken = Object.values(editorMemories).some(
                memories => memories.some(
                    memory => isSameMemorySource(memory.source, source) && memory.name === memoryScratchPad.name
                )
            );

            console.log({sourceIsTaken, memoryScratchPad, editorMemories});

            if (selectedIndex !== null) {
                const selfSource = editorMemories[memoryScratchPad.name][selectedIndex].source;
                if (sourceIsTaken && !isSameMemorySource(selfSource, source)) {
                    const takenBy = editorMemories[memoryScratchPad.name].find(
                        memory => isSameMemorySource(memory.source, source)
                    )?.label ?? "unknown";
                    setUnableToSaveMessage(`Memory source is already in use by "${takenBy}"`);
                    return false;
                }
            } else {
                if (sourceIsTaken) {
                    setUnableToSaveMessage(`Memory source is already in use by another memory`);
                    return false;
                }
            }
        }

        setUnableToSaveMessage(null);
        return true;
    }, [memoryScratchPad]);

    return <div className={css.content}>
        <div className={css.sideBar}>
            <div className={css.categoryBar}>
                <select
                    className={classNames(css.categorySelector, css.select)}
                    value={selectedCategory}
                    onChange={event => {
                        setSelectedCategory(event.target.value);
                        setMemoryScratchPad(null);
                        setSelectedIndex(null);
                        setIsEditing(false);
                    }}
                >
                    {selectedCategory === undefined && <option value={undefined}></option>}
                    {memoryCategories.map(category => <option key={category} value={category}>{category}</option>)}
                </select>
                <button
                    className={css.creatorButton}
                    onClick={() => {
                        if (selectedCategory === undefined) {
                            return;
                        }

                        const category = logics.memory[selectedCategory];
                        const memory = createMemoryScratchPad(category);
                        setMemoryScratchPad(memory);
                        setSelectedIndex(null);
                        setIsEditing(true);
                    }}
                >
                    <FontAwesomeIcon icon={faPlus}/>
                </button>
            </div>
            <input
                className={css.searchBox}
                value={searchBoxText}
                type="text"
                placeholder="Search..."
                onChange={event => setSearchBoxText(event.target.value)}
            />

            <div className={css.memoryList}>
                {selectedCategory && editorMemories[selectedCategory].map((memory, index) => {
                    if (searchBoxText !== "" && !memory.label.includes(searchBoxText)) {
                        return null;
                    }

                    return <div
                        key={index}
                        className={classNames(css.memoryListItem, {
                            [css.memoryListItemSelected]: index === selectedIndex,
                        })}
                        onClick={() => {
                            setMemoryScratchPad(memory);
                            setSelectedIndex(index);
                            if (index !== selectedIndex) {
                                setIsEditing(false);
                            }
                        }}
                    >{memory.label}</div>;
                })}
            </div>
        </div>
        {memoryScratchPad && config && nonPrivateVariables && <div
            key={`${selectedCategory}-${selectedIndex}`}
            className={css.memoryEditor}
        >
            <div className={css.row}>
                {!isEditing && <>
                    <button
                        className={css.button}
                        onClick={() => {
                            setIsEditing(true);
                        }}
                    ><FontAwesomeIcon icon={faPen}/></button>
                    <div className={css.memoryLabel}>{memoryScratchPad.label}</div>
                    {selectedIndex !== null && <button
                        className={classNames(css.button, css.deleteButton)}
                        onClick={() => {
                            setMemoryScratchPad(null);
                            setSelectedIndex(null);
                            setIsEditing(false);
                            dispatch(deleteMemory({
                                memoryName: memoryScratchPad.name,
                                index: selectedIndex,
                            }));
                        }}
                    ><FontAwesomeIcon icon={faTrash}/></button>}
                </>}
                {isEditing && <>
                    <button
                        className={classNames(css.button, { [css.creatorButtonDisabled]: !canSaveMemory })}
                        disabled={!canSaveMemory}
                        onClick={() => {
                            if (!canSaveMemory) {
                                throw new Error("Cannot insert memory with invalid data");
                            }

                            if (memoryScratchPad.source === null) {
                                throw new Error("Cannot insert memory with null source");
                            }

                            if (selectedCategory === undefined) {
                                throw new Error("Cannot insert memory in undefined category");
                            }

                            if (selectedIndex === null) {
                                setSelectedIndex(editorMemories[selectedCategory].length);
                                dispatch(insertMemory({
                                    memory: {
                                        id: generateUniqueId("memory"),
                                        ...memoryScratchPad
                                    } as Memory,
                                }));
                            } else {
                                if (selectedIndex >= editorMemories[selectedCategory].length) {
                                    throw new Error("Cannot update memory with invalid index");
                                }

                                dispatch(updateMemory({
                                    memory: {
                                        id: editorMemories[selectedCategory][selectedIndex].id,
                                        ...memoryScratchPad
                                    } as Memory,
                                    index: selectedIndex,
                                }));
                            }

                            setIsEditing(false);
                        }}
                    ><FontAwesomeIcon icon={faFloppyDisk}/></button>
                    <MemoryLabel
                        scratchPadMemory={memoryScratchPad}
                        onChange={content => {
                            setMemoryScratchPad({
                                ...memoryScratchPad,
                                label: content,
                            });
                        }}
                    />
                </>}
            </div>
            {unableToSaveMessage && <div className={css.row} style={{
                color: "var(--palette-red)"
            }}>
                {unableToSaveMessage}
            </div>}
            <div className={css.row}>
                <div className={css.sectionTitle}>Variables</div>
            </div>
            {nonPrivateVariables.map((config, index) => {
                const variable = memoryScratchPad.metadata.variables[config.name];

                return <div key={index} className={css.row}>
                    <div className={css.label}>
                        {variable.label}
                    </div>
                    {isEditing && <>
                        {variable.content.type === "boolean" && <BooleanVariable
                            content={variable.content}
                            config={config}
                            onChange={setVariableContent(config.name)}
                        />}
                        {variable.content.type === "enum" && <EnumVariable
                            content={variable.content}
                            config={config}
                            onChange={setVariableContent(config.name)}
                        />}
                        {variable.content.type === "number" && <NumberVariable
                            content={variable.content}
                            config={config}
                            onChange={setVariableContent(config.name)}
                        />}
                    </>}
                    {!isEditing && <div className={css.variableValue}>
                        {variable.content.type !== "boolean" && variable.content.value}
                        {variable.content.type === "boolean" && <div style={{ pointerEvents: "none"}}>
                            <BooleanVariable
                                content={variable.content}
                                config={config}
                                onChange={() => {}}
                            />
                        </div>}
                    </div>}
                </div>;
            })}
            {config.data && config.data.length > 0 && <>
                <div className={css.row}>
                    <div className={css.sectionTitle}>Data</div>
                </div>
                {config.data.map((config, index) => {
                    const currentData = memoryScratchPad.metadata.data[config.size];

                    return <div key={index} className={css.row}>
                        <div className={css.label}>
                            {config.label}
                        </div>
                        {isEditing && <DataField
                            content={currentData}
                            config={config}
                            onChange={setDataContent(config.size)}
                        />}
                        {!isEditing && <div className={css.variableValue}>
                            {currentData}
                        </div>}
                    </div>;
                })}
            </>}
            <div className={css.row}>
                <div className={css.sectionTitle}>Metadata</div>
            </div>
            <div className={css.row}>
                <div className={css.label}>
                    Device address
                </div>
                {isEditing && <select
                    className={css.select}
                    value={memoryScratchPad.source
                        ? memoryScratchPad.source.type === "local"
                            ? "Local"
                            : memoryScratchPad.source.deviceId
                        : ""
                    }
                    onChange={event => {
                        setSourceDeviceId(event.target.value);
                    }}
                >
                    {memoryScratchPad.source === null && <option value={""}></option>}
                    {"local" in config.modifiers && config.modifiers.local && <option value={"null"}>Local</option>}
                    {editorDevices.filter(nonNullableFilter)
                        .filter(device => device.address)
                        .map(device => <option key={device.id} value={device.id}>{device.address}</option>)
                    }
                </select>}
                {!isEditing && <div className={css.variableValue}>
                    {memoryScratchPad.source?.type === "local"
                        ? "Local"
                        : editorDevices.find(device => device?.id === memoryScratchPad.source?.deviceId)?.address
                    }
                </div>}
            </div>
            {memoryScratchPad.source?.type === "io" && <div className={css.row}>
                <div className={css.label}>
                    IO
                </div>
                {isEditing && <select
                    className={css.select}
                    value={memoryScratchPad.source.io}
                    onChange={event => {
                        setSourceIo(event.target.value);
                    }}
                >
                    {memoryScratchPad.source.io === "" && <option value={""}></option>}
                    {
                        memoryScratchPad.name === "INPUT" &&
                        (editorDevices.find(device => device?.id === (memoryScratchPad.source as MemorySourceIo).deviceId)?.inputs ?? [])
                            .map(io => <option key={io.name} value={io.name}>{io.name}</option>)
                    }
                    {
                        memoryScratchPad.name === "OUTPUT" &&
                        (editorDevices.find(device => device?.id === (memoryScratchPad.source as MemorySourceIo).deviceId)?.outputs ?? [])
                            .map(io => <option key={io.name} value={io.name}>{io.name}</option>)
                    }
                </select>}
                {!isEditing && <div className={css.variableValue}>
                    {(memoryScratchPad.source as MemorySourceIo).io}
                </div>}
            </div>}
            {memoryScratchPad.source?.type === "address" && <div className={css.row}>
                <div className={css.label}>
                    Address
                </div>
                {isEditing && <NumberVariable
                    content={{
                        type: "number",
                        value: memoryScratchPad.source.address,
                    }}
                    config={{
                        content: {
                            default: 0,
                            type: "number",
                            min: 0,
                            max: 2 ** 16 - 1,
                        },
                        description: "",
                        label: "",
                        name: "",
                        private: false,
                    }}
                    onChange={value => setSourceAddress(value.value)}
                />}
                {!isEditing && <div className={css.variableValue}>
                    {memoryScratchPad.source.address}
                </div>}
            </div>}
        </div>}
    </div>;
}

function MemoryLabel(props: {
    scratchPadMemory: MemoryScratchPad,
    onChange: (content: string) => void,
}) {
    return <input
        className={css.memoryLabel}
        value={props.scratchPadMemory.label}
        autoFocus={true}
        onFocus={event => {
            event.target.select();
            event.target.style.width = "0px";
            const boundingRect = event.target.getBoundingClientRect();
            const size = Math.min(event.target.scrollWidth + 2 - boundingRect.width, 300);
            event.target.style.width = `${size}px`;
        }}
        onChange={event => {
            event.target.style.width = "0px";
            const boundingRect = event.target.getBoundingClientRect();
            const size = Math.min(event.target.scrollWidth + 2 - boundingRect.width, 300);
            event.target.style.width = `${size}px`;
            props.onChange(event.target.value);
        }}
    />;
}
