import { WindowTypeAndPropsDefinition } from "../../windowManagerSlice";

export interface TestWindowProps {
    text: string;
}

export type TestWindowTypeAndProps = WindowTypeAndPropsDefinition<
    "test",
    TestWindowProps
>;

export function TestWindow(props: TestWindowProps & { id: string }) {
    return <span>{props.text}</span>;
}
