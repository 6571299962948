import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { appMainElementId } from "../../App";
import { GenericSelector, RootState } from "../../app/store";
import { generateUniqueId } from "../../common/generateUniqueId";
import { Point2D } from "../../common/Point2D";
import { setSheetGridSize } from "../editor/editorSlice";
import { WindowTypeAndProps } from "./window/windowImpl";

export type WindowManagerState = {
    windows: Record<string, Window>,
}

export type WindowTypeAndPropsDefinition<
    Type extends string,
    Props
> = {
    type: Type,
    props: Props,
};

interface Window {
    id: string,
    typeAndProps: WindowTypeAndProps,

    position: Point2D,
    size: Point2D,

    title: string,
}

const initialState: WindowManagerState = {
    windows: {},
};

function clampWindowToAppWindow(window: Window) {
    const left = window.position.x;
    const right = left + window.size.x;
    const top = window.position.y;
    const bottom = top + window.size.y;

    const appObject = document.getElementById(appMainElementId)!;

    const appWidth = appObject.clientWidth - 2;
    const appHeight = appObject.clientHeight - 2;

    if (window.size.x > appWidth) {
        window.size.x = appWidth;
    }

    if (window.size.y > appHeight) {
        window.size.y = appHeight;
    }

    if (left < 0) {
        window.position.x = 0;
    } else if (right > appWidth) {
        window.position.x = appWidth - window.size.x;
    }

    if (top < 0) {
        window.position.y = 0;
    } else if (bottom > appHeight) {
        window.position.y = appHeight - window.size.y;
    }
}

export const windowManagerSlice = createSlice({
    name: "windowManager",
    initialState,
    reducers: {
        resetWindowManager: () => {
            return initialState;
        },
        createWindow: (state, action: PayloadAction<{
            title: string,
            typeAndProps: WindowTypeAndProps,

            position?: Point2D,
            size?: Point2D,
        }>) => {
            const uniqueWindows = [
                "confirmNew",
                "memoryManager",
                "deviceManager",
                "confirmNew",
            ];

            if (uniqueWindows.includes(action.payload.typeAndProps.type)) {
                const windowExists = Object.values(state.windows)
                    .find(window => window.typeAndProps.type === action.payload.typeAndProps.type);

                if (windowExists) {
                    const temp = state.windows[windowExists.id];
                    delete state.windows[windowExists.id];
                    state.windows[windowExists.id] = temp;
                    return;
                }
            }

            const id = generateUniqueId("window");
            const window = {
                id,
                title: action.payload.title,
                typeAndProps: action.payload.typeAndProps,
                position: action.payload.position ?? { x: 200, y: 200 },
                size: action.payload.size ?? { x: 200, y: 200 },
            };

            clampWindowToAppWindow(window);


            Object.keys(state.windows).forEach(id => {
                delete state.windows[id];
            });

            state.windows[id] = window;
        },
        closeWindow: (state, action: PayloadAction<string>) => {
            delete state.windows[action.payload];
        },
        focusWindow: (state, action: PayloadAction<string>) => {
            const temp = state.windows[action.payload];
            delete state.windows[action.payload];
            state.windows[action.payload] = temp;
        },
        moveWindowByOffset: (state, action: PayloadAction<{
            windowId: string,
            delta: Point2D,
        }>) => {
            const delta = action.payload.delta;
            const id = action.payload.windowId;
            const window = state.windows[id];

            window.position.x += delta.x;
            window.position.y += delta.y;

            clampWindowToAppWindow(window);
        },
        resizeWindowByOffset: (state, action: PayloadAction<{
            windowId: string,
            delta: Point2D,
        }>) => {
            const delta = action.payload.delta;
            const id = action.payload.windowId;
            const window = state.windows[id];

            window.size.x += delta.x;
            window.size.y += delta.y;

            if (window.size.x < 200) {
                window.size.x = 200;
            }

            if (window.size.y < 150) {
                window.size.y = 150;
            }

            clampWindowToAppWindow(window);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(setSheetGridSize, (state, action) => {
                Object.entries(state.windows).forEach(([id, window]) => {
                    clampWindowToAppWindow(window);
                });
            })
    },
});

export const {
    resetWindowManager,
    createWindow,
    closeWindow,
    focusWindow,
    moveWindowByOffset,
    resizeWindowByOffset,
} = windowManagerSlice.actions;

export const selectWindows = (state: RootState) => state.windowManager.windows;

export const selectWindow = (category: string): GenericSelector<Window> =>
    createSelector(
        selectWindows,
        windows => windows[category]
    );

export default windowManagerSlice.reducer;
