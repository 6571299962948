import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { Logics } from "../../../../logics/logics/Logics";
import { selectLogics } from "../../../../logics/logicsSlice";
import { deleteConnection, setSelection, toggleFromSelection } from "../../../editorSlice";
import { SelectionConnection, Sheet, isInSelection } from "../../../interfaces/Sheet";
import Connection, { ConnectionIo } from "../../../interfaces/components/Connection";
import MemoryRef from "../../../interfaces/components/MemoryRef";
import Operator from "../../../interfaces/components/Operator";
import { ioGroups } from "../block/Block";
import css from "./Connection.module.css";

function blockFromIo(io: ConnectionIo, sheet: Sheet) {
    switch (io.type) {
        case "operator":
            return sheet.operators.find(operator => operator.id === io.operatorId);
        case "memoryRef":
            return sheet.memoryRefs.find(memoryRef => memoryRef.id === io.memoryRefId);
    }
}

function doesIoExist(io: ConnectionIo, block: Operator | MemoryRef, logics: Logics) {
    if (io.type === "memoryRef") {
        return true;
    }

    const operator = block as Operator;
    const config = logics.operator[operator.name];

    let groups;

    switch (io.ioType) {
        case "input":
            groups = ioGroups(config.inputs, operator.inputs, operator.metadata);
            break;
        case "output":
            groups = ioGroups(config.outputs, operator.outputs, operator.metadata);
            break;
    }

    return io.ioIndex < groups[io.ioGroup].ioCount;
}

function ioHeightSlot(io: ConnectionIo, block: Operator | MemoryRef, logics: Logics) {
    switch (io.type) {
        case "operator":
            const operator = block as Operator;
            const config = logics.operator[operator.name];

            let groups;

            switch (io.ioType) {
                case "input":
                    groups = ioGroups(config.inputs, operator.inputs, operator.metadata);
                    break;
                case "output":
                    groups = ioGroups(config.outputs, operator.outputs, operator.metadata);
                    break;
            }

            const sumOfPreviousGroups = groups.slice(0, io.ioGroup).reduce((sum, group) => sum + group.ioCount, 0);
            return sumOfPreviousGroups + io.ioIndex;

        case "memoryRef":
            return 0;
    }
}

export function ConnectionLine(props: {
    connection: Connection,
    sheetIndex: number,
    connectionIndex: number,
}) {
    const dispatch = useAppDispatch();

    const logics = useAppSelector(selectLogics);
    const sheet = useAppSelector(state => state.editor.sheets[props.sheetIndex]);
    const currentSelection = sheet.selection;
    // const [selected, setSelected] = useState(false);

    const selected = isInSelection(currentSelection, {
        type: "connection",
        connectionId: props.connection.id,
    });

    const { from: origin, to: destination } = props.connection;

    const originBlock = blockFromIo(origin, sheet);
    const destinationBlock = blockFromIo(destination, sheet);

    if (!originBlock || !destinationBlock) {
        dispatch(deleteConnection({ id: props.connection.id }));
        return null;
    }

    if (
        !doesIoExist(origin, originBlock, logics) ||
        !doesIoExist(destination, destinationBlock, logics)
    ) {
        dispatch(deleteConnection({ id: props.connection.id }));
        return null;
    }

    const originY = originBlock.boundingBox.top +
        (1 + 2 * ioHeightSlot(origin, originBlock, logics)) * sheet.gridPixelSize;
    const destinationY = destinationBlock.boundingBox.top +
        (1 + 2 * ioHeightSlot(destination, destinationBlock, logics)) * sheet.gridPixelSize;

    const originX = originBlock.boundingBox.left + originBlock.boundingBox.width - 1;
    const destinationX = destinationBlock.boundingBox.left + 1;

    const distance = Math.sqrt((originX - destinationX) ** 2 + (originY - destinationY) ** 2);
    const angle = Math.atan2((originY - destinationY), (originX - destinationX));
    const yAngleOffset = -distance * Math.sin(angle) / 2;
    const xDistanceOffset = -(distance - (destinationX - originX)) / 2;

    return <div
        className={classNames(css.connection, {
            [css.selected]: selected,
        })}
        style={{
            "--width": `${distance}px`,
            transform: `
                translate(
                    ${originX + xDistanceOffset}px,
                    ${originY + yAngleOffset}px
                )
                rotate(${angle}rad)
            `
        } as React.CSSProperties}
        onClick={event => {
            event.stopPropagation();
            // setSelected(true);

            const selection: SelectionConnection = {
                type: "connection",
                connectionId: props.connection.id,
            };

            if (event.ctrlKey || event.metaKey) {
                console.log("toggle");
                dispatch(toggleFromSelection(selection));
            } else {
                dispatch(setSelection(selection));
            }
        }}
    >
    </div>
}
