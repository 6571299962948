import { useAppSelector } from "../../../app/hooks";
import { selectWindow } from "../windowManagerSlice";
import { Window } from "./Window";
import { WindowTypeAndProps } from "./windowImpl";
import { BlockConfigWindow } from "./windowImpl/BlockConfigWindow/BlockConfigWindow";
import { ConfirmNewWindow } from "./windowImpl/ConfirmNewWindow/ConfirmNewWindow";
import { DeviceManagerWindow } from "./windowImpl/DeviceManagerWindow/DeviceManagerWindow";
import { LogicsMigrationWindow } from "./windowImpl/LogicsMigrationWindow/LogicsMigrationWindow";
import { MemoryManagerWindow } from "./windowImpl/MemoryManagerWindow/MemoryManagerWindow";
import { ReadmeWindow } from "./windowImpl/ReadmeWindow/ReadmeWindow";
import { SheetWindow } from "./windowImpl/SheetWindow";
import { TestWindow } from "./windowImpl/TestWindow";

export interface TypedWindowProps {
    id: string,
}

export function TypedWindow(props: TypedWindowProps) {
    const window = useAppSelector(selectWindow(props.id));

    return <Window id={props.id}>
        <WindowImpl {...window.typeAndProps} id={props.id}/>
    </Window>;
}

type WindowImplProps = WindowTypeAndProps & { id: string };

function WindowImpl(props: WindowImplProps) {
    switch (props.type) {
        case "sheet": return <SheetWindow id={props.id} {...props.props}/>;
        case "test": return <TestWindow id={props.id} {...props.props}/>;
        case "confirmNew": return <ConfirmNewWindow id={props.id} {...props.props}/>;
        case "blockConfig": return <BlockConfigWindow id={props.id} {...props.props}/>;
        case "memoryManager": return <MemoryManagerWindow id={props.id} {...props.props}/>;
        case "deviceManager": return <DeviceManagerWindow id={props.id} {...props.props}/>;
        case "readme": return <ReadmeWindow id={props.id} {...props.props}/>;
        case "logicsMigration": return <LogicsMigrationWindow id={props.id} {...props.props}/>;
    }
}
